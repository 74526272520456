const EmailCheckedIcon = ({
  fill = '#004949',
  width = '16',
  height = '16',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/mark_email_read">
        <mask
          id="mask0_7592_154"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect id="Bounding box" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7592_154)">
          <path
            id="icon"
            d="M10.6333 14.6667L7.80001 11.8333L8.73334 10.9L10.6333 12.8L14.4 9.03333L15.3333 9.96666L10.6333 14.6667ZM8.00001 7.33333L13.3333 4H2.66668L8.00001 7.33333ZM8.00001 8.66666L2.66668 5.33333V12H6.10001L7.43334 13.3333H2.66668C2.30001 13.3333 1.98612 13.2028 1.72501 12.9417C1.4639 12.6806 1.33334 12.3667 1.33334 12V4C1.33334 3.63333 1.4639 3.31944 1.72501 3.05833C1.98612 2.79722 2.30001 2.66666 2.66668 2.66666H13.3333C13.7 2.66666 14.0139 2.79722 14.275 3.05833C14.5361 3.31944 14.6667 3.63333 14.6667 4V6.9L13.3333 8.23333V5.33333L8.00001 8.66666Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}

export default EmailCheckedIcon
