import { lazy } from 'react'
import { typeUser } from '../localStorage'
import EditJobOffer from '../pages/JobOffers/components/EditJobOffer'
import {
  IS_ADMIN,
  IS_HIRING_ADVISOR,
  TYPE_OF_AUTH,
  TYPE_OF_USER,
} from '../utils/constants'
import CompanyDetail from '../pages/Company/components/CompanyDetail.js'

// const NotificationSetting = lazy(() =>
//   import('../pages/Setting/components/NotificationSetting.js')
// )

const UpdateCompanyInformation = lazy(() =>
  import('../pages/UpdateCompanyInformation/UpdateCompanyInformation')
)

const TmCompanies = lazy(() => import('../pages/TmCompanies/TmCompanies.js'))

const SuperSearch = lazy(() => import('../pages/SuperSearch/SuperSearch.js'))

const CompleteRegister = lazy(() =>
  import('../pages/CompleteRegister/CompleteRegister.js')
)

const CompanyPersonalInformation = lazy(() =>
  import('../pages/CompanyPersonalInformation/CompanyPersonalInformation.js')
)

const ListOfSoftSkills = lazy(() =>
  import('../pages/Admin/ListOfSoftSkills/ListOfSoftSkills.js')
)

const TalentManagerLastConection = lazy(() =>
  import(
    '../pages/Admin/TalentManagerLastConection/TalentManagerLastConection.js'
  )
)

const ActiveProcesses = lazy(() =>
  import('../pages/ActiveProcesses/ActiveProcesses.js')
)

const TmTalents = lazy(() => import('../pages/TmTalents/TmTalents.js'))

// const TmJobOffersReceived = lazy(() =>
//   import('../pages/TmJobOffers/TmJobOffersReceived.js')
// )

const FrecuentQuestions = lazy(() =>
  import('../pages/FrecuentQuestions/FrecuentQuestions.js')
)

const DashboardTalentsManagers = lazy(() =>
  import('../pages/Admin/dashboard/DashboardTalentsManagers')
)

const TalentsAsociateTm = lazy(() =>
  import('../pages/TalentsAsociateTm/TalentsAsociateTm')
)

const Support = lazy(() => import('../pages/Dashboard/NestedPages/Support'))

const HiringAdvisorDetailFromAdmin = lazy(() =>
  import('../pages/Dashboard/NestedPages/HiringAdvisorDetailFromAdmin')
)
const LandingSecondary = lazy(() =>
  import('../pages/LandingSecondary/LandingSecondary.js')
)

const Landing = lazy(() => import('../pages/Landing/Landing.js'))

const CreateHiringAdvisor = lazy(() =>
  import('../pages/Admin/CreateHiringAdvisor')
)
const ListOfHiringAdvisor = lazy(() =>
  import('../pages/Admin/ListOfHiringAdvisor')
)
const AllNotifications = lazy(() =>
  import('../pages/AllNotifications/AllNotifications')
)
const Candidates = lazy(() => import('../pages/Candidates/Candidates'))
const ChangePassword = lazy(() => import('../pages/ChangePassword'))
const ChatgptAssistant = lazy(() =>
  import('../pages/ChatgptAssistant/ChatgptAssistant')
)
const CompanyInformation = lazy(() =>
  import('../pages/CompanyInformation/CompanyInformation')
)
const ContactUs = lazy(() => import('../pages/ContactUs/ContactUs'))
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'))
const DashboardExperience = lazy(() =>
  import('../pages/Admin/dashboard/DashboardExperience')
)

const DashboardTalents = lazy(() =>
  import('../pages/Admin/dashboard/DashboardTalents')
)

const AllOffersFromRecruiter = lazy(() =>
  import('../pages/Dashboard/NestedPages/AllOffersFromRecruiter')
)
const ChangeEmail = lazy(() =>
  import('../pages/Dashboard/NestedPages/ChangeEmail')
)
const CompaniesFromAdmin = lazy(() =>
  import('../pages/Dashboard/NestedPages/CompaniesFromAdmin')
)
const CompanyDetailFromAdmin = lazy(() =>
  import('../pages/Dashboard/NestedPages/CompanyDetailFromAdmin')
)
const IncompleteProfiles = lazy(() =>
  import('../pages/Dashboard/NestedPages/IncompleteProfiles')
)
const ListOfCategories = lazy(() =>
  import('../pages/Dashboard/NestedPages/ListOfCategories')
)
const ListOfCities = lazy(() =>
  import('../pages/Dashboard/NestedPages/ListOfCities')
)
const ListOfIdioms = lazy(() =>
  import('../pages/Dashboard/NestedPages/ListOfIdioms')
)
const ListOfSectors = lazy(() =>
  import('../pages/Dashboard/NestedPages/ListOfSectors')
)
const ListOfSkills = lazy(() =>
  import('../pages/Dashboard/NestedPages/ListOfSkills')
)
const ListOfStates = lazy(() =>
  import('../pages/Dashboard/NestedPages/ListOfStates')
)
const RecruiterDetailFromAdmin = lazy(() =>
  import('../pages/Dashboard/NestedPages/RecruiterDetailFromAdmin')
)
const RecruiterNetwork = lazy(() =>
  import('../pages/Dashboard/NestedPages/RecruiterNetwork')
)
const RecruiterTalentDetail = lazy(() =>
  import('../pages/Dashboard/NestedPages/RecruiterTalentDetail')
)
const RecruitersFromAdmin = lazy(() =>
  import('../pages/Dashboard/NestedPages/RecruitersFromAdmin')
)
const SendEmails = lazy(() =>
  import('../pages/Dashboard/NestedPages/SendEmails')
)
const TalentsFromAdmin = lazy(() =>
  import('../pages/Dashboard/NestedPages/TalentsFromAdmin')
)
const ForgetPassword = lazy(() => import('../pages/ForgetPassword'))
const CompanieDetailByHiringAdvisor = lazy(() =>
  import('../pages/HiringAdvisor/CompanieDetailByHiringAdvisor')
)
const ListOfCompaniesByHiringAdvisor = lazy(() =>
  import('../pages/HiringAdvisor/ListOfCompaniesByHiringAdvisor')
)
const MyListOfJobOffers = lazy(() =>
  import('../pages/HiringAdvisor/components/MyListOfJobOffers')
)
const ListOfFastOffers = lazy(() =>
  import('../pages/JobOffers/ListOfFastOffers')
)
const ListOfJobOffers = lazy(() => import('../pages/JobOffers/ListOfJobOffers'))
const CreateJobOffer = lazy(() =>
  import('../pages/JobOffers/components/CreateJobOffer')
)
const FastOfferDetail = lazy(() =>
  import('../pages/JobOffers/components/FastOfferDetail')
)
const JobOfferDetail = lazy(() =>
  import('../pages/JobOffers/components/JobOfferDetail')
)
const Login = lazy(() => import('../pages/Login/Login'))
const MyProfile = lazy(() => import('../pages/MyProfile/MyProfile'))
const MyResume = lazy(() => import('../pages/MyResume/MyResume'))
const NewPersonalInformation = lazy(() =>
  import('../pages/NewPersonalInformation/NewPersonalInformation')
)
const OrderCompanies = lazy(() =>
  import('../pages/OrderCompanies/OrderCompanies')
)
const PersonalInformation = lazy(() =>
  import('../pages/PersonalInformation/PersonalInformation')
)
const Process = lazy(() => import('../pages/Process/Process'))
const ProfessionalInformation = lazy(() =>
  import('../pages/ProfessionalInformation')
)
const RecoveryPassword = lazy(() => import('../pages/RecoveryPassword'))
const Register = lazy(() => import('../pages/Register/Register'))
const VerificationEmailMessage = lazy(() =>
  import('../pages/Register/components/VerificationEmailMessage')
)
const VerifyEmailUser = lazy(() =>
  import('../pages/Register/components/VerifyEmailUser')
)
const CreateFastJobOffer = lazy(() =>
  import('../pages/RegisterFastCompany/CreateFastJobOffer')
)
const Setting = lazy(() => import('../pages/Setting/Setting'))
const TalentInformation = lazy(() =>
  import('../pages/TalentInformation/TalentInformation')
)
const TalentDetail = lazy(() =>
  import('../pages/Talents/components/TalentDetail')
)
const TermsAndConditions = lazy(() =>
  import('../pages/TermsAndConditions/TermsAndConditions')
)
const ListOfTalentsMatch = lazy(() =>
  import('../pages/HiringAdvisor/components/ListOfTalentsMatch')
)

const QuickOffer = lazy(() => import('../pages/Landing/components/QuickOffer.js'))
const QuickOfferMaster = lazy(() => import('../pages/Admin/QuickOffer'))

const TalentManagerRankingAdmin = lazy(() =>
  import('../pages/Admin/talentManagerRanking/TalentManagerRanking')
)

// simple routes

export const PRIVATE_ROUTES = {
  contactUs: '/contact-us',
  talents: '/talents',
  talent_detail: '/talent/:id',
  dashboard: '/dashboard',
  invite_talent: '/invite_talent',
  incomplete_profiles: '/incomplete-profiles',
  process: '/process',
  process_detail: '/process/:id/:idJobOffer',
  my_resume: '/my-resume',
  my_profile: '/my-profile',
  my_curriculum: '/my-curriculum',
  personal_data: '/personal-data',
  professional_data: '/professional-data',
  update_works_data: '/update-works-data',
  change_password: '/change-password',
  my_favorites: '/my-favorites',
  offer_management: '/offer-management',
  offer_management_detail:
    IS_HIRING_ADVISOR || IS_ADMIN
      ? '/offer-management/:offerManagementId/:id'
      : '/offer-management/:offerManagementId',
  recruiter_network: '/recruiter-network',
  recruiter_talent_detail: '/recruiter-talent-detail/:id',
  recruiter_talent_detail_by_processes_actives:
    '/recruiter-talent-detail-by-processes-actives/:id',
  recruiter_talent_detail_by_offers_received:
    '/recruiter-talent-detail-by-offers-received/:id',
  all_offers_from_recruiter: '/all-offers-from-recruiter',
  offers_sent: '/offers-sent',
  company_detail: '/company-detail/:id',
  complete_profiles: '/complete-profiles',
  change_email: '/change-email',
  all_notifications: '/all-notifications',
  talent_information: '/talent-information',
  update_personal_information: '/update-personal-information',
  update_professional_information: '/update-professional-information',
  company_form: '/company-form',
  list_of_offers: '/list-of-offers',
  create_job_offer: '/create-job-offer',
  create_job_offer_asociate_id: '/create-job-offer/:id',
  candidates:
    IS_HIRING_ADVISOR || IS_ADMIN
      ? '/candidates/:id/:idCompany'
      : '/candidates/:id',
  list_of_companies_by_hiring_advisor: '/list-of-companies-by-hiring-advisor',
  company_detail_by_hiring_advisor: '/company-detail-by-hiring-advisor/:id',
  setting: '/setting',
  chatgpt_assistant: '/chatgpt-assistant',
  list_of_fast_offers_by_ha: '/list-of-fast-offers-by-ha',
  edit_job_offer: '/edit-job-offer/:id',
  offers_with_talents_tm: '/offers-with-talents-tm',
  update_company_information: '/update-company-information',
  frecuent_questions: '/frecuent-questions',
  tm_talents: '/tm-talents',
  tm_job_offers_received: '/talent-manager-job-offers-received',
  active_processes: '/active-processes',
  tm_companies: '/tm-companies',
  company_personal_information: '/company-personal-information',
  update_talent_information: '/update-talent-information',
  notification_setting: '/notification-setting',

  // --> ADMIN ROUTES
  admin_talents: '/admin-talents',
  admin_recruiters: '/admin-recruiters',
  admin_recruiters_detail: '/admin-recruiters/:id',
  admin_companies: '/admin-companies',
  admin_companies_detail: '/admin-companies-detail/:id',
  admin_send_emails: '/admin-send-emails',
  list_Of_Skills: '/list-Of-Skills',
  list_of_soft_skills: '/list-of-soft-skills',
  list_Of_Sectors: '/list-Of-Sectors',
  list_Of_Categories: '/list-Of-Categories',
  list_of_city: '/list-of-city',
  list_of_idioms: '/list-of-idioms',
  list_of_fast_offers: '/list-of-fast-offers',
  fast_offer_detail: '/fast-offer-detail/:id',
  list_of_order_companies: '/list-of-order-companies',
  list_of_validate_fast_offers: '/list-of-validate-fast-offers',
  fast_validate_offer_detail: '/fast-validate-offer-detail/:id',
  list_of_states: '/list-of-states',
  create_hiring_advisor: '/create-hiring-advisor',
  list_of_hiring_advisors: '/list-of-hiring-advisors',
  register_recruiter: '/register-recruiter',
  list_of_talents_match: '/list-of-talents-match/:id',
  hiring_advisor_detail: '/hiring-advisor/:id',
  support: '/support',
  support_detail: '/support-detail/:id',
  admin_quick_offer: '/admin-quick-offer',
  admin_talent_manager_ranking: '/admin-talent-manager-ranking',
  dashboard_talents: '/dashboard-talents',
  dashboard_experience: '/dashboard-experience',
  dashboard_talents_managers: '/dashboard-talents-managers',
  master_talent_detail: '/master-talent-detail/:id',
  talent_manager_last_conection: '/talent-manager-last-conection',
  offer_detail_admin: '/offer-detail-admin/:offerManagementId',
  register_by_complete_company: '/register-by-complete-company',
}

export const PUBLIC_ROUTES = {
  home: '/',
  login: '/login',
  login_token: '/login/:code',
  about: '/about',
  register: '/register',
  register_by_company: '/register-by-company',
  register_by_company_by_code: '/register-by-company/:code',
  verify_email_user: '/verify-email-user/:token',
  register_by_inivitation_code: '/tm-link/:invitationCode/:idiom',
  forget_password: '/forget-password',
  recovery_pasword: '/recovery-password/:token',
  public_talent_detail: '/public-talents/:id',
  terms_and_conditions: '/termns-and-conditions',
  create_fast_job_offer: '/create-fast-job-offer/:token',
  create_fast_job_offer_by_ha: '/create-fast-job-offer/ha/:code',
  go_to_email: '/go-to-email',
  contact_us: '/contact-us',
  quick_offer: '/quick-offer',
  quick_offer_24hrs: '/quick-offer-24hrs',
  landing_by_tm_company_code: '/tm-company-code/:code',
  super_search: '/super-search/:code',
  super_search_static: '/super-search',
  company_activation: '/company-activation/:token',
  complete_register_google: '/complete-register-google/:token',
  complete_register_linkedin: '/complete-register-linkedin/:token',
  new_landing: '/new-landing',
}

export const ADMIN_ROUTES = [
  {
    path: PRIVATE_ROUTES.admin_talents,
    element: <TalentsFromAdmin />,
  },
  {
    path: PRIVATE_ROUTES.admin_recruiters,
    element: <RecruitersFromAdmin />,
  },
  {
    path: PRIVATE_ROUTES.admin_recruiters_detail,
    element: <RecruiterDetailFromAdmin />,
  },
  {
    path: PRIVATE_ROUTES.admin_companies,
    element: <CompaniesFromAdmin />,
  },
  {
    path: PRIVATE_ROUTES.admin_companies_detail,
    element: <CompanyDetailFromAdmin />,
  },
  {
    path: PRIVATE_ROUTES.admin_send_emails,
    element: <SendEmails />,
  },
  {
    path: PRIVATE_ROUTES.list_Of_Skills,
    element: <ListOfSkills />,
  },
  {
    path: PRIVATE_ROUTES.list_of_soft_skills,
    element: <ListOfSoftSkills />,
  },
  {
    path: PRIVATE_ROUTES.list_Of_Categories,
    element: <ListOfCategories />,
  },
  {
    path: PRIVATE_ROUTES.list_Of_Sectors,
    element: <ListOfSectors />,
  },
  {
    path: PRIVATE_ROUTES.list_of_city,
    element: <ListOfCities />,
  },
  {
    path: PRIVATE_ROUTES.list_of_idioms,
    element: <ListOfIdioms />,
  },
  {
    path: PRIVATE_ROUTES.list_of_fast_offers,
    element: <ListOfFastOffers />,
  },
  {
    path: PRIVATE_ROUTES.fast_offer_detail,
    element: <FastOfferDetail />,
  },
  {
    path: PRIVATE_ROUTES.list_of_order_companies,
    element: <OrderCompanies />,
  },
  {
    path: PRIVATE_ROUTES.list_of_states,
    element: <ListOfStates />,
  },
  {
    path: PRIVATE_ROUTES.fast_validate_offer_detail,
    element: <FastOfferDetail validate />,
  },
  {
    path: PRIVATE_ROUTES.create_hiring_advisor,
    element: <CreateHiringAdvisor />,
  },
  {
    path: PRIVATE_ROUTES.list_of_hiring_advisors,
    element: <ListOfHiringAdvisor />,
  },
  {
    path: PRIVATE_ROUTES.register_recruiter,
    element: <Register isRecruiter />,
  },
  {
    path: PRIVATE_ROUTES.hiring_advisor_detail,
    element: <HiringAdvisorDetailFromAdmin />,
  },
  {
    path: PRIVATE_ROUTES.list_of_talents_match,
    element: <ListOfTalentsMatch />,
  },
  {
    path: PRIVATE_ROUTES.company_detail_by_hiring_advisor,
    element: <CompanieDetailByHiringAdvisor />,
  },
  {
    path: PRIVATE_ROUTES.support,
    element: <Support />,
  },
  {
    path: PRIVATE_ROUTES.admin_quick_offer,
    element: <QuickOfferMaster />,
  },
  {
    path: PRIVATE_ROUTES.admin_talent_manager_ranking,
    element: <TalentManagerRankingAdmin />,
  },
  {
    path: PRIVATE_ROUTES.dashboard_talents_managers,
    element: <DashboardTalentsManagers />,
  },
  {
    path: PRIVATE_ROUTES.dashboard_experience,
    element: <DashboardExperience />,
  },
  {
    path: PRIVATE_ROUTES.dashboard_talents_managers,
    element: <DashboardTalentsManagers />,
  },
  {
    path: PRIVATE_ROUTES.dashboard_talents,
    element: <DashboardTalents />,
  },
  {
    path: PRIVATE_ROUTES.master_talent_detail,
    element: <RecruiterTalentDetail isMaster />,
  },
  {
    path: PRIVATE_ROUTES.talent_manager_last_conection,
    element: <TalentManagerLastConection />,
  },
  {
    path: PRIVATE_ROUTES.offer_detail_admin,
    element: <JobOfferDetail />,
  },
  {
    path: PRIVATE_ROUTES.register_by_complete_company,
    element: <Register isCompleteCompany />,
  },
  {
    path: PRIVATE_ROUTES.company_detail,
    element: <CompanyDetail />,
  },
]

export const COMPANY_ROUTES = [
  {
    path: PRIVATE_ROUTES.process_detail,
    element: <Process />,
  },
  {
    path: PRIVATE_ROUTES.edit_job_offer,
    element: <EditJobOffer />,
  },
  {
    path: PRIVATE_ROUTES.update_company_information,
    element: <UpdateCompanyInformation />,
  },
  {
    path: PRIVATE_ROUTES.company_personal_information,
    element: <CompanyPersonalInformation />,
  },
]

const TALENT_ROUTES = [
  {
    path: PRIVATE_ROUTES.chatgpt_assistant,
    element: <ChatgptAssistant />,
  },
  {
    path: PRIVATE_ROUTES.update_talent_information,
    element: <TalentInformation isUpdate />,
  },
  // {
  //   path: PRIVATE_ROUTES.notification_setting,
  //   element: <NotificationSetting />,
  // },
]

const TM_ROUTES = [
  {
    path: PRIVATE_ROUTES.frecuent_questions,
    element: <FrecuentQuestions />,
  },
  {
    path: PRIVATE_ROUTES.tm_talents,
    element: <TmTalents />,
  },
  {
    path: PRIVATE_ROUTES.active_processes,
    element: <ActiveProcesses />,
  },
  {
    path: PRIVATE_ROUTES.tm_companies,
    element: <TmCompanies />,
  },

  // {
  //   path: PRIVATE_ROUTES.tm_job_offers_received,
  //   element: <TmJobOffersReceived />,
  // },
]

const REST_OF_ROUTES = [
  {
    path: PRIVATE_ROUTES.change_email,
    element: <ChangeEmail />,
  },
  {
    path: PRIVATE_ROUTES.my_resume,
    element: <MyResume />,
  },
  {
    path: PRIVATE_ROUTES.my_profile,
    element: <MyProfile />,
  },
  {
    path: PRIVATE_ROUTES.personal_data,
    element: <PersonalInformation />,
  },
  {
    path: PRIVATE_ROUTES.incomplete_profiles,
    element: <IncompleteProfiles />,
  },
  {
    path: PRIVATE_ROUTES.recruiter_network,
    element: <RecruiterNetwork />,
  },
  {
    path: PRIVATE_ROUTES.all_offers_from_recruiter,
    element: <AllOffersFromRecruiter />,
  },
  {
    path: PRIVATE_ROUTES.incomplete_profiles,
    element: <IncompleteProfiles />,
  },
  {
    path: PRIVATE_ROUTES.complete_profiles,
    element: <RecruiterNetwork />,
  },
  {
    path: PRIVATE_ROUTES.company_form,
    element: <CompanyInformation />,
  },
  {
    path: PRIVATE_ROUTES.create_job_offer,
    element: <CreateJobOffer />,
  },
  {
    path: PRIVATE_ROUTES.create_job_offer_asociate_id,
    element: <CreateJobOffer />,
  },
  {
    path: PRIVATE_ROUTES.offers_with_talents_tm,
    element: <TalentsAsociateTm />,
  },

  // talent routes

  {
    path: PRIVATE_ROUTES.talent_information,
    element: <TalentInformation />,
  },
  {
    path: PRIVATE_ROUTES.update_professional_information,
    element: <ProfessionalInformation />,
  },
  {
    path: PRIVATE_ROUTES.update_personal_information,
    element: <NewPersonalInformation />,
  },
  {
    path: PRIVATE_ROUTES.list_of_offers,
    element: <ListOfJobOffers />,
  },

  // general routes

  {
    path: PRIVATE_ROUTES.candidates,
    element: <Candidates />,
  },
  {
    path: PRIVATE_ROUTES.dashboard,
    element: <Dashboard />,
  },
  {
    path: PRIVATE_ROUTES.talent_detail,
    element: <TalentDetail />,
  },
  {
    path: PRIVATE_ROUTES.recruiter_talent_detail,
    element: <RecruiterTalentDetail isTm />,
  },
  {
    path: PRIVATE_ROUTES.recruiter_talent_detail_by_processes_actives,
    element: <RecruiterTalentDetail isProcessesActivesRoute />,
  },
  {
    path: PRIVATE_ROUTES.recruiter_talent_detail_by_offers_received,
    element: <RecruiterTalentDetail isOffersReceived />,
  },
  {
    path: PRIVATE_ROUTES.offer_management_detail,
    element: <JobOfferDetail />,
  },
  {
    path: PRIVATE_ROUTES.all_notifications,
    element: <AllNotifications />,
  },
  {
    path: PRIVATE_ROUTES.change_password,
    element: <ChangePassword />,
  },
  {
    path: PRIVATE_ROUTES.setting,
    element: <Setting />,
  },
]

const HIRING_ADVISOR_ROUTES = [
  {
    path: PRIVATE_ROUTES.list_of_companies_by_hiring_advisor,
    element: <ListOfCompaniesByHiringAdvisor />,
  },
  {
    path: PRIVATE_ROUTES.company_detail_by_hiring_advisor,
    element: <CompanieDetailByHiringAdvisor />,
  },
  {
    path: PRIVATE_ROUTES.list_of_fast_offers_by_ha,
    element: <MyListOfJobOffers />,
  },
  {
    path: PRIVATE_ROUTES.list_of_talents_match,
    element: <ListOfTalentsMatch />,
  },
]

const renderUserRoutes = {
  0: [...REST_OF_ROUTES],
  [TYPE_OF_USER.talent]: [...TALENT_ROUTES, ...REST_OF_ROUTES],
  [TYPE_OF_USER.recruiter]: [...TM_ROUTES, ...REST_OF_ROUTES],
  [TYPE_OF_USER.company]: [...COMPANY_ROUTES, ...REST_OF_ROUTES],
  [TYPE_OF_USER.hiring_advisor]: [...HIRING_ADVISOR_ROUTES, ...REST_OF_ROUTES],
  [TYPE_OF_USER.admin]: [...ADMIN_ROUTES, ...REST_OF_ROUTES],
}

export const LIST_OF_PRIVATES_ROUTES = renderUserRoutes[typeUser] || []

export const LIST_OF_PUBLIC_ROUTES = [
  {
    path: PUBLIC_ROUTES.home,
    element: <Landing />,
  },
  {
    path: PUBLIC_ROUTES.forget_password,
    element: <ForgetPassword />,
  },
  {
    path: PUBLIC_ROUTES.register,
    element: <Register />,
  },
  {
    path: PUBLIC_ROUTES.register_by_inivitation_code,
    element: <Register />,
  },
  {
    path: PUBLIC_ROUTES.login,
    element: <Login />,
  },
  {
    path: PUBLIC_ROUTES.login_token,
    element: <Login />,
  },
  {
    path: PUBLIC_ROUTES.contactUs,
    element: <ContactUs />,
  },
  {
    path: PUBLIC_ROUTES.verify_email_user,
    element: <VerifyEmailUser />,
  },
  {
    path: PUBLIC_ROUTES.recovery_pasword,
    element: <RecoveryPassword />,
  },
  {
    path: PUBLIC_ROUTES.public_talent_detail,
    element: <MyResume />,
  },
  {
    path: PUBLIC_ROUTES.go_to_email,
    element: (
      <VerificationEmailMessage
        email={JSON.parse(localStorage.getItem('currentEmail'))}
      />
    ),
  },
  {
    path: PUBLIC_ROUTES.create_fast_job_offer,
    element: <CreateFastJobOffer />,
  },
  {
    path: PUBLIC_ROUTES.create_fast_job_offer_by_ha,
    element: <CreateFastJobOffer isHiringAdvisor />,
  },
  {
    path: PUBLIC_ROUTES.terms_and_conditions,
    element: <TermsAndConditions />,
  },
  {
    path: PUBLIC_ROUTES.register_by_company,
    element: <Register isCompany />,
  },
  {
    path: PUBLIC_ROUTES.register_by_company_by_code,
    element: <Register isCompany />,
  },
  {
    path: PUBLIC_ROUTES.contact_us,
    element: <ContactUs />,
  },
  {
    path: PUBLIC_ROUTES.quick_offer,
    element: <QuickOffer />,
  },
  {
    path: PUBLIC_ROUTES.quick_offer_24hrs,
    element: <QuickOffer is24hrs />,
  },
  {
    path: PUBLIC_ROUTES.landing_by_tm_company_code,
    element: <Landing />,
  },
  {
    path: PUBLIC_ROUTES.super_search,
    element: <SuperSearch isSuperSearch />,
  },
  {
    path: PUBLIC_ROUTES.super_search_static,
    element: <SuperSearch isSuperSearch />,
  },
  {
    path: PUBLIC_ROUTES.complete_register_google,
    element: <CompleteRegister typeOfAuth={TYPE_OF_AUTH.google} />,
  },
  {
    path: PUBLIC_ROUTES.complete_register_linkedin,
    element: <CompleteRegister typeOfAuth={TYPE_OF_AUTH.linkedin} />,
  },
  {
    path: PUBLIC_ROUTES.new_landing,
    element: <LandingSecondary />,
  },
]
