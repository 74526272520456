const MetricsIcon = ({ fill = '#004949', width = 33, height = 33 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_515_13142"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="33"
        height="33"
      >
        <rect x="0.112305" y="0.78125" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_515_13142)">
        <path
          d="M4.1123 27.4479C3.37897 27.4479 2.75119 27.1868 2.22897 26.6646C1.70674 26.1424 1.44563 25.5146 1.44563 24.7812C1.44563 24.0479 1.70674 23.4201 2.22897 22.8979C2.75119 22.3757 3.37897 22.1146 4.1123 22.1146H4.4623C4.5623 22.1146 4.66786 22.1368 4.77897 22.1812L10.8456 16.1146C10.8012 16.0035 10.779 15.8979 10.779 15.7979V15.4479C10.779 14.7146 11.0401 14.0868 11.5623 13.5646C12.0845 13.0424 12.7123 12.7812 13.4456 12.7812C14.179 12.7812 14.8067 13.0424 15.329 13.5646C15.8512 14.0868 16.1123 14.7146 16.1123 15.4479C16.1123 15.4924 16.0901 15.7146 16.0456 16.1146L19.4456 19.5146C19.5567 19.4701 19.6623 19.4479 19.7623 19.4479H20.4623C20.5623 19.4479 20.6679 19.4701 20.779 19.5146L25.5123 14.7812C25.4679 14.6701 25.4456 14.5646 25.4456 14.4646V14.1146C25.4456 13.3812 25.7067 12.7535 26.229 12.2312C26.7512 11.709 27.379 11.4479 28.1123 11.4479C28.8456 11.4479 29.4734 11.709 29.9956 12.2312C30.5179 12.7535 30.779 13.3812 30.779 14.1146C30.779 14.8479 30.5179 15.4757 29.9956 15.9979C29.4734 16.5201 28.8456 16.7812 28.1123 16.7812H27.7623C27.6623 16.7812 27.5567 16.759 27.4456 16.7146L22.7123 21.4479C22.7567 21.559 22.779 21.6646 22.779 21.7646V22.1146C22.779 22.8479 22.5179 23.4757 21.9956 23.9979C21.4734 24.5201 20.8456 24.7812 20.1123 24.7812C19.379 24.7812 18.7512 24.5201 18.229 23.9979C17.7067 23.4757 17.4456 22.8479 17.4456 22.1146V21.7646C17.4456 21.6646 17.4679 21.559 17.5123 21.4479L14.1123 18.0479C14.0012 18.0924 13.8956 18.1146 13.7956 18.1146H13.4456C13.4012 18.1146 13.179 18.0924 12.779 18.0479L6.7123 24.1146C6.75674 24.2257 6.77897 24.3312 6.77897 24.4312V24.7812C6.77897 25.5146 6.51786 26.1424 5.99563 26.6646C5.47341 27.1868 4.84563 27.4479 4.1123 27.4479ZM5.44563 14.0812L4.6123 12.2812L2.8123 11.4479L4.6123 10.6146L5.44563 8.81458L6.27897 10.6146L8.07897 11.4479L6.27897 12.2812L5.44563 14.0812ZM20.1123 12.7812L18.8456 10.0479L16.1123 8.78125L18.8456 7.51458L20.1123 4.78125L21.379 7.51458L24.1123 8.78125L21.379 10.0479L20.1123 12.7812Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default MetricsIcon
