import { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer'
import NavbarDrawer from '../Navbar/components/NavbarDrawer'
import { useNavigate } from 'react-router-dom'
import { useLostConectionStore } from '../../store'

const Wrapper = ({
  children,
  activeWrapper = true,
  isQuickOffer = false,
  activeFooter = true,
  disableStyles = false,
  isSuperSearch = false,
  isLanding = false,
}) => {
  const navigate = useNavigate()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { setIsLostConection } = useLostConectionStore()

  useEffect(() => {
    setInterval(function () {
      if (!navigator.onLine) {
        navigate('/internet-connection-lost')
        setIsLostConection(false)
      }
    }, 2500)
  }, [navigator.onLine])

  return (
    <div>
      {openDrawer && <NavbarDrawer setOpenDrawer={setOpenDrawer} />}
      {activeWrapper && (
        <Navbar
          isQuickOffer={isQuickOffer}
          setOpenDrawer={setOpenDrawer}
          isSuperSearch={isSuperSearch}
          isLanding={isLanding}
        />
      )}
      <div className={`${disableStyles ? '' : 'wrapper-styles'}`}>
        {children}
      </div>
      {activeWrapper && activeFooter && <Footer />}
    </div>
  )
}

export default Wrapper
