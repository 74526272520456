import { create } from 'zustand'
import { DICTIONARY, SORT_VALUES } from '../utils/constants'
import dayjs from 'dayjs'
// import dayjs from 'dayjs'

export const useListOfTalentsStore = create((set) => ({
  data: [],
  page: 0,
  countTalents: 0,
  setData: (data) => set(() => ({ data })),
  setPage: (page) => set(() => ({ page })),
  setCountTalents: (value) => set(() => ({ countTalents: value })),
}))

export const useListOfTalentsBySuperSearchStore = create((set) => ({
  data: [],
  dataWithoutBanners: [],
  page: 0,
  countTalent: 0,
  id: null,

  selectedCategories: [],
  currentCategories: [],
  filteredCategories: [],

  selectedSkills: [],
  currentSkills: [],
  filteredSkills: [],
  actionButton: 0,
  firstSearch: false,
  countTalents: 0,

  hideSearch: false,

  setData: (data) => set(() => ({ data })),
  setDataWithoutBanners: (dataWithoutBanners) =>
    set(() => ({ dataWithoutBanners })),
  setPage: (page) => set(() => ({ page })),
  setCountTalent: (countTalent) => set(() => ({ countTalent })),
  setId: (id) => set(() => ({ id })),
  setSelectedCategories: (value) => set(() => ({ selectedCategories: value })),
  setCurrentCategories: (value) => set(() => ({ currentCategories: value })),
  setFilteredCategories: (value) => set(() => ({ filteredCategories: value })),

  setSelectedSkills: (value) => set(() => ({ selectedSkills: value })),
  setCurrentSkills: (value) => set(() => ({ currentSkills: value })),
  setFilteredSkills: (value) => set(() => ({ filteredSkills: value })),

  setActionButton: (value) => set(() => ({ actionButton: value })),
  setFirstSearch: (value) => set(() => ({ firstSearch: value })),
  setCountTalents: (value) => set(() => ({ countTalents: value })),

  setHideSearch: (value) => set(() => ({ hideSearch: value })),

  resetFilters: () =>
    set(() => ({ selectedCategories: [], currentCategories: [] })),
}))

export const useCompanieStore = create((set) => ({
  id: null,
  setId: (id) => set(() => ({ id })),
}))

export const useCompanieByHiringAdvisorStore = create((set) => ({
  label: 'dashboard',
  setLabel: (label) => set(() => ({ label })),
}))

export const useUpdateCompanyIdStore = create((set) => ({
  id: null,
  setId: (id) => set(() => ({ id })),
}))

export const useUpdateJobOfferIdStore = create((set) => ({
  id: null,
  setId: (id) => set(() => ({ id })),
}))

export const useUpdateProcessStore = create((set) => ({
  idProcess: null,
  idTalent: null,
  idCandidate: null,
  idColumn: null,
  idOldColumns: null,
  idNewColumns: null,
  newPosition: null,
  hideProcessSidebar: false,
  username: null,
  tabBarValue: 'candidates',
  page: 0,
  setIdProcess: (idProcess) => set(() => ({ idProcess })),
  setIdTalent: (idTalent) => set(() => ({ idTalent })),
  setIdColumn: (idColumn) => set(() => ({ idColumn })),
  setIdOldColumns: (idOldColumns) => set(() => ({ idOldColumns })),
  setIdNewColumns: (idNewColumns) => set(() => ({ idNewColumns })),
  setNewPosition: (newPosition) => set(() => ({ newPosition })),
  setIdCandidate: (idCandidate) => set(() => ({ idCandidate })),
  setHideProcessSidebar: (hideProcessSidebar) =>
    set(() => ({ hideProcessSidebar })),
  setUsername: (username) => set(() => ({ username })),
  setTabBarValue: (tabBarValue) => set(() => ({ tabBarValue })),
  setPage: (page) => set(() => ({ page })),
}))

export const useChatStore = create((set) => ({
  open: false,
  id: null,
  prevId: null,
  detailData: {},
  online: false,
  currentMessageHistoryData: [],
  currentScroll: 0,
  setOpen: (open) => set(() => ({ open })),
  setId: (id) => set(() => ({ id })),
  setDetailData: (detailData) => set(() => ({ detailData })),
  setOnline: (value) => set(() => ({ online: value })),
  setCurrentMessageHistoryData: (value) =>
    set(() => ({ currentMessageHistoryData: value })),
  setCurrentScroll: (value) => set(() => ({ currentScroll: value })),
  setPrevId: (value) => set(() => ({ prevId: value })),
}))

export const useChatgptAssistantStore = create((set) => ({
  typeView: 'default',
  setTypeView: (typeView) => set(() => ({ typeView })),
}))

export const useChangeManagerStore = create((set) => ({
  id: null,
  page: 0,
  searchText: '',
  setId: (id) => set(() => ({ id })),
  setPage: (value) => set(() => ({ page: value })),
  setSearchText: (value) => set(() => ({ searchText: value })),
}))

export const useMasterStore = create((set) => ({
  idSkill: null,
  idSoftSkill: null,
  nameSkill: '',
  nameSoftSkill: '',
  idTm: null,
  activeSoftSkill: true,
  setNameSkill: (name) => set(() => ({ nameSkill: name })),
  setIdSkill: (id) => set(() => ({ idSkill: id })),
  setIdTm: (id) => set(() => ({ idTm: id })),
  setNameSoftSkill: (name) => set(() => ({ nameSoftSkill: name })),
  setIdSoftSkill: (id) => set(() => ({ idSoftSkill: id })),
  setActiveSoftSkill: (active) => set(() => ({ activeSoftSkill: active })),
}))

export const useLandingStore = create((set) => ({
  showWsBubble: false,
  typeOfPlan: null,
  setShowWsBubble: (value) => set(() => ({ showWsBubble: value })),
  setTypeOfPlan: (value) => set(() => ({ typeOfPlan: value })),
}))

export const useSearchTalentsFiltersStore = create((set) => ({
  selectedCategories: [],
  currentCategories: [],
  filteredCategories: [],

  skills: [],
  selectedSkills: [],
  filteredSkills: [],

  languages: [],
  selectedLanguages: [],

  experience: [],
  selectedExperience: [],

  keywordGlobalValue: '',

  setSelectedCategories: (value) => set(() => ({ selectedCategories: value })),
  setCurrentCategories: (value) => set(() => ({ currentCategories: value })),
  setFilteredCategories: (value) => set(() => ({ filteredCategories: value })),

  setSkills: (value) => set(() => ({ skills: value })),
  setSelectedSkills: (value) => set(() => ({ selectedSkills: value })),
  setFilteredSkills: (value) => set(() => ({ filteredSkills: value })),

  setLanguages: (value) => set(() => ({ languages: value })),
  setSelectedLanguages: (value) => set(() => ({ selectedLanguages: value })),

  setExperience: (value) => set(() => ({ experience: value })),
  setSelectedExperience: (value) => set(() => ({ selectedExperience: value })),
  setFilterByWords: (value) => set(() => ({ keywordGlobalValue: value })),
}))

export const useSupportStore = create((set) => ({
  showChatRoom: false,
  tabBarState: DICTIONARY.talent,
  ticketAdminId: null,
  page: 0,
  historyMessagePage: 0,
  setShowChatRoom: (value) => set(() => ({ showChatRoom: value })),
  setTabBarState: (value) => set(() => ({ tabBarState: value })),
  setPage: (value) => set(() => ({ page: value })),
  setHistoryMessagePage: (value) => set(() => ({ historyMessagePage: value })),
  setTicketAdminId: (value) => set(() => ({ ticketAdminId: value })),
}))

export const useAdminTalentsStore = create((set) => ({
  page: 0,
  searchValue: 'all',
  currentRecruiter: 0,
  filterByValidate: 0,
  filterByCreationDate: 'false',
  dateBefore: '',
  dateAfter: '',
  username: '',
  email: '',
  talentId: null,
  profileFilter: 0,
  talentManagerFilter: 0,
  checkDateFilter: 1,
  countryFilter: '',
  textFilter: '',
  sort: SORT_VALUES.desc,
  setPage: (value) => set(() => ({ page: value })),
  setSearchValue: (value) => set(() => ({ searchValue: value })),
  setCurrentRecruiter: (value) => set(() => ({ currentRecruiter: value })),
  setFilterByValidate: (value) => set(() => ({ filterByValidate: value })),
  setFilterByCreationDate: (value) =>
    set(() => ({ filterByCreationDate: value })),
  setDateBefore: (value) => set(() => ({ dateBefore: value })),
  setDateAfter: (value) => set(() => ({ dateAfter: value })),
  setUsername: (value) => set(() => ({ username: value })),
  setEmail: (value) => set(() => ({ email: value })),
  setTalentId: (value) => set(() => ({ talentId: value })),
  setProfileFilter: (value) => set(() => ({ profileFilter: value })),
  setTalentManagerFilter: (value) =>
    set(() => ({ talentManagerFilter: value })),
  setCheckDateFilter: (value) => set(() => ({ checkDateFilter: value })),
  setCountryFilter: (value) => set(() => ({ countryFilter: value })),
  setTextFilter: (value) => set(() => ({ textFilter: value })),
  setSort: (value) => set(() => ({ sort: value })),
}))

export const useAdminRecruiterStore = create((set) => ({
  page: 0,
  textToSearch: '',
  filterByDeleteTm: '',
  setPage: (value) => set(() => ({ page: value })),
  setTextToSearch: (value) => set(() => ({ textToSearch: value })),
  setFilterByDeleteTm: (value) => set(() => ({ filterByDeleteTm: value })),
}))

export const useRecruiterNetworkStore = create((set) => ({
  page: 0,
  setPage: (value) => set(() => ({ page: value })),
}))

export const useTmTalentsStore = create((set) => ({
  page: 0,
  search: '',
  tabBarValue: 'without_validating',
  setPage: (value) => set(() => ({ page: value })),
  setSearch: (value) => set(() => ({ search: value })),
  setTabBarValue: (value) => set(() => ({ tabBarValue: value })),
}))

export const useIncompleteProfilesStore = create((set) => ({
  page: 0,
  setPage: (value) => set(() => ({ page: value })),
}))

export const useFavoriteCandidatesStore = create((set) => ({
  page: 0,
  setPage: (value) => set(() => ({ page: value })),
}))

export const useOffersTalentWithTmStore = create((set) => ({
  page: 0,
  selectedButton: 0,
  setPage: (value) => set(() => ({ page: value })),
  setSelectedButton: (value) => set(() => ({ selectedButton: value })),
}))

export const useTmMyTalentsFiltersStore = create((set) => ({
  tabBarValue: 1,

  categoryValue: '',
  selectedCategories: [],
  filteredCategories: [],
  currentCategories: [],

  skillValue: '',
  skills: [],
  filteredSkills: [],
  selectedSkills: [],

  experience: [],
  selectedExperience: [],

  feedback: 0,

  setTabBarValue: (value) => set(() => ({ tabBarValue: value })),

  setCategoryValue: (value) => set(() => ({ categoryValue: value })),
  setFilteredCategories: (value) => set(() => ({ filteredCategories: value })),
  setCurrentCategories: (value) => set(() => ({ currentCategories: value })),
  setSelectedCategories: (value) => set(() => ({ selectedCategories: value })),

  setSkillValue: (value) => set(() => ({ skillValue: value })),
  setSkills: (value) => set(() => ({ skills: value })),
  setFilteredSkills: (value) => set(() => ({ filteredSkills: value })),
  setSelectedSkills: (value) => set(() => ({ selectedSkills: value })),

  setExperience: (value) => set(() => ({ experience: value })),
  setSelectedExperience: (value) => set(() => ({ selectedExperience: value })),

  setFeedback: (value) => set(() => ({ feedback: value })),
}))

export const useJobOffersRecivedFiltersStore = create((set) => ({
  tabBarValue: 1,

  categoryValue: '',
  selectedCategories: [],
  filteredCategories: [],
  currentCategories: [],

  skillValue: '',
  skills: [],
  filteredSkills: [],
  selectedSkills: [],

  experience: [],
  selectedExperience: [],

  setTabBarValue: (value) => set(() => ({ tabBarValue: value })),

  setCategoryValue: (value) => set(() => ({ categoryValue: value })),
  setFilteredCategories: (value) => set(() => ({ filteredCategories: value })),
  setCurrentCategories: (value) => set(() => ({ currentCategories: value })),
  setSelectedCategories: (value) => set(() => ({ selectedCategories: value })),

  setSkillValue: (value) => set(() => ({ skillValue: value })),
  setSkills: (value) => set(() => ({ skills: value })),
  setFilteredSkills: (value) => set(() => ({ filteredSkills: value })),
  setSelectedSkills: (value) => set(() => ({ selectedSkills: value })),

  setExperience: (value) => set(() => ({ experience: value })),
  setSelectedExperience: (value) => set(() => ({ selectedExperience: value })),
}))

export const useAllJobOffersFiltersStore = create((set) => ({
  tabBarValue: 1,

  categoryValue: '',
  selectedCategories: [],
  filteredCategories: [],
  currentCategories: [],

  skillValue: '',
  skills: [],
  filteredSkills: [],
  selectedSkills: [],

  experience: [],
  selectedExperience: [],

  setTabBarValue: (value) => set(() => ({ tabBarValue: value })),

  setCategoryValue: (value) => set(() => ({ categoryValue: value })),
  setFilteredCategories: (value) => set(() => ({ filteredCategories: value })),
  setCurrentCategories: (value) => set(() => ({ currentCategories: value })),
  setSelectedCategories: (value) => set(() => ({ selectedCategories: value })),

  setSkillValue: (value) => set(() => ({ skillValue: value })),
  setSkills: (value) => set(() => ({ skills: value })),
  setFilteredSkills: (value) => set(() => ({ filteredSkills: value })),
  setSelectedSkills: (value) => set(() => ({ selectedSkills: value })),

  setExperience: (value) => set(() => ({ experience: value })),
  setSelectedExperience: (value) => set(() => ({ selectedExperience: value })),
}))

export const useActiveProcessesStore = create((set) => ({
  tabBarValue: 1,

  categoryValue: '',
  selectedCategories: [],
  filteredCategories: [],
  currentCategories: [],

  skillValue: '',
  skills: [],
  filteredSkills: [],
  selectedSkills: [],

  experience: [],
  selectedExperience: [],

  searchText: '',
  offerSubmissionId: 0,
  talentId: 0,
  sort: 'asc',
  page: 0,

  setTabBarValue: (value) => set(() => ({ tabBarValue: value })),

  setCategoryValue: (value) => set(() => ({ categoryValue: value })),
  setFilteredCategories: (value) => set(() => ({ filteredCategories: value })),
  setCurrentCategories: (value) => set(() => ({ currentCategories: value })),
  setSelectedCategories: (value) => set(() => ({ selectedCategories: value })),

  setSkillValue: (value) => set(() => ({ skillValue: value })),
  setSkills: (value) => set(() => ({ skills: value })),
  setFilteredSkills: (value) => set(() => ({ filteredSkills: value })),
  setSelectedSkills: (value) => set(() => ({ selectedSkills: value })),

  setExperience: (value) => set(() => ({ experience: value })),
  setSelectedExperience: (value) => set(() => ({ selectedExperience: value })),

  setSearchText: (value) => set(() => ({ searchText: value })),
  setOfferSubmissionId: (value) => set(() => ({ offerSubmissionId: value })),
  setTalentId: (value) => set(() => ({ talentId: value })),
  setSort: (value) => set(() => ({ sort: value })),
  setPage: (value) => set(() => ({ page: value })),
}))

export const useSearchTalentsStore = create((set) => ({
  page: 0,
  searchValue: '',
  setPage: (value) => set(() => ({ page: value })),
  setSearchValue: (value) => set(() => ({ searchValue: value })),
}))

export const useKpisStore = create((set) => ({
  talentDateBefore: '',
  talentDateAfter: '',
  talentShowLineBar: false,

  experienceDateBefore: '',
  experienceDateAfter: '',
  experienceShowLineBar: false,

  talentManagerDateBefore: '',
  talentManagerDateAfter: '',
  talentManagerShowLineBar: false,
  searchTm: '',

  setTalentDateBefore: (value) => set(() => ({ talentDateBefore: value })),
  setTalentDateAfter: (value) => set(() => ({ talentDateAfter: value })),
  setTalentShowLineBar: (value) => set(() => ({ talentShowLineBar: value })),

  setExperienceDateBefore: (value) =>
    set(() => ({ experienceDateBefore: value })),
  setExperienceDateAfter: (value) =>
    set(() => ({ experienceDateAfter: value })),
  setExperienceShowLineBar: (value) =>
    set(() => ({ experienceShowLineBar: value })),

  setTalentManagerDateBefore: (value) =>
    set(() => ({ talentManagerDateBefore: value })),
  setTalentManagerDateAfter: (value) =>
    set(() => ({ talentManagerDateAfter: value })),
  setTalentManagerShowLineBar: (value) =>
    set(() => ({ talentManagerShowLineBar: value })),
  setSearchTm: (value) => set(() => ({ searchTm: value })),
}))

export const useTalentManagerLastConectionStore = create((set) => ({
  page: 0,
  searchValue: '',
  dateBefore: '2023-03-01',
  dateAfter: dayjs().format('YYYY-MM-DD'),
  setPage: (value) => set(() => ({ page: value })),
  setSearchValue: (value) => set(() => ({ searchValue: value })),
  setDateBefore: (value) => set(() => ({ dateBefore: value })),
  setDateAfter: (value) => set(() => ({ dateAfter: value })),
}))

export const useAllOffersFromRecruiterStore = create((set) => ({
  page: 0,
  id: null,
  setPage: (value) => set(() => ({ page: value })),
  setId: (value) => set(() => ({ id: value })),
}))

export const useOrderCompaniesStore = create((set) => ({
  page: 0,
  search: '',
  setPage: (value) => set(() => ({ page: value })),
  setSearch: (value) => set(() => ({ search: value })),
}))

export const useTmCompaniesStore = create((set) => ({
  page: 0,
  search: '',
  tabBarState: 1,
  setPage: (value) => set(() => ({ page: value })),
  setSearch: (value) => set(() => ({ search: value })),
  setTabBarState: (value) => set(() => ({ tabBarState: value })),
}))

export const useAdminCompaniesStore = create((set) => ({
  page: 0,
  search: '',
  tabBarValue: '',
  setPage: (value) => set(() => ({ page: value })),
  setSearch: (value) => set(() => ({ search: value })),
  setTabBarValue: (value) => set(() => ({ tabBarValue: value })),
}))

export const useChangeRecruiterStore = create((set) => ({
  id: null,
  recruiterName: null,
  setId: (value) => set(() => ({ id: value })),
  setRecruiterName: (value) => set(() => ({ recruiterName: value })),
}))

export const useTalentInformationStore = create((set) => ({
  qualityOfProfile: 'red',
  generalQualityProfile: 'red',
  setQualityOfProfile: (value) => set(() => ({ qualityOfProfile: value })),
  setGeneralQualityProfile: (value) =>
    set(() => ({ generalQualityProfile: value })),
}))

export const useLostConectionStore = create((set) => ({
  isLostConection: false,
  lostConectionImage: '',
  setIsLostConection: (isLostConection) => set(() => ({ isLostConection })),
  setLostConectionImage: (lostConectionImage) =>
    set(() => ({ lostConectionImage })),
}))

export const useCompanyNotesStore = create((set) => ({
  id: null,
  setId: (id) => set(() => ({ id })),
}))

export const useQuickOfferStore = create((set) => ({
  globalCategorie: null,
  setGlobalCategorie: (globalCategorie) => set(() => ({ globalCategorie })),
}))

export const useOAuthStore = create((set) => ({
  googleAccessToken: null,
  setGoogleAccessToken: (value) => set(() => ({ googleAccessToken: value })),
}))

export const useAddFavoriteStore = create((set) => ({
  newFavorite: null,
  setNewFavorite: (value) => set(() => ({ newFavorite: value })),
}))

export const useTalentActiveProcessesStore = create((set) => ({
  showTalentActiveProcesses: false,
  id: null,
  setShowTalentActiveProcesses: (value) =>
    set(() => ({ showTalentActiveProcesses: value })),
  setId: (value) => set(() => ({ id: value })),
}))
