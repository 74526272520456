const ListUserIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/demography">
        <mask
          id="mask0_12536_880"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="44"
          height="44"
        >
          <rect
            id="Bounding box"
            x="0.733307"
            y="0.399994"
            width="43.2"
            height="43.2"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_12536_880)">
          <path
            id="icon"
            d="M33.1333 32.8C33.8833 32.8 34.5208 32.5375 35.0458 32.0125C35.5708 31.4875 35.8333 30.85 35.8333 30.1C35.8333 29.35 35.5708 28.7125 35.0458 28.1875C34.5208 27.6625 33.8833 27.4 33.1333 27.4C32.3833 27.4 31.7458 27.6625 31.2208 28.1875C30.6958 28.7125 30.4333 29.35 30.4333 30.1C30.4333 30.85 30.6958 31.4875 31.2208 32.0125C31.7458 32.5375 32.3833 32.8 33.1333 32.8ZM33.1333 38.2C34.0333 38.2 34.8733 37.99 35.6533 37.57C36.4333 37.15 37.0783 36.565 37.5883 35.815C36.8983 35.395 36.1783 35.0875 35.4283 34.8925C34.6783 34.6975 33.9133 34.6 33.1333 34.6C32.3533 34.6 31.5883 34.6975 30.8383 34.8925C30.0883 35.0875 29.3683 35.395 28.6783 35.815C29.1883 36.565 29.8333 37.15 30.6133 37.57C31.3933 37.99 32.2333 38.2 33.1333 38.2ZM9.7333 38.2C8.7433 38.2 7.8958 37.8475 7.1908 37.1425C6.4858 36.4375 6.1333 35.59 6.1333 34.6V9.39999C6.1333 8.40999 6.4858 7.56249 7.1908 6.85749C7.8958 6.15249 8.7433 5.79999 9.7333 5.79999H34.9333C35.9233 5.79999 36.7708 6.15249 37.4758 6.85749C38.1808 7.56249 38.5333 8.40999 38.5333 9.39999V21.46C37.9633 21.19 37.3783 20.9575 36.7783 20.7625C36.1783 20.5675 35.5633 20.425 34.9333 20.335V9.39999H9.7333V34.6H20.6233C20.7133 35.26 20.8558 35.89 21.0508 36.49C21.2458 37.09 21.4783 37.66 21.7483 38.2H9.7333ZM9.7333 34.6V9.39999V20.335V20.2V34.6ZM13.3333 31H20.6683C20.7583 30.37 20.9008 29.755 21.0958 29.155C21.2908 28.555 21.5083 27.97 21.7483 27.4H13.3333V31ZM13.3333 23.8H24.3133C25.2733 22.9 26.3458 22.15 27.5308 21.55C28.7158 20.95 29.9833 20.545 31.3333 20.335V20.2H13.3333V23.8ZM13.3333 16.6H31.3333V13H13.3333V16.6ZM33.1333 41.8C30.6433 41.8 28.5208 40.9225 26.7658 39.1675C25.0108 37.4125 24.1333 35.29 24.1333 32.8C24.1333 30.31 25.0108 28.1875 26.7658 26.4325C28.5208 24.6775 30.6433 23.8 33.1333 23.8C35.6233 23.8 37.7458 24.6775 39.5008 26.4325C41.2558 28.1875 42.1333 30.31 42.1333 32.8C42.1333 35.29 41.2558 37.4125 39.5008 39.1675C37.7458 40.9225 35.6233 41.8 33.1333 41.8Z"
            fill="#407BFF"
          />
        </g>
      </g>
    </svg>
  )
}

export default ListUserIcon
