const Modal = ({
  title = 'Modal title',
  modalSize = '',
  className,
  children,
  modalId,
  titleClassName = '',
  renderIcon = false,
  renderTitle = true,
  renderCloseButton = true,
  renderModalHeader = true,
  Icon = <></>,
  modalBodyClassName = '',
  modalContentClassName = '',
  ...rest
}) => {
  return (
    <div
      className={`${className} modal fade modal-custom-styles`}
      id={modalId}
      {...rest}
    >
      <div className={`modal-dialog ${modalSize}  modal-dialog-centered`}>
        <div className={`${modalContentClassName} modal-content`}>
          {renderModalHeader && (
            <div className="modal-header border-0">
              <div className="d-flex align-items-center gap-2">
                {renderIcon && <Icon />}
                {renderTitle && (
                  <h1
                    className={`${titleClassName} modal-title fs-5 fw-bolder text-on-bg-primary`}
                    id="exampleModalLabel"
                  >
                    {title}
                  </h1>
                )}
              </div>
              {renderCloseButton && (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              )}
            </div>
          )}
          <div className={`modal-body ${modalBodyClassName}`}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Modal
