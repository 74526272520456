import { useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../Routes/routes'

import HutritIcon from '../../icons/HutritIcon'
import Button from '../Button'
import Notifications from '../Notifications/Notifications'
import useResize from '../../hooks/useResize'
import { userInfo } from '../../localStorage'
import MenuBurgerTwoIcon from '../../icons/MenuBurgerTwoIcon'
import { VIEWPORT_LG } from '../../utils/constants'
import HutritGreenIcon from '../../icons/HutritGreenIcon'
import { useTranslation } from 'react-i18next'
import Translate from './components/Translate'
import SearchTalents from '../SearchTalents'
import ButtonSuperSearchIcon from '../../icons/ButtonSuperSearchIcon'

const Navbar = ({
  setOpenDrawer = () => {},
  isQuickOffer = false,
  isSuperSearch = false,
  isLanding = false,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem('token'))

  const typeOfUser = userInfo?.payload?.typeUser
  const viewPortSize = useResize()

  const [scroll, setScroll] = useState(false)
  const [openUserOptions, setOpenUserOptions] = useState(false)

  const handleNavigation = useCallback(() => {
    setScroll(!window.scrollY <= 0)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation)
    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  const handleLogin = () => {
    navigate(PUBLIC_ROUTES.login)
  }

  const handleGoToRegister = () => {
    navigate(PUBLIC_ROUTES.register)
  }

  const renderNavbar = isLanding ? (
    <header
      className={`${
        window.location.pathname === PRIVATE_ROUTES.talents ? 'mb-2' : ''
      }  ${
        viewPortSize[0] <= VIEWPORT_LG
          ? 'bg-bg-brand-pressed'
          : isQuickOffer
          ? 'bg-on-bg-Tertiary-hover'
          : 'bg-bg-brand-pressed'
      } `}
    >
      <nav
        className={`${scroll ? 'bg-bg-brand' : ''} navbar fixed-nav custom-transition`}
        style={{ paddingLeft: '32px', paddingRight: '32px', zIndex: 200 }}
      >
        <div className="d-flex container-fluid w-100 mt-2 mb-2">
          <a
            className="mt-2 cursor-pointer"
            onClick={() => navigate(PUBLIC_ROUTES.home)}
          >
            {viewPortSize[0] <= VIEWPORT_LG ? (
              <HutritIcon />
            ) : isQuickOffer ? (
              <HutritGreenIcon />
            ) : (
              <HutritIcon />
            )}
          </a>
          {isSuperSearch && (
            <div className="d-lg-flex d-none">
              <SearchTalents isSuperSearch={isSuperSearch} />
            </div>
          )}
          {!isSuperSearch && (
            <div
              className="cursor-pointer d-lg-flex d-none"
              onClick={() => navigate('/super-search/')}
              style={{marginLeft: '200px'}}
            >
              <ButtonSuperSearchIcon />
            </div>
          )}
          <div
            className="d-lg-none d-flex cursor-pointer"
            onClick={() => setOpenDrawer((prevState) => !prevState)}
          >
            <MenuBurgerTwoIcon />
          </div>

          <div className="d-none d-lg-flex flex-row align-items-center">
            <div className="login d-lg-inline-block  me-4">
              <span onClick={handleLogin} className="text-white cursor-pointer">
                {t('log_in')}
              </span>
            </div>
            <Button
              id="navbar-register-button"
              className="border-bg-CTA-primary text-bg-CTA-primary"
              onClick={handleGoToRegister}
              style={{
                background: 'transparent',
              }}
            >
              {t('sign_up')}
            </Button>
            <div className="ms-2">
              <Translate isQuickOffer={isQuickOffer} />
            </div>
          </div>
          <Notifications
            typeOfUser={typeOfUser}
            openUserOptions={openUserOptions}
            setOpenUserOptions={setOpenUserOptions}
            token={token}
            userInfo={userInfo}
          />
        </div>
      </nav>
    </header>
  ) : (
    <header
      className={`${
        window.location.pathname === PRIVATE_ROUTES.talents ? 'mb-2' : ''
      }  ${
        viewPortSize[0] <= VIEWPORT_LG
          ? 'bg-bg-brand-pressed'
          : isQuickOffer
          ? 'bg-on-bg-Tertiary-hover'
          : 'bg-bg-brand-pressed'
      } `}
    >
      <nav
        className={`navbar ${
          scroll
            ? 'fixed-nav bg-bg-brand'
            : viewPortSize[0] <= VIEWPORT_LG
            ? ''
            : 'wrapper-margin'
        }`}
      >
        <div className="container-fluid mt-2 mb-2">
          <a
            className={`${
              scroll ? 'fixed-margin-left' : ''
            }  mt-2 cursor-pointer`}
            onClick={() => navigate(PUBLIC_ROUTES.home)}
          >
            {viewPortSize[0] <= VIEWPORT_LG ? (
              <HutritIcon />
            ) : isQuickOffer ? (
              <HutritGreenIcon />
            ) : (
              <HutritIcon />
            )}
          </a>

          {isSuperSearch && (
            <div className="d-lg-flex d-none">
              <SearchTalents isSuperSearch={isSuperSearch} />
            </div>
          )}
          <div
            className="d-lg-none d-flex cursor-pointer"
            onClick={() => setOpenDrawer((prevState) => !prevState)}
          >
            <MenuBurgerTwoIcon />
          </div>

          <div
            className={`${
              scroll ? 'fixed-margin-right' : ''
            } d-none d-lg-flex flex-row align-items-center `}
          >
            <div className="login d-lg-inline-block  me-4">
              <span
                onClick={handleLogin}
                className={`${
                  isQuickOffer && !scroll
                    ? 'text-on-bg-primary'
                    : scroll
                    ? 'text-bg-primary bg-bg-brand'
                    : 'text-white'
                } cursor-pointer`}
              >
                {t('log_in')}
              </span>
            </div>
            <Button
              id="navbar-register-button"
              className={`${
                isQuickOffer && !scroll
                  ? 'bg-on-bg-Tertiary-hover border-on-bg-primary'
                  : scroll
                  ? 'btn-bg-brand border-bg-CTA-primary text-bg-CTA-primary'
                  : 'btn-bg-brand-pressed border-bg-CTA-primary text-bg-CTA-primary'
              }`}
              onClick={handleGoToRegister}
            >
              {t('sign_up')}
            </Button>
            <div className="ms-2">
              <Translate isQuickOffer={isQuickOffer} />
            </div>
          </div>
          <Notifications
            typeOfUser={typeOfUser}
            openUserOptions={openUserOptions}
            setOpenUserOptions={setOpenUserOptions}
            token={token}
            userInfo={userInfo}
          />
        </div>
      </nav>
    </header>
  )

  return renderNavbar
}

export default Navbar

//bg-on-bg-Tertiary-hover
