/* eslint-disable quotes */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { idiom } from '../localStorage'

const resources = {
  en: {
    translation: {
      home: 'Home',
      how_does_it_work: 'How does it work',
      start_now: 'Start now',
      personal_profile: 'Personal Profile',
      location: 'Location',
      additional_data: 'Additional data',
      professional_profile: 'Professional Profile',
      profession: 'Profession',
      skills: 'Skills',
      skill: 'Skill',
      work_experience: 'Work Experience',
      staff: 'Personal Data',
      //eslint-disable-next-line
      lets_complete_your_profile: "Let's complete your profile",
      you_are_just_a_few_steps_away_from_completing_your_profile_and_attracting_the_best_opportunities:
        'You are just a few steps away from completing your profile and attracting the best opportunities.',
      tell_us_where_you_are_from: 'Tell us where you are from',
      country: 'Country',
      state_or_region: 'State or Region',
      city: 'City',
      next: 'Next',
      these_data_will_not_be_shared_with_companies_your_profile_will_remain_anonymous:
        'These data will not be shared with companies. Your profile will remain anonymous.',
      write_your_contact_information: 'Write your contact information',
      phone_number: 'Phone Number',
      identification_document: 'Identification Document',
      linkedIn_profile: 'LinkedIn Profile',
      tell_us_about_yourself: 'Tell us about yourself',
      years_of_experience: 'Years Of Experience',
      we_will_use_this_information_to_find_the_ideal_job_for_you:
        'We will use this information to find the ideal job for you',
      highlight_your_skills: 'Highlight your skills',
      focus_on_relevant_skills_highlight_those_related_to_the_position_you_hope_to_get:
        'Focus on relevant skills, highlight those related to the position you hope to get.',
      tip: 'Tip',
      english_level: 'English level',
      english: 'English',
      beginner: 'Beginner',
      intermediate: 'Intermediate',
      advanced: 'Advanced',
      professional: 'Professional Data',
      required: 'Required',
      level: 'Level',
      competence: 'Competence',
      this_field_is_required: 'This field is required',
      tell_us_about_your_experience: 'Tell us about your experience',
      highlight_your_most_relevant_achievements_See_an_example_of_a:
        'Highlight your most relevant achievements. See an example of a',
      successful_profile: 'successful profile',
      post: 'Position',
      remove_experience: 'Remove experience',
      sector: 'Sector',
      company: 'Company',
      return: 'Return',
      finish: 'Finish',
      finish_date: 'Finish date',
      start_date: 'Start date',
      choose_the_position: 'Choose the position',
      enter_the_company: 'Enter the company',
      choose_the_sector: 'Choose the sector',
      i_work_here_currently: 'I work here currently',
      activities_carried_out_and_achievements_obtained:
        'Activities carried out and achievements obtained',
      you_have_completed_your_profile: 'You have completed your profile!',
      you_are_one_step_closer_to_starting_your_next_project:
        'You are one step closer to starting your next project',
      back_to_top: 'back to the top',
      view_your_profile: 'View your profile',
      see_less: 'See less',
      see_more: 'See more',
      show_all_experiences: 'Show all experiences',
      review_now: 'Review now',
      remote: 'Remote',
      requirements: 'Requirements',
      required_skills: 'Required skills',
      desired_profile_and_additional_benefits:
        'Desired profile and additional benefits',
      description_of_the_position_and_functions_to_be_performed:
        'Description of the position and functions to be performed',
      download: 'Download',
      preview_what_the_offer_will_look_like:
        'Preview what the offer will look like',
      Share: 'Share',
      we_seek: 'We seek',
      write_me_or_leave_me_a_comment_for_more_information:
        'Write me or leave me a comment for more information',
      share_this_offer: 'Share this offer',
      these_data_will_not_be_shared_with_companies:
        'These data will not be shared with companies',
      your_profile_will_remain_anonymous: 'Your profile will remain anonymous.',
      personal_information_updated_correctly:
        'Personal information updated correctly',
      error_in_the_system: 'Error in the system',
      save: 'Save',
      visualize_and_highlight_your_professional_profile_as_companies_see_it:
        'Visualize and highlight your professional profile as companies see it',
      available: 'Available',
      not_available: 'Not available',
      of: 'of',
      experience: 'Experience',
      favorite: 'Favorite',
      go_back: 'Go back',
      sign_up_in_seconds: 'Sign up in seconds',
      general_skills: 'General skills',
      show_all_skills: 'Show all skills',
      professional_header: 'Professional header',
      professional_information_updated_correctly:
        'Professional information updated correctly',
      successfully_updated_work_experience: 'Work experience updated correctly',
      active_offers: 'Active offers',
      offers_sent: 'Offers sent',
      offers_accepted: 'Offers accepted',
      attracts_the_attention_of_companies: 'Attract Companies Attention',
      find_the_talent_you_need: 'Find the talent you need',
      your_profile_is_your_showcase_to_new_opportunities:
        'Your profile is your window to new opportunities.',
      by_completing_it_you_allow_ompanies_to_discover_you:
        'By completing it, you allow companies to discover you.',
      open_the_doors_to_a_universe_of_qualified_professionals:
        'Open the doors to a universe of qualified professionals.',
      complete_your_profile_create_your_job_offer_and_let_our_platform_take_care_of_the_rest:
        'Complete your profile, create your job offer and let our platform take care of the rest.',
      complete_my_profile: 'Complete my profile',
      offers: 'Job Offers',
      talents: 'Talents',
      my_profile: 'My profile',
      company_profile: 'Company profile',
      user_profile: 'User profile',
      statistics: 'Statistics',
      your_summary: 'Your summary',
      your_progress: 'Your progress',
      back: 'Back',
      //eslint-disable-next-line
      lets_start: "Let's Start",
      lets_find_the_talent_you_are_looking_for:
        'Lets find the talent you are looking for',
      we_help_you_find_the_talent_you_need_in_a_few_steps:
        'We help you find the talent you need in a few steps',
      about_the_company: 'About the company',
      company_data: 'Company data',
      contact_information: 'Contact information',
      business_values: 'Business values',
      tell_us_about_the_company: 'Tell us about the company',
      company_name: 'Company name',
      the_business_social_reason: 'The business social reason',
      tax_registry: 'Tax registry',
      company_sector: 'Company sector',
      team_size: 'Team size',
      years_of_the_company: 'Years of the company',
      we_will_use_this_data_to_make_a_precise_match_with_the_ideal_talent_for_your_company:
        'We will use this data to make a precise match with the ideal talent for your company.',
      company_logo: 'Company logo',
      or_drag_the_company_logo: 'Or drag the company logo',
      goes_up: 'Upload',
      accepted_formats: 'Accepted formats',
      drop_here: 'Drop here',
      tell_us_where_they_are: 'Tell us where they are',
      later_you_will_be_able_to_find_talent_in_person:
        'Later you will be able to find talent in person',
      tell_us_about_your_values: 'Tell us about your values',
      attract_the_right_talent_to_your_company_with_values_aligned_with_theirs:
        'Attract the right talent to your company with values aligned with theirs',
      mission: 'Mission',
      optional: 'Optional',
      vision: 'Vision',
      brief_description: 'Brief description',
      aspects_you_want_to_highlight: 'Aspects you want to highlight',
      now_create_your_first_offer_and_discover_the_talent_that_will_transform_your_company:
        'Now, create your first offer and discover the talent that will transform your company.',
      create_offer: 'Create offer',
      your_new_offers_are_coming: 'Your new offers are coming',
      professional_experience: 'Professional experience',
      my_offers: 'My offers',
      all: 'All',
      allTwo: 'All',
      accepted: 'Accepted',
      without_answer: 'Without answer',
      rejected: 'Rejected',
      rejectedTwo: 'Rejected',
      decline: 'Decline',
      accept_now: 'Accept now',
      review_offer: 'Review offer',
      financial_services: 'Financial services',
      description: 'Description',
      workers: 'Workers',
      highlights: 'Highlights',
      in_progress: 'In progress',
      new: 'New',
      earring: 'Earring',
      create_first_offer: 'Create first offer',
      you_have_not_created_offers_yet: 'You have not created offers yet',
      post_your_first_job_offer_and_let_our_ai_powered_technology_find_the_perfect_professional_for_you:
        'Post your first job offer and let our AI-powered technology find the perfect professional for you.',
      see_candidates: 'See candidates',
      save_as_draft: 'Save as draft',
      publish_offer: 'Publish offer',
      we_are_creating_your_offer: 'We are creating your offer',
      we_connect_the_talent_that_your_company_needs_to_measure:
        'We connect the talent that your company needs to measure',
      your_offer_was_created_successfully:
        'Your offer was created successfully',
      go_to_favorites: 'Go to favorites',
      back_to_offers: 'Back to offers',
      create_new_offer: 'Create new offer',
      type_of_salary_offered: 'Type of salary offered',
      offer_name: 'Offer name',
      assign_a_name_to_the_offer: 'Assign a name to the offer',
      position_offered: 'Position offered',
      salary_offered: 'Salary offered',
      candidates: 'Candidates',
      publication_date: 'Publication date',
      state: 'State',
      actions: 'Actions',
      duplicate_offer: 'Duplicate offer',
      bid_options: 'Bid options',
      deactivate_offer: 'Deactivate offer',
      legal_warning: 'Legal warning',
      all_rights_reserved: 'All rights reserved',
      log_in: 'Log in',
      sign_up: 'Sign up',
      contact_us: 'Contact us',
      follow_us_on: 'Follow us on:',
      privacy: 'Privacy',
      terms_and_conditions: 'Terms and conditions',
      category: 'Category',
      look_for: 'Look for',
      sent: 'Sent',
      profiles_matched: 'Profiles matched',
      review_profile: 'Review profile',
      send_offer: 'Send offer',
      you_are_about_to_send_the_following_offer:
        'You are about to send the following offer',
      selected_candidates: 'Selected candidates',
      cancel: 'Cancel',
      send_offers: 'Send offers',
      save_as_favorite: 'Save as favorite',
      search_offer: 'Search offer',
      double_the_offer: 'Double the offer',
      offer_data: 'Offer data',
      search_filters: 'Search filters',
      categories: 'Categories',
      search_categories: 'Search categories',
      languages: 'Languages',
      apply_filters: 'Apply filters',
      candidates_associated_with_the_offer:
        'Candidates associated with the offer',
      keep_all: 'Keep all',
      you_are_about_to_deactivate_the_offer:
        'You are about to deactivate the offer',
      disabled: 'Disabled',
      keep: 'Keep',
      rule_out: 'Rule out',
      do_not_you_find_what_you_are_looking_for:
        'Dont you find what you are looking for?',
      let_our_team_find_your_perfect_candidate:
        'Let our team find your perfect candidate.',
      create_your_offer: 'Create your offer',
      allow_access: 'Allow access',
      receive_access: 'Receive access',
      find_your_talent: 'Find your talent',
      see_all_talents: 'See all talents',
      sign_up_two: 'Sign up',
      become_a_pro_for_free: 'Become a Pro for free',
      go_pro_for_free_now: 'Go Pro for free now!',
      offers_created: 'Offers created',
      unvalidated_offers: 'Unvalidated offers',
      offers_validated: 'Offers validated',
      annual_gross: 'Annual gross',
      schedule_appointment: 'Schedule Appointment',
      our_team_is_here_to_help_you: 'Our team is here to help you. ',
      offer_detail: 'Offer detail',
      administrator: 'Administrator',
      welcome: 'Welcome',
      process: 'Process',
      take_your_selection_process_seven_times_faster:
        'Take your selection process seven times faster',
      learn_how_to_use_hutrit_to_hire_quickly_in_just_a_few_steps:
        'Learn how to use Hutrit to hire quickly in just a few steps',
      my_companies: 'My companies',
      total_offers: 'Total offers',
      hired: 'Hired',
      status_update: 'Status update',
      messenger_service: 'Messenger service',
      see_tutorial: 'See tutorial',
      chatgpt_assistant: 'ChatGPT Assistant',
      try_now: 'Try now',
      learn_english: 'Learn English',
      strengthen_your_command_of_the_language_with_lessons_adapted_to_your_level_and_pace:
        'Strengthen your command of the language with lessons adapted to your level and pace.',
      interviewer: 'Interviewer',
      practice_interviews_based_on_your_profile_and_gain_confidence_for_the_real_day:
        'Practice interviews based on your profile and gain confidence for the real day.',
      softskills: 'Softskills',
      discover_your_strengths_and_areas_of_improvement_in_soft_skills:
        'Discover your strengths and areas of improvement in soft skills.',
      assign_all: 'Assign all',
      talents_without_a_recruiter: 'Talents without a recruiter',
      assign_talent: 'Assign talent',
      select_talent: 'Select Talent',
      correctly_assigned_talents: 'Talents assigned correctly',
      an_error_has_occurred: 'An error has occurred',
      setting: 'Settings',
      change_nickname: 'Change nickname',
      email: 'Email',
      change_password: 'Change password',
      password: 'Password',
      continue: 'Continue',
      current_password: 'Current password',
      enter_your_current_password: 'Enter your current password',
      enter_your_password: 'Enter your password',
      new_password: 'New password',
      repeat_password: 'Repeat password',
      enter_your_new_password: 'Enter your new password',
      repeat_your_new_password: 'Repeat your new password',
      desactivate_account: 'Deactivate account',
      by_deactivating_your_account_your_profile_will_be_hidden_you_will_not_receive_any_more_offers_and_you_will_lose_contact_with_the_companies_and_your_manager:
        'By deactivating your account, your profile will be hidden, you will not receive any more offers, and you will lose contact with the companies and your manager.',
      you_are_about_to_deactivate_your_account:
        'You are about to deactivate your account',
      enter_your_password_to_continue: 'Enter your password to continue',
      your_account_has_been_deactivated: 'Your account has been deactivated',
      you_can_activate_your_account_again_whenever_you_want:
        'You can activate your account again whenever you want.',
      leaving_hutrit: 'Leaving Hutrit',
      reactivate_account: 'Reactivate account',
      reactivate_your_account_to_make_your_profile_public_receive_new_offers_and_have_contact_with_your_manager_again:
        'Reactivate your account to make your profile public, receive new offers, and have contact with your manager again.',
      reactivating_your_account: 'Reactivating your account',
      your_account_was_successfully_reactivated:
        'Your account was successfully reactivated',
      enter_new_nickname: 'Enter new nickname',
      nickname_updated_successfully: 'Nickname updated successfully',
      nou_cancel: 'No, cancel',
      yes_desactivate_account: 'Yes, deactivate account',
      talk_to_the_ceo: 'Talk to the CEO',
      list_of_candidates: 'List of candidates',
      last_connection: 'Last connection',
      download_pdf: 'Download pdf',
      loading_pdf: 'Loading PDF',
      support: 'Support',
      support_tickets: 'Support tickets',
      talent: 'Talent',
      prospects: 'Prospects',
      mark_as_resolved: 'Mark as resolved',
      write_an_answer: 'Write an answer',
      recruiter_visits_to_your_profile: 'Recruiter visits to your profile',
      number_of_recruiters_who_favorited_your_profile:
        'Number of recruiters who favorited your profile',
      number_of_companies_that_have_made_you_an_offer:
        'Number of companies that have made you an offer',
      talents_with_recruiter: 'Talents with recruiter',
      menAll: 'All',
      apply_second_validation: 'Apply second validation',
      this_chat_was_marked_as_resolved: 'This chat was marked as resolved',
      very_badly: 'Very badly',
      bad: 'Bad',
      regular: 'Regular',
      good: 'Good',
      excellent: 'Excellent',
      rate_the_conversation: 'Rate the conversation',
      create_for: 'Created by:',
      user_rating: 'User rating',
      talk_to_your_talent_manager: 'Talk to your Talent Manager',
      without_resolving: 'Without resolving',
      resolved: 'Resolved',
      complete_information_manually: 'Complete information manually',
      upload_your_linkedin_pdf: 'Upload your LinkedIn PDF',
      get_candidates: 'Get candidates',
      name: 'Name',
      why_hutrit: 'Why Hutrit',
      request_for_offer: 'Request for offer',
      prospect: 'Prospect',
      select_the_category: 'Select the category',
      select_years_of_experience: 'Select years of experience',
      enter_your_name: 'Enter your name',
      enter_your_email: 'Enter your email',
      metrics: 'Metrics',
      new_talents: 'New talents',
      search_by_name: 'Search by name',
      search: 'Search',
      clean_filter: 'Clean filter',
      talent_manager_description: 'Talent manager description',
      validate: 'Validate',
      change_idiom: 'Change language',
      spanish: 'Spanish',
      work_wherever_you_want_and_achieve_your_goals:
        'Work wherever you want and achieve your goals',
      you_have_nothing_to_lose: 'You have nothing to lose',
      and: 'and',
      a_lot_to_gain: 'a lot to gain',
      test_the_technology: 'Test the technology',
      that_connects_you_with_talent: 'that connects you with talent.',
      register_for_free: 'Register for free',
      nickname: 'Nickname',
      last_name: 'Last name',
      numbers_and_special_characters_are_not_allowed:
        'Numbers and special characters are not allowed',
      enter_valid_email: 'Enter valid email',
      you_must_enter_a_corporate_email: 'You must enter a corporate email',
      enter_minimum_characters: 'Enter minimum 8 characters',
      passwords_must_match: 'Passwords must match',
      minimum_characters: 'minimum 8 characters',
      your_email: 'youremail@gmail.com',
      register_now: 'Register now',
      the_registration_link_has_been_sent_successfully:
        'The registration link has been sent successfully',
      check_your_email_to_access_your_new_account:
        'Check your email to access your new account',
      go_to_your_email: 'Go to your email',
      record: 'Record',
      creat_your_account: 'create your account',
      sign_up_free: 'Regístrate gratis',
      register_with_email: 'Register with Email',
      do_you_already_have_an_account: 'Do you already have an account?',
      or_register_with: 'Or register with',
      or: 'Or',
      sign_up_with_google: 'Sign up with Google',
      sign_up_with_linkedIn: 'Sign up with LinkedIn',
      by_registering_you_are_agreeing_to_our:
        'By registering, you are agreeing to our',
      confirm_password: 'Confirm Password',
      start: 'start',
      we_have_already_processed_your_profile:
        'We have already processed your profile!',
      now_complete_and_correct_the_corresponding_information:
        'Now, complete and correct the corresponding information',
      processing_your_profile: 'Processing your profile',
      view_more: 'view more',
      save_to_pdf: 'Save to PDF',
      to_download_it_go_to_your_linkedIn_profile_click_view_more_and_to_finish_click_on_save_to_pdf:
        'To download it, go to your LinkedIn profile, click {{dynamicValue}} and to finish click on {{dynamicValueTwo}}',
      the_file_type_is_not_supported: 'The file type is not supported',
      linkedIn_pdf: 'LinkedIn PDF',
      or_drag_the_pdf_from_linkedin: 'or drag LinkedIn PDF',
      file_uploaded_successfully: 'File uploaded successfully!',
      you_are_just_a_few_steps_away_from_completing_your_profile_and_attracting_the_best:
        'You are just a few steps away from completing your profile and attracting the best',
      opportunities: 'opportunities',
      upload_information: 'Upload information',
      complete_information: 'Complete information',
      manually: 'manually',
      in_spain: 'In. Spain',
      in_catalonia: 'In. Catalonia',
      in_barcelona: 'In. Barcelona',
      maximum_characters_allowed: 'Maximum {{value}} characters allowed',
      you_must_enter_a_valid_url_for_linkedIn_ex:
        'You must enter a valid URL for LinkedIn. Ex',
      enter_the_link: 'Enter the link',
      you_have_repeated_skills: 'You have repeated skills',
      minimum_skills: 'Minimum {{value}} skills',
      add_more: 'Add more',
      step_one_location: 'Step 1: Location',
      step_two_additional_data: 'Step 2: Additional data',
      step_three_profession: 'Step 3: Profession',
      step_four_skills: 'Step 4: Skills',
      step_five_education: 'Step 5: Education',
      step_six_work_experience: 'Step 6: Work Experience',
      here_you_will_see_your_profession: 'Here you will see your profession',
      profile_visits: 'Profile Visits',
      in_favorites: 'In Favorites',
      job_offers_received: 'Job Offers Received',
      upload_linkedIn_pdf: 'Upload LinkedIn PDF',
      configuration: 'Configuration',
      log_out: 'Log Out',
      we_are_under_maintenance_but_we_want_to_help_you:
        'We are under maintenance, but we want to help you',
      by_completing_the_following_form_our_team_will_take_care_of_find_the_talent_you_need_at_no_cost:
        'By completing the following form, our team will take care of find the talent you need at no cost.',
      complete_form: 'Complete form',
      our_ai_tool_has_matched: 'Our AI tool has matched',
      profiles_you_can_find_them_in_the_favorites_section:
        'profiles, you can find them in the Favorites section',
      create_another_offer: 'Create another offer',
      we_are_working_on_it: 'This functionality is under development.',
      your_notifications: 'Your notifications',
      mark_as_read: 'Mark as read',
      newTwo: 'New',
      years: 'Years',
      months: 'Months',
      days: 'Days',
      today: 'Today',
      previous: 'Previous',
      there_are_no_more_notifications: 'There are no more notifications',
      no_notifications: 'Sin notificaciones',
      here_we_will_notify_you_when_companies_contact_you_and_information_relevant_to_you:
        'Here we will notify you when companies contact you and information relevant to you',
      review_all: 'Review all',
      filters: 'Filters',
      search_by_category: 'Search by category',
      search_by_skills: 'Search by skills',
      clean_filters: 'Clean filters',
      former: 'Former',
      notifications: 'Notifications',
      from_experience: 'from experience',
      when_you_submit_the_offer_you_will_not_be_able_to_edit_it_to_modify_the_offer_go_to:
        'When you submit the offer, you will not be able to edit it. To modify the offer go to',
      offer_sent: 'Offer sent',
      inn_this_space_you_can_communicate: 'In this space you can communicate',
      with: 'with',
      he: 'he',
      recruiter: 'recruiter',
      the_company: 'the company',
      this_space_was_created_so_that_a_manager_or_a_company_can_establish_a_conversation_with_you:
        'This space was created so that a manager or a company can establish a conversation with you',
      charging: 'Charging...',
      you: 'You',
      hutrit_manager: 'Hutrit manager',
      greeting: 'Hello {{value}}! 👋',
      here_you_will_receive_messages_from_your:
        'Here you will receive messages from your',
      manager_and_you_will_establish_contact_with:
        'manager and you will establish contact with',
      companies_you_have_accepted: 'companies you have accepted.',
      ex_software_consulting: 'Ex. Software Consulting',
      ex: 'Ex',
      spain: 'Spain',
      step_company_details: 'Step 1: Company details',
      step_location: 'Step 2: Location',
      step_company_values: 'Step 3: Company Values',
      your_message_was_sent_successfully: 'Your message was sent successfully!',
      our_team_will_contact_you_as_soon_as_possible_if_youre_in_a_big_hurry_make_an_appointment_right_now:
        //eslint-disable-next-line
        `Our team will contact you as soon as possible. If you're in a big hurry, make an appointment right now.`,
      return_to_hutrit: 'Return to Hutrit',
      message: 'Message',
      tell_us_how_we_can_help_you: 'Tell us how we can help you',
      send_message: 'Send Message',
      schedule_an_appointment: 'Schedule an appointment',
      on_our_platform_job_offers_come_to_you_in_this_section_you_can_review_the_offers_you_receive_to_connect_with_the_best_opportunities:
        'On our platform, job offers come to you. In this section you can review the offers you receive to connect with the best opportunities.',
      request_evaluation_of_my_profile: 'Request evaluation of my profile',
      create_your_first_offer: 'Create your first offer',
      post_your_first_offer_and_let_our_ai_powered_technology_find_the_perfect_professional_for_you:
        'Post your first offer and let our AI-powered technology find the perfect professional for you.',
      category_created_successfully: 'Category created successfully',
      successfully_created_city: 'Successfully created city',
      you_must_select_a_country_and_a_state_to_see_the_list_of_cities:
        'You must select a country and a state to see the list of cities.',
      list_of_cities: 'List of cities',
      enter_the_name_of_the_city_to_create:
        'Enter the name of the city to create',
      language_created_successfully: 'Language created successfully',
      welcome_manager: 'Welcome Manager',
      here_you_will_see_the_name_of_the_company:
        'Here you will see the name of the company',
      referral_link: 'Referral link',
      copy_link: 'Copy link',
      attention: 'Attention',
      list_of_companies: 'List of companies',
      what_profiles_are_you_looking_for: 'What profiles are you looking for?',
      assign_a_name_so_you_can_identify_your_offers_faster_this_information_will_not_be_visible_to_the_talent:
        'Assign a name so you can identify your offers faster. This information will not be visible to the talent.',
      choose_the_skills: 'Choose the skills',
      annual_offered_salary: 'Annual offered salary',
      minimum: 'Minimum',
      average_amount: 'Average amount',
      maximum: 'Maximum',
      the_initial_range_must_be_less_than_the_final_range:
        'The initial range must be less than the final range.',
      do_you_prefer_to_include_a_salary_range:
        'Do you prefer to include a salary range?',
      salary_type: 'Salary type',
      choose_the_type_of_salary: 'Choose the type of salary',
      annual: 'Annual',
      in_person: 'In person',
      you_will_be_able_to_search_for_candidates_in_person_very_soon:
        'You will be able to search for candidates in person very soon!',
      say_goodbye_to_manual_talent_search_complete_your_job_offer_and_get_ready_to_meet_your_ideal_candidate:
        'Say goodbye to manual talent search. Complete your job offer and get ready to meet your ideal candidate.',
      tell_us_what_happened: 'Tell us what happened',
      please_tell_us_more_about_what_you_didnt_like_about_the_offer_so_we_can_send_you_those_that_meet_your_expectations:
        //eslint-disable-next-line
        `Please tell us more about what you didn't like about the offer so we can send you those that meet your expectations.`,
      offer_disabled: 'Offer Disabled!',
      we_will_notify_the_talent_that_the_offer_is_not_available_you_can_activate_it_again_and_notify_the_talent_to_continue_with_the_selection_process:
        'We will notify the talent that the offer is not available. You can activate it again and notify the talent to continue with the selection process',
      annuals: 'Annuals',
      more_than_years: 'More than {{value}} years',
      without_years: 'Without years',
      created_in: 'Created in',
      you_cannot_have_more_than_one_offer_active:
        'You cannot send the offer to the same talent more than once.',
      the_some_selected_talents_have_already_been_sent_the_offer:
        'The some selected talents have already been sent the offer',
      do_you_have_a_company_or: 'Do you have a company or',
      work_team: 'work team?',
      receive_more_information_and_resolve_doubts:
        'Receive more information and resolve doubts',
      directly_with_our_ceo_at: 'directly with our CEO at',
      via_whatsApp: 'via WhatsApp',
      if_you_hire_you_will_pay: 'If you hire, you will pay',
      you_hire: 'you hire',
      use_our_tools_publish_all_the_offers_you_want_and_find:
        'Use our tools, post all the offers you want and find',
      the_technological_talent_you_need: 'the technological talent you need.',
      totally_free: 'Totally free.',
      know_benefits: 'Know Benefits',
      because: 'Because',
      in_seconds: 'In {{value}} seconds…',
      you_will_find_the_perfect_technological_talent_for_your_company_a_once_the_job_offer_is_created_our_ai_will_show_you_the_candidates_that_meet_your_needs:
        'You will find the perfect technological talent for your company. Once the Job Offer is created, our AI will show you the candidates that meet your needs.',
      verified_talents: 'Verified talents',
      each_talent_has_been_evaluated_and_certified_to_ensure_their_competence_and_experience_in_their_area_of_expertise_trust_our_platform_to_find_the_right_professionals_for_your_technological_projects:
        'Each talent has been evaluated and certified to ensure their competence and experience in their area of expertise. Trust our platform to find the right professionals for your technological projects.',
      you_will_match: 'You will match…',
      this_way_you_will_be_more_effective_in_the_search_for_talent:
        'Only with people who are interested in your job offer and meet the requested skills, this way you will be more effective in the search for talents.',
      find_the: 'Find the',
      ideal_candidate: 'ideal candidate',
      sign_up_to_use_the_filter_tool_to_find_your_ideal_candidate_faster_or_browse_all_available_talent_without_signing_up:
        'Sign up to use the filter tool to find your ideal candidate faster, or browse all available talent without signing up',
      register_to_access_filter_and_save_your_favorite_candidates:
        'Register to access, filter and save your favorite candidates.',
      seen: 'Seen',
      benefits_of_using_hutrit: 'Benefits of using Hutrit',
      for_businesses: 'For businesses',
      you_can_use_the_platform_for_free_once_registered_they_will_be_able_to_search_for_the_ideal_candidate_and_you_will_only_pay_if_you_hire:
        'The use of the platform is free. Once registered you will be able to search for the ideal candidate. And if you hire, you will pay.',
      find_the_ideal_candidate: 'Find the ideal candidate',
      for_talent: 'For talents',
      thanks_to_hutrit_you_receive_clear_job_offers_without_applying_and_you_will_only_speak_with_those_companies_in_which_you_are_interested:
        'Thanks to Hutrit you will receive clear job offers without applying and you will only talk with those companies in which you are interested.',
      connect_with_company: 'Connect with company',
      and_complete_your_profile_in_just_a_few_steps:
        'And complete your profile in just a few steps.',
      create_an_offer: 'Create an offer',
      the_better_the_description_the_better_results_you_will_get:
        'The better the description, the better results you will get.',
      send_the_job_offer: 'Send the job offer',
      browse_to_see_all_profiles_and_find_the_ideal_candidate:
        'Browse to see all profiles and find the ideal candidate.',
      start_the_selection_process: 'Start the selection process',
      only_interview_those_you_want_to_work_with_you:
        'Only interview those you want to work with you.',
      only_people_who_are_interested_in_your_job_offer_and_meet_the_requested_skills_this_way_you_will_be_more_effective_in_the_search_for_talent:
        'Only with people who are interested in your job offer and meet the requested skills, this way you will be more effective in the search for talent.',
      why: 'Why',
      for_companies: 'For Companies',
      complete_your_professional_profile: 'Complete your professional profile',
      highlight_your_unique_skills_all_the_work_experiences_you_have_and_the_accomplishments_that_make_you_proud:
        'Highlight your unique skills, all the work experiences you have and the accomplishments that make you proud.',
      receive_feedback_of_your_profile: 'Receive feedback of your profile',
      our_team_of_experts_will_provide_you_with_advice_and_suggestions_to_optimize_your_profile_and_increase_your_visibility:
        'Our team of experts will provide you with advice and suggestions to optimize your profile and increase your visibility.',
      start_receiving_job_offers: 'Start receiving Job Offers',
      receive_personalized_job_offers_according_to_your_profile_check_select_and_accept_the_best_job_offers_for_you:
        'Receive personalized Job Offers according to your profile. Check, select and accept the best Job Offers for you.',
      a_disruptive_method: 'A disruptive method',
      use_our_tools_for_free: 'Use our tools for free',
      welcome_back: 'Welcome back!',
      enter_hutrit: 'Enter Hutrit',
      your_nickname_or_your_email: '@yourNickname or @yourEmail',
      did_you_forget_your_password: 'Did you forget your password?',
      enter_now: 'Enter now',
      or_enter_with: 'Or enter with',
      sign_in_with_google: 'Sign in with Google',
      sign_in_with_linkedin: 'Sign in with LinkedIn',
      you_do_not_have_an_account: 'You do not have an account?',
      you_found_the_talent_you_were_looking_for_follow_these_steps_to_contact_him_and_have_the_best_candidate:
        'You found the talent you were looking for. Follow these steps to contact him and have the best candidate',
      send_the_offer: 'Send the offer',
      selection_begins: 'Selection begins',
      steps_to_contact_talent: 'Steps to contact talent',
      until: 'until',
      work_here_currently: 'Work here currently',
      activities: 'Activities',
      you_have_attempt_to_change_your_username:
        'You have {{value}} attempt to change your username',
      updated_password: 'Updated password',
      by_deactivating_your_account_your_profile_will_be_hidden_you_will_not_receive_any_more_offers_and_you_will_lose_contact_with_the_companies_and_your_manager_do_you_want_to_continue:
        'By deactivating your account, your profile will be hidden, you will not receive any more offers and you will lose contact with the companies and your manager. Do you want to continue?',
      monthly: 'Monthly',
      weekly: 'Weekly',
      general: 'General',
      registered_talents: 'Registered talents',
      validated_talents: 'Validated talents',
      current_month: 'Current month',
      previous_month: 'Previous month',
      active_users: 'Active users',
      your_description_is_the_voice_of_your_professional_story_take_advantage_of_this_space_to_share_your_vision_passions_and_what_moves_you_in_your_career:
        'Your description is the voice of your professional story. Take advantage of this space to share your vision, passions and what moves you in your career.',
      over_you: 'Over you',
      write_a_description_of_your_profile:
        'Write a description of your profile',
      soft_skills: 'Soft Skills',
      select_your_level: 'Select your level',
      select_a_skill: 'Select a skill',
      // eslint-disable-next-line
      youre_about_to_leave: `You're about to leave`,
      are_you_sure_you_want_to_log_out: 'Are you sure you want to log out?',
      completed_profiles: 'Completed profiles',
      talent_funnel: 'Talent funnel',
      offers_with_talents: 'Offers with talents',
      review_talent: 'Review talent',
      acceptedTwo: 'accepted',
      no_external_links_allowed_in_this_field:
        'No external links allowed in this field',
      registered_talent_managers: 'Registered Talent Managers',
      active_talent_managers: 'Active Talent Managers',
      abandoned_talent_managers: 'Abandoned Talent Managers',
      the_nickname_cannot_contain_an_email_or_url:
        'The nickname cannot contain an email or URL.',
      required_level_of_english: 'Required level of English',
      years_of_experience_required: 'Years of experience required',
      edit_offer: 'Edit offer',
      web_page: 'Web page',
      now_you_can_complete_your_profile_with_linkedIn:
        'Now you can complete your profile with LinkedIn!',
      boost_your_profile_in_one_click_by_uploading_the_pdf_of_your_profile_on_linkedI:
        'Boost your profile in one click by uploading the pdf of your profile on LinkedIn.',
      skip: 'Skip',
      complete_profile_with_linkedIn: 'Complete profile with LinkedIn',
      incomplete: 'Incomplete',
      validated: 'Validated',
      activated: 'Activated',
      validation: 'Validation',
      talent_activation: 'Talent activation',
      activate_talent: 'Activate talent',
      report_problem: 'Report problem',
      change_the_tm: 'Change the TM',
      about_the_profile: 'About the profile',
      see_details: 'See details',
      deactivate: 'Deactivate',
      remove: 'Remove',
      activate: 'Activate',
      profiles: 'Profiles',
      registered_profile: 'Registered profile',
      validated_profile: 'Validated profile',
      successfully_eliminated_talent: 'Successfully eliminated talent',
      talent_successfully_deactivated: 'Talent successfully deactivated',
      successfully_activated_talent: 'Successfully activated talent',
      problem_reported_successfully: 'Problem reported successfully',
      profile_created: 'Profile created',
      copy_invitation_link: 'Copy invitation link',
      your_invitation_link_was_successfully_copied:
        'Your invitation link was successfully copied',
      your_talents: 'your talents',
      registered: 'Registered',
      number_of_talents_who_completed_the_registration_process_with_your_invitation_link:
        'Number of talents who completed the registration process with your invitation link',
      number_of_talents_who_have_not_completed_their_profile_in_hutrit:
        'Number of talents who have not completed their profile in Hutrit',
      number_of_talents_that_passed_the_verification_process_and_have_their_profile_open_to_offers:
        'Number of talents that passed the verification process and have their profile open to offers',
      keep_your_data_updated_to_maintain_better_communication_with_your_talents:
        'Keep your data updated to maintain better communication with your talents.',
      my_talents: 'My talents',
      offers_received: 'Offers received',
      latest_talents: 'Latest talents',
      not_validated: 'Not validated',
      frequent_questions: 'Frequent questions',
      see_all_your_talents: 'Ver todos tus talentos',
      without_validating: 'Without validating',
      email_successfully_copied: 'Email successfully copied',
      phone_number_successfully_copied: 'Phone number successfully copied',
      feedback_sent_successfully: 'Feedback sent successfully',
      all_offers: 'All offers',
      check_registered_talents_by_first_and_last_name:
        'Check registered talents by first and last name',
      consult: 'Consult',
      tm_active_offers: 'Active offers',
      successfully_validated_talent: 'Successfully validated talent',
      sort_by: 'Sort by',
      date: 'Date',
      search_category: 'Search category',
      search_skills: 'Search skills',
      search_experience: 'Search experience',
      talent_consultation: 'Consulta de talento',
      not_registered_in_hutrit: 'not registered in Hutrit',
      talent_registered_in_hutrit: 'Talent registered in Hutrit',
      question_one:
        'From which countries will companies be sending job offers?',
      answer_one: 'From Spain, the United States and LATAM.',
      question_two: 'Can Talents from all countries register?',
      answer_two:
        'Yes, registration in Hutrit is for everyone. The page can be changed to English so that the Talent can register without problems. And they can also upload all their information in English.',
      question_three: 'Can the Talent Manager upload the Talent CV to Hutrit?',
      answer_three:
        'No. Only the Talent is the one who has to upload their information/CV on the platform.',
      question_four:
        'Should Talents upload information about their education to Hutrit?',
      answer_four:
        'No, on our platform we focus on your CV only showing the technical skills you know and your work experience.',
      question_five:
        'Can Talent upload any PDF to Hutrit to speed up their registration?',
      answer_five:
        'No, in Hutrit you can only upload the PDF that is downloaded from LinkedIn. They should consider that if the Talent uploads the empty LinkedIn PDF because their account does not have any description of their work experience, it will also look empty on our platform.',
      question_six:
        'If a Talent indicates that they do not see a technical skill, can they be added to the platform?',
      answer_six:
        'Yes, they must ask you for the name of said skill and we can add it to the platform so that the Talent completes its registration successfully.',
      question_seven:
        'Will you receive an email notifying you if a Company sees your Hutrit profile?',
      answer_seven:
        'Yes, an email will be sent daily to the Talent with all the notifications received in their account. Likewise, on the platform you will be able to see all the notifications that arise regarding offers received, saved in favorites, etc. And you as your Talent Managers will receive the same notifications that your Talents receive.',
      question_eight:
        'How does this mentorship of being a Talent Manager work?',
      answer_eight:
        'The Talent Managers will evaluate the Talent profile and tell you if there is anything you can improve, perhaps in the writing of your work experience, adding more technical skills that you know, etc. In addition, they will be accompanying them throughout their professional career.',
      question_nine:
        'How does the process work with your platform? Or if they indicate that it is similar to Upwork.',
      answer_nine:
        'This platform works in reverse of what we are used to, which is to apply, apply and apply and many times without having answers to those applications. Hutrit serves to unite Technological Talents with Companies in a disruptive way, that is, the Talent will receive the job offers that fit their profile directly to their Hutrit account and will have the decision to accept or reject. Once the talent accepts the offer, they will go to the interview with the Company directly, who will tell them all the details of the vacancy to be filled and what is necessary to start working. We, Hutrit, serve as a bridge between Talents and Companies. That is, with us at Hutrit you will not have to perform tasks or anything similar to Upwork. They will only receive real and clear job offers.',
      question_ten: 'Do I have to pay a fee to use your services?',
      answer_ten:
        'No, Hutrit is completely free for Talents, Talent Managers and Companies. Companies only pay when a Talent is hired.',
      question_eleven: 'Once I get a job, do I have to pay a fee?',
      answer_eleven:
        'No, as a Talent you should not pay anything. Nor as a Talent Manager.',
      question_twelve: 'If people ask how real this page is:',
      answer_twelve:
        'Explain that it is a platform that is starting and that we already have Companies that are reviewing the profiles, and that if there is a vacancy that suits their CV, the Talent will be receiving the offers directly in their Hutrit account.',
      question_thirteen: 'What are the payment methods for Talents?',
      answer_thirteen:
        'They depend on each company. Hutrit has nothing to do with this part, our platform is only responsible for making the link between the Talent and the Company. Unless the company hires Hutrit to handle the entire Talent selection and hiring process, Hutrit will be responsible for paying you either by PayPal, transfers in dollars or, if you are in Venezuela, transfers in Bolívares.',
      question_fourteen: 'Is contact with Companies only through Hutrit?',
      answer_fourteen: `Yes, it will only be within the platform. The Talent's personal data will always be anonymous, and once they accept the job offers and the first contact is made with the Company, they can be viewed. And from there the Company can contact you to coordinate the interview.`,
      question_fifteen: 'Do internships count as work experience?',
      answer_fifteen: 'No.',
      question_sixteen: 'What will the interview process be like?',
      answer_sixteen:
        'This process will depend on each Company. And it must inform the Talent at all times. Likewise, at Hutrit we will always be informing how the process of each Talent is going, so you as Talent Managers will be very informed of each interview carried out until the hiring.',
      question_seventeen: 'Can a Talent deactivate their Hutrit account?',
      answer_seventeen:
        'Yes, all talents have it in their account settings to deactivate it whenever they want. A notification will be sent to your Talent Manager.',
      question_eighteen:
        'Should the Talents that must be registered only be from the IT area?',
      answer_eighteen:
        'Yes, Hutrit stands for Human Talent Recruitment IT. Therefore, our platform is based solely and exclusively on Talents from the Technology area.',
      active_processes: 'Active processes',
      search_by_nickname_or_email: 'Search by nickname or email',
      affer_received: 'Offer received',
      see_the_process: 'See the process',
      selection_process: 'Selection process',
      validate_talent: 'Validate Talent',
      send_feedback: 'Send feedback',
      open_chat: 'Open chat',
      vacancies: 'Vacancies',
      creation: 'Creation',
      email_copied_successfully: 'Email copied successfully',
      successfully_reassigned_talent: 'Successfully reassigned talent',
      username: 'Username',
      reject_talent: 'Reject talent',
      birthdate: 'Birthdate',
      your_date_of_birth_is_missing: 'Your date of birth is missing',
      enrich_your_profile_on_hutrit: 'Enrich your profile on Hutrit',
      add_your_date_of_birth_to_receive_exclusive_offers_and_benefits_for_you:
        'Add your date of birth to receive exclusive offers and benefits for you.',
      add_date_of_birth: 'Add date of birth',
      you_are_about_to_send_feedback_to_the_talent:
        'You are about to send feedback to the talent',
      by_sending_feedback_the_talent_will_receive_an_email_indicating_that_they_must_improve_their_professional_profile_within_hutrit_are_you_sure_you_want_to_continue:
        'By sending feedback, the talent will receive an email indicating that they must improve their professional profile within Hutrit. Are you sure you want to continue?',
      yes_send_feedback: 'Yes, send feedback',
      no_cancel: 'No cancel',
      number_of_connections: 'Number of connections',
      hybrid: 'Hybrid',
      now_you_can_create_offers_with_hybrid_mode:
        'Now you can create offers with hybrid mode!',
      modality: 'Modality',
      your_profile_has_opportunities_for_improvement:
        'Your profile has opportunities for improvement',
      your_talent_manager_has_some_suggestions_to_boost_your_profile_remember_that_it_is_the_mirror_of_your_professionalism_and_your_letter_of_introduction_to_the_world:
        'Your Talent Manager has some suggestions to boost your profile. Remember that it is the mirror of your professionalism and your letter of introduction to the world.',
      improve_my_profile_now: 'Improve my profile now',
      your_talent_manager_has_sent_you_to_improve_your_profile:
        'Your Talent Manager has sent you to improve your profile.',
      dont_know_how_to_improve_it: `Don't know how to improve it?`,
      look: 'Look',
      a: 'A',
      junior_profile_example: 'Junior profile example',
      example_of_a_senior_profile: 'Example of a Senior profile',
      highlight_your_profile_as_a_senior: 'Highlight your profile as a Senior',
      make_the_most_of_your_experience_with_a_profile_that_authentically_represents_you_see_how_we_do_it_in_our_featured_example:
        'Make the most of your experience with a profile that authentically represents you. See how we do it in our featured example.',
      highlight_your_profile_as_a_junior: 'Highlight your profile as a Junior',
      find_talent_in_seconds: 'Find talent in seconds',
      you_have_already_sent_feedback_to_this_talent:
        'You have already sent feedback to this talent',
      are_you_talented: 'Are you an IT Talent?',
      my_enterprise: 'My enterprise',
      you_will_only_pay_if: 'You will only pay if',
      superfinder: 'Superfinder',
      check_our_updates: 'Check our updates',
      quickly_find_the_ideal_talent_with_our_superfinder_engine_and_its_advanced_filters:
        'Quickly find the ideal talent with our superfinder engine and its advanced filters.',
      skip_and_continue: 'Skip and Continue',
      go_to_superfinder: 'Go to Superfinder',
      try_our: 'Try our',
      totally_free_and_without_signing_up:
        'Totally free and without signing up',
      technical_skills: 'Technical Skills',
      see_talents: 'See Talents',
      professionals_in: 'professionals in',
      remote_from: 'remote from',
      verified: 'verified',
      hutrit_is_for_who: 'Hutrit is for who?',
      for_it_consultants: 'For IT consultants',
      for_startups_and_pymes: 'For StartUps and PYMES',
      try: 'Try',
      our_blog: 'Our Blog',
      find_tips_and_strategies_for_talents_recruiters_and_companies_in_the:
        'Find tips and strategies for talents, recruiters and companies in the',
      technology_sector: 'technology sector.',
      use_our_superfinder_engine_free_of_charge:
        'Use our superfinder engine free of charge',
      company_activation: 'Company activation',
      activate_company: 'Activate company',
      you_are_about_to_activate_this_company:
        'You are about to activate this company',
      by_activating_this_company_you_will_have_access_to_the_platform_are_you_sure_you_want_to_continue:
        'By activating this company, you will have access to the platform. Are you sure you want to continue?',
      yes_activate_company: 'Yes, activate company',
      add_notes: 'Add notes',
      disable: 'Disable',
      delete: 'Delete',
      change_the_talent_manager: 'Change the Talent Manager',
      current_talent_manager: 'Current Talent Manager',
      new_talent_manager: 'New Talent Manager',
      find_the_talent_manager: 'Find the Talent Manager',
      note: 'Note',
      the_company_will_be_visible_to_the_talent_manager_you_select:
        'The company will be visible to the Talent Manager you select',
      save_changes: 'Save Changes',
      no_talent_manager_found: 'No Talent Manager found',
      copy_invitation: 'Copy invitation',
      invitation_in_spanish: 'Invitation in Spanish',
      invitation_in_english: 'Invitation in English',
      company_notes: 'Company Notes',
      write_relevant_information_about_the_company:
        'Write relevant information about the company',
      write_your_comments: 'Write your comments',
      verified_email: 'Verified Email!',
      welcome_to_hutrit: 'Welcome to hutrit',
      checking_email: 'Checking email...',
      there_was_an_error_while_validating_your_email:
        'There was an error while validating your email',
      low_skill_level: 'Low skill level',
      could_you_do_it_better: 'Could you do it better',
      brilliant_high_level_of_skills: 'Brilliant! 🌟 High level of skills',
      includes_at_least_skills_we_recommend_you_include_between_to_skills_to_strengthen_your_profile:
        'Includes at least {{minimumSkills}} skills. We recommend you include between {{numberOfSkillsOne}} to {{numberOfSkillsTwo}} skills to strengthen your profile',
      low_experience_level: 'Low experience level',
      brilliant_high_level_of_experience:
        'Brilliant! 🌟 High level of experience',
      our_talents_that_include_between_and_skills_receive_more_offers:
        'Our talents that include between 10 and 15 skills receive 80% more offers',
      excellent_your_profile_now_has_the_skills_necessary_to_stand_out:
        'Excellent! Your profile now has the skills necessary to stand out.',
      best_describe_the_role_you_played_in_this_company_you_can_see_a:
        'Best describe the role you played in this company. You can see a',
      you_can_see_a_successful_example_here: 'successful example here.',
      use_styles_in_your_text_to_better_highlight_your_experience_highlight_important_points_and_personalize_your_writing:
        'Use styles in your text to better highlight your experience. Highlight important points and personalize your writing.',
      brilliant_a_complete_description_of_your_experience_will_bring_you_closer_to_the_position_you_deserve:
        'Brilliant! A complete description of your experience will bring you closer to the position you deserve',
      low_description_level: 'Low description level',
      brilliant_great_description: 'Brilliant! 🌟 Great description',
      your_description_is_very_brief_add_more_details_to_attract_better_offers:
        'Your description is very brief. Add more details to attract better offers.',
      your_description_is_good_but_it_could_be_better_add_more_details_to_stand_out_more:
        'Your description is good, but it could be better. Add more details to stand out more.',
      excellent_your_description_is_very_complete_this_will_increase_your_chances_of_receiving_high_quality_job_offers:
        'Excellent! Your description is very complete. This will increase your chances of receiving high-quality job offers.',
      number_of_offers_that_are_in_process:
        'Number of offers that are in process',
      number_of_offers_shared_with_candidates:
        'Number of offers shared with candidates',
      number_of_offers_that_were_accepted_by_candidates:
        'Number of offers that were accepted by candidates',
      company_successfully_deactivated: 'Company successfully deactivated',
      successfully_deleted_company: 'Successfully deleted company',
      you_are_about_to_deactivate_the_company:
        'You are about to deactivate the company',
      by_deactivating_the_company_you_will_lose_access_to_the_platform_you_can_reactivate_the_company_at_any_time_are_you_sure_you_want_to_continue:
        'By deactivating the company, you will lose access to the platform. You can reactivate the company at any time. Are you sure you want to continue?',
      yes_deactivate_company: 'Yes, deactivate company',
      you_are_about_to_eliminate_the_company:
        'You are about to eliminate the company',
      deleting_the_company_will_delete_your_entire_hutrit_record_this_action_cannot_be_undone_are_you_sure_you_want_to_continue:
        'Deleting the company will delete your entire Hutrit record. This action cannot be undone. Are you sure you want to continue?',
      yes_delete_company: 'Yes, delete company',
      recruiter_change_successfully_completed:
        'Recruiter change successfully completed',
      successfully_created_note: 'Successfully created note',
      signed_contract: 'Signed contract',
      the_it_talent_you_need: 'The IT talent you need',
      one_click: 'one click',
      we_have_the_people_you_are_looking_for_we_send_you_the_talent_that:
        'We have the people you are looking for. We send you the talent that',
      you_need_free_in_less_than_24_hours:
        'you need, free, in less than 24 hours.',
      we_send_you_the_talent_that: 'We send you the talent that',
      you_need_in_hours: 'you need in 24 hours.',
      more_than_it_experts_are: 'More than 20,000 IT experts are',
      waiting_for_you_to_contact_them: 'waiting for you to contact them.',
      our_model_is_based_on_your: 'Our model is based on your',
      success_you_only_pay_if_we_solve: 'success, you only pay if we solve',
      your_hiring_problem: 'your hiring problem.',
      about_us: 'About us?',
      in_a_competitive_world_where_time_and_quality_are_crucial_we_are_ready_to_be_your_strategic_partner_in_the_acquisition_of_technological_talent_forget_about_financial_risks_and_trust_us_to_take_your_team_to_the_next_levelL:
        'In a competitive world where time and quality are crucial, we are ready to be your strategic partner in the acquisition of technological talent. Forget about financial risks and trust us to take your team to the next level',
      schedule_an_appointment_with: 'Schedule an appointment with',
      us: 'Us',
      your_request_was_received_successfully:
        'Your request was received successfully',
      our_team_will_contact_you_via_email_to_send_you_the_candidates_if_you_are_in_a_hurry_make_an_appointment_right_now: `Our team will contact you via email to send you the candidates. If you're in a hurry, make an appointment right now.`,
      close: 'Close',
      in_a_competitive_world_where_time_and_quality_are_crucial_we_are_ready_to_be_your_strategic_partner_in_the_acquisition_of_technological_talent_forget_about_financial_risks_and_trust_us_to_take_your_team_to_the_next_level:
        'In a competitive world where time and quality are crucial, we are ready to be your strategic partner in the acquisition of technological talent. Forget about financial risks and trust us to take your team to the next level.',
      see_all_skills: 'See all skills',
      new_habilities: 'New habilities',
      you_will_soon_be_able_to_view_the_soft_skills_of_this_profile:
        'You will soon be able to view the soft skills of this profile',
      your_profile_is_hidden_from_companies_improve_your_profile_to_start_receiving_offers:
        'Your profile is hidden from companies. Improve your profile to start receiving offers.',
      look_a: 'Look a',
      and_a: 'and a',
      improve_your_skills_to_attract_better_offers:
        'Improve your skills to attract better offers.',
      improve_your_experience_description_to_attract_better_offers:
        'Improve your experience description to attract better offers.',
      improve_description: 'Improve description',
      improve_technical_skills: 'Improve technical skills',
      you_are_viewing_your_profile_as_companies_see_it_highlight_all_your_potential_and_make_it_shine:
        'You are viewing your profile as companies see it. Highlight all your potential and make it shine',
      this_profile_has_areas_for_improvement:
        'This profile has areas for improvement',
      low_profile_level: 'Low profile level',
      medium_profile_level: 'Medium profile level',
      congratulations_your_profile_has_a_high_level:
        'Congratulations, your profile has a high level',
      about_me: 'About me',
      include_between_and_skills_to_improve_your_profile:
        'Include between {{firstQuantity}} and {{secondQuantity}} skills to improve your profile.',
      your_description_is_good_but_it_could_be_better:
        'Your description is good, but it could be better.',
      improve_about_me: 'Improve about me',
      high_level: 'high level',
      medium_level: 'Medium level',
      low_level: 'Low level',
      improve_soft_skills: 'Improve soft skills',
      it_is_prohibited_to_enter_links: 'It is prohibited to enter links.',
      see_all_offers: 'See all offers',
      select_a_languages: 'Select a languages',
      you_have_repeated_languages: 'You have repeated languages',
      minimum_languages: 'Minimum {{value}} languages',
      remove_language: 'Remove language',
      native: 'Native',
      complementary: 'Complementary',
      name_and_surname: 'Name and surname',
      brilliant: 'Brilliant',
      check_the_email_entered_to_have_your_new_password:
        'Check the email entered to have your new password',
      recover_password: 'Recover password',
      send_mail: 'Send mail',
      found_talents: 'Found Talents',
      add_to_favorite: 'Add to favorite',
      request_demo: 'Request demo',
      the_talent_details_will_appear_here:
        'The talent details will appear here.',
      if_you_are_looking_for_a_more_specific_talent_we_invite_you_to_use_the_search_filters_or_request_a_demo:
        'If you are looking for a more specific talent, we invite you to use the search filters or request a demo',
      soon_you_will_be_able_to_see_more: 'Soon you will be able to see more',
      professional_information_about_this:
        'professional information about this',
      profile: 'profile',
      do_you_want_to_make_this_talent_an_offer:
        'Do you want to make this talent an offer?',
      schedule_a_call_to_manage_the_process_with_you:
        'Schedule a call to manage the process with you',
      schedule_call: 'Schedule call',
      select: 'Select',
      language_not_found: 'Language not found',
      update_profile: 'Update profile',
      i_am_a_talent_manager: 'I am a Talent Manager',
      it_talent: 'IT Talent',
      in_24_hours: 'in 24 hours',
      receive_qualified_profiles_in_less_than_hours_find_the_talent:
        'Receive 3 qualified profiles in less than 24 hours. Find the talent',
      what_you_are_looking_for_easy_and_fast:
        'what you are looking for, easy and fast.',
      enter_the_name_of_the_company: 'Enter the name of the company',
      offer_description: 'Offer description',
      your_request_has_already_been_received:
        'Your request has already been received',
      our_team_will_be_in_touch_with_you_shortly_to_send_you_the_profiles_of_candidates_who_fit_your_offer_if_you_need_an_urgent_response_schedule_an_appointment_right_now:
        'Our team will be in touch with you shortly to send you the profiles of candidates who fit your offer. If you need an urgent response, schedule an appointment right now.',
      company_visits_to_your_profile: 'Company visits to your profile',
      number_of_companies_that_marked_your_profile_as_a_favorite:
        'Number of companies that marked your profile as a favorite',
      present: 'Present',
      maximum_characters: 'Maximum {{value}} characters.',
      no_external_links_allowed_in_this_field_or_emails:
        'No external links allowed or emails in this field',
      the_start_date_must_be_less: 'The start date must be less.',
      education: 'Education',
      courses_and_certificates: 'Courses and certificates',
      certificates: 'Certificates',
      courses_and_certificates_updated_correctly:
        'Courses and certificates updated correctly',
      studies_updated_correctly: 'Studies updated correctly',
      academic_training: 'Academic training',
      describe_your_academic_background: 'Describe your academic background',
      obtained_title: 'Obtained title',
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'Diciembre',
      educational_institution: 'Educational institution',
      month: 'Month',
      year: 'Year',
      include_your_most_notable_degrees_and_certifications_this_will_help_companies_recognize_your_academic_and_professional_preparation:
        'Include your most notable degrees and certifications. This will help companies recognize your academic and professional preparation.',
      or_expected_date: 'or expected date',
      certification_date: 'Certification date',
      mandatory: 'Mandatory',
      certifications_and_courses_completed:
        'Certifications and courses completed',
      this_offer_was_abandoned: 'This offer was abandoned',
      decided_not_to_continue_with_this_offer:
        '{{name}} decided not to continue with this offer',
      the_candidate_did_not_advance_in_the_process:
        'The candidate did not advance in the process',
      unfortunately_did_not_advance_in_the_selection_process_for_this_offer:
        'Unfortunately, {{name}} did not advance in the selection process for this offer.',
      sorry_we_couldn_t_find_the_page_you_were_looking_for: `Sorry, we couldn't find the page you were looking for.`,
      please_go_back_to_the_beginning_or_contact_support_if_you_think_this_is_an_error:
        'Please go back to the beginning or contact support if you think this is an error.',
      contact_support: 'Contact support',
      oops_there_seems_to_be_a_connection_problem:
        'Oops, there seems to be a connection problem',
      please_check_your_internet_connection_or_try_again_later_ff_the_problem_persists_please_contact_support:
        'Please check your internet connection or try again later. If the problem persists, please contact support.',
      retry: 'Retry',
      the_email_is_already_registered: 'The email is already registered.',
      list_of_soft_skills: 'List of soft skills',
      create_soft_skills: 'Create soft skills',
      enter_a_soft_skill: 'Enter a soft skill',
      enter_a_soft_skill_in_english: 'Enter a soft skill in english',
      show_soft_skill: 'Show soft skill',
      hide_soft_skill: 'Hide soft skill',
      edit_soft_skill: 'Edit soft skill',
      you_have_not_invited_any_companies_yet:
        'You have not invited any companies yet',
      you_can_check_the_status_and_information_of_the_companies_you_invite_to_hutrit_from_here:
        'You can check the status and information of the companies you invite to Hutrit from here.',
      invite_companies: 'Invite companies',
      observe_this_example_of_a: 'Observe this example of',
      successfull_profile: 'successfull profile',
      in_hutrit_for_more_experienced_talents:
        'in Hutrit for more experienced talents',
      example_of_a_junior_profile: 'Example of a Junior profile',
      begin: 'Begin',
      go_out: 'Salir',
      company_details: 'Company details',
      companies: 'Companies',
      favorites: 'Favorites',
      profile_views: 'Profile views',
      complete_your_profile: 'Complete your profile',
      to_access_this_function: 'to access this function',
      your_nickname_must_not_exceed_characters:
        'Your nickname must not exceed {{value}} characters',
      avoid_using_your_real_name_choose_a_unique_and_professional_nickname_that_best_represents_you:
        'Avoid using your real name. Choose a unique and professional nickname that best represents you.',
      update_your_nickname_to_improve_your_profile:
        'Update your nickname to improve your profile',
      your_nickname_is_the_first_thing_companies_see_to_ensure_better_presentation_it_should_now_be_a_maximum_of_characters_adjust_yours_to_stand_out_more_effectively:
        'Your nickname is the first thing companies see. To ensure better presentation, it should now be a maximum of 25 characters. Adjust yours to stand out more effectively.',
      update_nickname: 'Update nickname',
      our_team_will_be_in_touch_with_you_to_give_you_access_and_introduce_you_to_hutrit_if_you_dont_see_our_email_please_check_your_spam_folder_if_you_are_in_a_hurry_please_schedule_an_appointment_right_now: `Our team will be in touch with you to give you access and introduce you to Hutrit. If you don't see our email, please check your spam folder. If you are in a hurry, please schedule an appointment right now.`,
      your_data_was_received_successfully:
        'Your data was received successfully!',
      this_company_has_not_completed_its_profile:
        'This company has not completed its profile',
      you_will_be_able_to_review_and_activate_this_company_once_you_have_completed_your_profile:
        'You will be able to review and activate this company once you have completed your profile.',
      approximate_date_of_hiring: 'Approximate date of hiring',
      estimated_hiring: 'Estimated hiring',
      this_is_the_approximate_date_when_the_selection_process_is_expected_to_be_completed_and_a_formal_offer_is_made_it_is_not_a_guarantee_or_the_exact_date_of_your_hiring:
        'This is the approximate date when the selection process is expected to be completed and a formal offer is made. It is not a guarantee or the exact date of your hiring.',
      cant_find_tech_talent: `Can't find tech talent?`,
      try_hutrit: 'Try Hutrit',
      we_present_you_qualified_candidates_within_hours:
        'We present you qualified candidates within 48 hours.',
      find_out_how_much_you_can_save_with_hutrit:
        'Find out how much you can save with Hutrit',
      know_how_much_you_can: 'Know how much you can',
      spare: 'spare',
      with_hutrit: 'with Hutrit',
      position_or_role: 'Position or role',
      select_the_role_you_need_to_hire: 'Select the role you need to hire',
      gross_annual_salary: 'Gross annual salary',
      enter_the_gross_annual_salary_for_this_role:
        'Enter the gross annual salary for this role',
      up_to_a: 'Up to a',
      percentage_less: '{{value}}% less',
      in_costs_compared_to_other_platforms:
        'in costs compared to other platforms.',
      get_candidates_in_hours: 'Get candidates in 48 hours',
      get_qualified_candidates_in_less_than_hours:
        'Get qualified candidates in less than 48 hours',
      our_model_is_based_on_your_success_you_only_pay_if_we_solve_your_hiring_problem:
        'our_model_is_based_on_your_success_you_only_pay_if_we_solve_your_hiring_problem',
      we_use_cookies_to_improve_your_experience_on_our_website_and_to_analyse_our_site_traffic:
        'We use cookies to improve your experience on our website and to analyse our site traffic.',
      accept: 'Accept',
      by_clicking: 'By clicking',
      you_consent_to_the_use_of_cookies_by_google_analytics_for_more_information_please_see_our:
        'you consent to the use of cookies by Google Analytics. For more information, please see our',
      cookie_policy: 'Cookie Policy',
      complete_your_registration: 'Complete your registration',
      create_a_username_and_password_to_finish_and_start_using_your_account:
        'Create a username and password to finish and start using your account.',
      email_notifications: 'Email Notifications',
      choose_the_notifications_you_want_to_receive_by_email:
        'Choose the notifications you want to receive by email',
      weekly_summary: 'Weekly summary',
      receive_an_email_with_the_events_of_the_week:
        'Receive an email with the events of the week',
      weekly_summary_notifications_have_been_successfully_disabled:
        'Weekly Summary notifications have been successfully disabled',
      weekly_summary_notifications_have_been_successfully_activated:
        'Weekly Summary notifications have been successfully activated',
      if_you_disable_notifications_for_offers_your_profile_will_be_automatically_deactivated_so_you_will_not_be_able_to_receive_more_offers_and_you_will_lose_contact_with_the_companies_and_your_manager_do_you_want_to_continue:
        'If you disable notifications for offers, your profile will be automatically deactivated, so you will not be able to receive more offers and you will lose contact with the companies and your manager. Do you want to continue?',
      you_are_about_to_disable_your_notifications:
        'You are about to disable your notifications',
      you_are_about_to_activate_your_notifications:
        'You are about to activate your notifications',
      creation_date: 'Creation date',
      this_talent_has_not: 'The talent {{value}} has not',
      completed_their_profile: 'completed their profile',
      in_order_for_talent_to_start_receiving_offers_they_must_complete_their_profile:
        'In order for talent to start receiving offers, they must complete their profile.',
      once_complete_review_and_validate_the_information_to_ensure_it_meets_the_necessary_criteria:
        'Once complete, review and validate the information to ensure it meets the necessary criteria.',
      send_reminder: 'Send reminder',
      profile_not_completed: 'Profile not completed',
      validate_profile: 'Validate profile',
      the_user_is_already_validated: 'The user is already validated',
      you_cant_find_the_talent_youre_looking_for: `You can't find the talent you're looking for`,
      at_hutrit_you_will_have_hundreds_of_talent_managers_in_charge_of_finding_talents_just_for_you:
        'At Hutrit, you will have hundreds of Talent Managers in charge of inding talents just for you.',
      keep_searching: 'Keep searching',
      oops_we_couldnt_find_what_you_were_looking_for: `Oops, we couldn't find what you were looking for.`,
      dont_worry_try_again: `Don't worry, try again.`,
      great_you_found_over_talents_in_your_first_search:
        'Great! You found over {{value}} talents in your first search',
      want_to_maximize_your_results_request_a_free_demo_and_discover_how_hutrit_can_help_you_find_the_perfect_talent_faster:
        'Want to maximize your results? Request a free demo and discover how Hutrit can help you find the perfect talent faster.',
      see_results: 'See results',
      you_found_less_than_talents_in_your_first_search:
        'You found less than {{value}} talents in your first search',
      schedule_a_call_to_help_you_find_the_specific_candidate_you_are_looking_for_with_the_support_of_more_than_hundreds_of_talent_managers_at_your_disposal:
        'Schedule a call to help you find the specific candidate you are looking for with the support of more than hundreds of Talent Managers at your disposal.',
      by_deleting_your_account_you_are_completely_disconnected_from_hutrit:
        'By deleting your account, you are completely disconnected from Hutrit.',
      you_are_about_to_delete_your_account:
        'You are about to delete your account',
      yes_remove_account: 'Yes, delete account',
      remove_account: 'Delete account.',
      account_deleted_successfully: 'Account deleted successfully',
      request_a_demo_to_get_started: 'Request a Demo to Get Started',
      discover_how_our_platform_can_help_you_create_effective_offers_request_your_demo_now_and_start_using_all_our_features:
        'Discover how our platform can help you create effective offers. Request your demo now and start using all our features.',
      active: 'active',
      remove_validation: 'Remove validation',
      validation_successfully_removed: 'Validation successfully removed',
      your_virtual_recruiter: 'your virtual <1/> recruiter',
      revolutionizing_talent_search_and_selection:
        'Revolutionizing Talent Search and Selection',
      learn_more: 'Learn more',
      this_offer_is_activated: 'This offer is activated',
      this_offer_has_been_deactivated: 'This offer has been deactivated',
      position: 'Position',
      published: 'Published',
      estimated_hiring_v2: 'Estimated hiring',
      see_offer: 'See offer',
      see_processes: 'See processes',
      talents_ready_for_your_needs: 'Talents ready for <1/> your needs',
      start_today_and_secure_the_future_of_your_company_with_the_best_talents_in_the_market:
        'Start today and secure the future of your company with the best talents in the market',
    },
  },
  es: {
    translation: {
      home: 'Inicio',
      how_does_it_work: 'Cómo funciona',
      start_now: 'Comenazar ahora',
      personal_profile: 'Perfil personal',
      location: 'Ubicación',
      additional_data: 'Datos adicionales',
      professional_profile: 'Perfil profesional',
      profession: 'Profesión',
      skills: 'Habilidades',
      skill: 'Habilidad',
      work_experience: 'Experiencia laboral',
      staff: 'Personal',
      lets_complete_your_profile: 'Completemos tu perfil',
      you_are_just_a_few_steps_away_from_completing_your_profile_and_attracting_the_best_opportunities:
        'Estás a solo unos pasos de completar tu perfil y atraer las mejores oportunidades.',
      tell_us_where_you_are_from: 'Cuéntanos de dónde eres',
      country: 'País',
      state_or_region: 'Estado o Región',
      city: 'Ciudad',
      next: 'Siguiente',
      these_data_will_not_be_shared_with_companies_your_profile_will_remain_anonymous:
        'Estos datos no serán compartidos con las empresas. Tu perfil se mantendrá anónimo.',
      write_your_contact_information: 'Escribe tus datos de contacto',
      phone_number: 'Número de teléfono',
      identification_document: 'Documento de identidad',
      linkedIn_profile: 'Perfil de LinkedIn',
      tell_us_about_yourself: 'Cuéntanos sobre ti',
      years_of_experience: 'Años de experiencia',
      we_will_use_this_information_to_find_the_ideal_job_for_you:
        'Utilizaremos esta información para conseguir el trabajo ideal para ti',
      highlight_your_skills: 'Destaca tus habilidades',
      focus_on_relevant_skills_highlight_those_related_to_the_position_you_hope_to_get:
        'Enfócate en habilidades relevantes, destaca aquellas relacionadas al puesto que esperas conseguir.',
      tip: 'Tip',
      english_level: 'Nivel de Inglés',
      english: 'Inglés',
      beginner: 'Principiante',
      intermediate: 'Intermedio',
      advanced: 'Avanzado',
      professional: 'Profesional',
      required: 'Requerido',
      level: 'Nivel',
      competence: 'Competencia',
      this_field_is_required: 'Este campo es requerido',
      tell_us_about_your_experience: 'Cuéntanos sobre tu experiencia',
      highlight_your_most_relevant_achievements_See_an_example_of_a:
        'Destaca tus logros más relevantes. Observa un ejemplo de un',
      successful_profile: 'perfil exitoso',
      post: 'Cargo',
      remove_experience: 'Remover experiencia',
      sector: 'Sector',
      company: 'Empresa',
      return: 'Volver',
      finish: 'Finalizar',
      finish_date: 'Fecha de finalización',
      start_date: 'Fecha de inicio',
      choose_the_position: 'Escoge el cargo',
      enter_the_company: 'Ingresa la empresa',
      choose_the_sector: 'Escoge el sector',
      i_work_here_currently: 'Trabajo aquí actualmente',
      activities_carried_out_and_achievements_obtained:
        'Actividades realizadas y logros obtenidos',
      you_have_completed_your_profile: '¡Has completado tu perfil!',
      you_are_one_step_closer_to_starting_your_next_project:
        'Estás un paso más cerca de comenzar en tu próximo proyecto',
      back_to_top: 'Volver al inicio',
      view_your_profile: 'Ver tu perfil',
      see_less: 'Ver menos',
      see_more: 'Ver más',
      show_all_experiences: 'Mostrar todas las Experiencias',
      review_now: 'Revisar ahora',
      remote: 'Remoto',
      requirements: 'Requisitos',
      required_skills: 'Habilidades requeridas',
      desired_profile_and_additional_benefits:
        'Perfil deseado y beneficios adicionales',
      description_of_the_position_and_functions_to_be_performed:
        'Descripción del cargo y funciones a realizar',
      download: 'Descargar',
      preview_what_the_offer_will_look_like:
        'Previsualiza cómo se verá la oferta',
      Share: 'Compartir',
      we_seek: 'Buscamos',
      write_me_or_leave_me_a_comment_for_more_information:
        'Escríbeme o déjame un comentario para más información',
      share_this_offer: 'Comparte esta oferta',
      these_data_will_not_be_shared_with_companies:
        'Estos datos no serán compartidos con las empresas',
      your_profile_will_remain_anonymous: 'Tu perfil se mantendrá anónimo.',
      personal_information_updated_correctly:
        'Información personal actualizada correctamente',
      error_in_the_system: 'Error en el sistema',
      save: 'Guardar',
      visualize_and_highlight_your_professional_profile_as_companies_see_it:
        'Visualiza y destaca tu perfil profesional tal como lo ven las empresas',
      available: 'Disponible',
      not_available: 'No disponible',
      of: 'de',
      experience: 'Experiencia',
      favorite: 'Favorito',
      go_back: 'Regresar',
      sign_up_in_seconds: 'Regístrate en segundos',
      general_skills: 'Habilidades Generales',
      show_all_skills: 'Mostrar todas las Habilidades',
      professional_header: 'Encabezado profesional',
      professional_information_updated_correctly:
        'Información Profesional actualizada correctamente',
      successfully_updated_work_experience:
        'Experiencia laboral actualizada correctamente',
      active_offers: 'Ofertas activas',
      offers_sent: 'Ofertas enviadas',
      offers_accepted: 'Ofertas aceptadas',
      attracts_the_attention_of_companies: 'Atrae la atención de las empresas',
      find_the_talent_you_need: 'Encuentra el talento que necesitas',
      your_profile_is_your_showcase_to_new_opportunities:
        'Tu perfil es tu vitrina hacia nuevas oportunidades.',
      by_completing_it_you_allow_ompanies_to_discover_you:
        'Al completarlo, permites que las empresas te descubran.',
      open_the_doors_to_a_universe_of_qualified_professionals:
        'Abre las puertas a un universo de profesionales cualificados.',
      complete_your_profile_create_your_job_offer_and_let_our_platform_take_care_of_the_rest:
        'Completa tu perfil, crea tu oferta de empleo y deja que nuestra plataforma se encargue del resto.',
      complete_my_profile: 'Completar mi perfil',
      offers: 'Ofertas',
      talents: 'Talentos',
      my_profile: 'Mi perfil',
      company_profile: 'Perfil de empresa',
      user_profile: 'Perfil de usuario',
      statistics: 'Estadísticas',
      your_summary: 'Tu resumen',
      your_progress: 'Tu progreso',
      back: 'Atrás',
      lets_start: 'Comencemos',
      lets_find_the_talent_you_are_looking_for:
        'Encontremos el talento que buscas',
      we_help_you_find_the_talent_you_need_in_a_few_steps:
        'Te ayudamos a buscar el talento que necesitas en pocos pasos',
      about_the_company: 'Sobre la empresa',
      company_data: 'Datos de la empresa',
      contact_information: 'Datos de contacto',
      business_values: 'Valores de la empresa',
      tell_us_about_the_company: 'Cuéntanos sobre la empresa',
      company_name: 'Nombre de la empresa',
      the_business_social_reason: 'Razón social de la empresa',
      tax_registry: 'Registro Fiscal',
      company_sector: 'Sector de la empresa',
      team_size: 'Tamaño del equipo',
      years_of_the_company: 'Años de la empresa',
      we_will_use_this_data_to_make_a_precise_match_with_the_ideal_talent_for_your_company:
        'Estos datos los utilizaremos para hacer un match preciso con el talento ideal para tu empresa.',
      company_logo: 'Logo de la empresa',
      or_drag_the_company_logo: 'o arrastra el logo de la empresa',
      goes_up: 'Sube',
      accepted_formats: 'Formatos aceptados',
      drop_here: 'soltar aqui',
      tell_us_where_they_are: 'Cuéntanos dónde están',
      later_you_will_be_able_to_find_talent_in_person:
        'Más adelante podrás encontrar talento de forma presencial',
      tell_us_about_your_values: 'Cuéntanos sobre sus valores',
      attract_the_right_talent_to_your_company_with_values_aligned_with_theirs:
        'Atrae el talento indicado a tu empresa con valores alineados a los suyos',
      mission: 'Misión',
      optional: 'opcional',
      vision: 'Visión',
      brief_description: 'Breve descripción',
      aspects_you_want_to_highlight: 'Aspectos que desean destacar',
      now_create_your_first_offer_and_discover_the_talent_that_will_transform_your_company:
        'Ahora, crea tu primera oferta y descubre al talento que transformará tu empresa.',
      create_offer: 'Crear oferta',
      your_new_offers_are_coming: 'Tus nuevas ofertas están por llegar',
      professional_experience: 'Experiencia Profesional',
      my_offers: 'Mis ofertas',
      all: 'Todas',
      allTwo: 'Todos',
      accepted: 'Aceptadas',
      acceptedTwo: 'Aceptada',
      without_answer: 'Sin contestar',
      rejected: 'Rechazadas',
      rejectedTwo: 'Rechazada',
      decline: 'Rechazar',
      accept_now: 'Aceptar ahora',
      review_offer: 'Revisar oferta',
      financial_services: 'Servicios financieros',
      description: 'Descripción',
      workers: 'trabajadores',
      highlights: 'Aspectos destacados',
      in_progress: 'En progreso',
      new: 'Nuevo',
      earring: 'Pendiente',
      create_first_offer: 'Crear primera oferta',
      you_have_not_created_offers_yet: 'No has creado ofertas aún',
      post_your_first_job_offer_and_let_our_ai_powered_technology_find_the_perfect_professional_for_you:
        'Publica tu primera oferta y deja que nuestra tecnología potenciada por IA encuentre el profesional perfecto para ti.',
      see_candidates: 'Ver candidatos',
      save_as_draft: 'Guardar como borrador',
      publish_offer: 'Publicar oferta',
      we_are_creating_your_offer: 'Estamos creando tu oferta',
      we_connect_the_talent_that_your_company_needs_to_measure:
        'Conectamos el talento que tu empresa necesita a la medida',
      your_offer_was_created_successfully: 'Tu oferta fue creada con éxito',
      go_to_favorites: 'Ir a Favoritos',
      back_to_offers: 'Volver a Ofertas',
      create_new_offer: 'Crear nueva oferta',
      type_of_salary_offered: 'Tipo de salario ofrecido',
      offer_name: 'Nombre de la oferta',
      assign_a_name_to_the_offer: 'Asigna un nombre a la oferta',
      position_offered: 'Cargo ofertado',
      salary_offered: 'Salario ofrecido',
      candidates: 'Candidatos',
      publication_date: 'Fecha de publicación',
      state: 'Estado',
      actions: 'Acciones',
      duplicate_offer: 'Duplicar oferta',
      bid_options: 'Opciones de la oferta',
      deactivate_offer: 'Desactivar oferta',
      legal_warning: 'Aviso legal',
      all_rights_reserved: 'Todos los derechos reservados',
      log_in: 'Iniciar sesión',
      sign_up: 'Regístrate',
      contact_us: 'Contáctanos',
      follow_us_on: 'Síguenos en:',
      privacy: 'Privacidad',
      terms_and_conditions: 'Términos y condiciones',
      category: 'Categoría',
      look_for: 'Buscar',
      sent: 'Enviados',
      profiles_matched: 'Perfiles hicieron Match',
      review_profile: 'Revisar perfil',
      send_offer: 'Enviar oferta',
      you_are_about_to_send_the_following_offer:
        'Estás por enviar la siguiente oferta',
      selected_candidates: 'Candidatos seleccionados',
      cancel: 'Cancelar',
      send_offers: 'Enviar ofertas',
      save_as_favorite: 'Guardar como favorito',
      search_offer: 'Buscar oferta',
      double_the_offer: 'Duplicar la oferta',
      offer_data: 'Datos de la oferta',
      search_filters: 'Filtros de búsqueda',
      categories: 'Categorías',
      search_categories: 'Buscar categorías',
      languages: 'Idiomas',
      apply_filters: 'Aplicar filtros',
      candidates_associated_with_the_offer: 'Candidatos asociados a la oferta',
      keep_all: 'Conservar todos',
      you_are_about_to_deactivate_the_offer: 'Estás por desactivar la oferta',
      disabled: 'Desactivada',
      keep: 'Conservar',
      rule_out: 'Descartar',
      do_not_you_find_what_you_are_looking_for: '¿No encuentras lo que buscas?',
      let_our_team_find_your_perfect_candidate:
        'Deja que nuestro equipo encuentre a tu candidato perfecto.',
      create_your_offer: 'Crea tu oferta',
      allow_access: 'Permitir acceso',
      receive_access: 'Recibir acceso',
      find_your_talent: 'Encuentra tu talento',
      see_all_talents: 'Ver todos los talentos',
      sign_up_two: 'Registrate',
      become_a_pro_for_free: 'Conviértete en Pro gratis',
      go_pro_for_free_now: '¡Hazte Pro gratis ahora!',
      offers_created: 'Ofertas creadas',
      unvalidated_offers: 'Ofertas sin validar',
      offers_validated: 'Ofertas validadas',
      annual_gross: 'Bruto Anual',
      schedule_appointment: 'Agendar cita',
      our_team_is_here_to_help_you: 'Nuestro equipo está aquí para ayudarte. ',
      offer_detail: 'Detalle de oferta',
      administrator: 'Administrador',
      welcome: 'Bienvenido/a',
      process: 'Procesos',
      take_your_selection_process_seven_times_faster:
        'Lleva tu proceso de selección 7 veces más rápido',
      learn_how_to_use_hutrit_to_hire_quickly_in_just_a_few_steps:
        'Aprende cómo utilizar Hutrit para contratar rápidamente en pocos pasos',
      my_companies: 'Mis compañias',
      total_offers: 'Ofertas totales',
      hired: 'Contratados',
      status_update: 'Actualización de estado',
      messenger_service: 'Mensajeria',
      see_tutorial: 'Ver tutorial',
      chatgpt_assistant: 'Asistente ChatGPT',
      try_now: 'Probar ahora',
      learn_english: 'Aprende Inglés',
      strengthen_your_command_of_the_language_with_lessons_adapted_to_your_level_and_pace:
        'Fortalece tu dominio del idioma con lecciones adaptadas a tu nivel y ritmo.',
      interviewer: 'Entrevistador',
      practice_interviews_based_on_your_profile_and_gain_confidence_for_the_real_day:
        'Practica entrevistas basadas en tu perfil y gana confianza para el día real.',
      softskills: 'Softskills',
      discover_your_strengths_and_areas_of_improvement_in_soft_skills:
        'Descubre tus fortalezas y áreas de mejora en habilidades blandas.',
      assign_all: 'Asignar todos',
      talents_without_a_recruiter: 'Talentos sin reclutador',
      assign_talent: 'Asignar talento',
      select_talent: 'Seleccionar Talento',
      correctly_assigned_talents: 'Talentos asignados correctamente',
      an_error_has_occurred: 'Ha ocurrido un error',
      setting: 'Configuración',
      change_nickname: 'Cambiar apodo',
      email: 'Correo electrónico',
      change_password: 'Cambiar contraseña',
      password: 'Contraseña',
      continue: 'Continuar',
      current_password: 'Contraseña actual',
      enter_your_current_password: 'Ingresa tu contraseña actual',
      enter_your_password: 'Ingresa tu contraseña',
      new_password: 'Nueva contraseña',
      repeat_password: 'Repite contraseña',
      enter_your_new_password: 'Ingresa tu nueva contraseña',
      repeat_your_new_password: 'Repite tu nueva contraseña',
      desactivate_account: 'Desactivar cuenta',
      by_deactivating_your_account_your_profile_will_be_hidden_you_will_not_receive_any_more_offers_and_you_will_lose_contact_with_the_companies_and_your_manager:
        'Al desactivar tu cuenta, tu perfil quedará oculto, no recibirás más ofertas y perderás el contacto con las empresas y tu manager.',
      you_are_about_to_deactivate_your_account:
        'Estás a punto de desactivar tu cuenta',
      enter_your_password_to_continue: 'Escribe tu contraseña para continuar',
      your_account_has_been_deactivated: 'Tu cuenta ha sido desactivada',
      you_can_activate_your_account_again_whenever_you_want:
        'Podrás activar tu cuenta nuevamente cuando desees.',
      leaving_hutrit: 'Salir de Hutrit',
      reactivate_account: 'Reactivar cuenta',
      reactivate_your_account_to_make_your_profile_public_receive_new_offers_and_have_contact_with_your_manager_again:
        'Reactiva tu cuenta para hacer público tu perfil, recibir nuevas ofertas y tener contacto nuevamente con tu manager.',
      reactivating_your_account: 'Reactivando tu cuenta',
      your_account_was_successfully_reactivated:
        'Tu cuenta fue reactivada con éxito',
      enter_new_nickname: 'Ingresar nuevo apodo',
      nickname_updated_successfully: 'Apodo actualizado correctamente',
      nou_cancel: 'No, cancelar',
      yes_desactivate_account: 'Si, desactivar cuenta',
      talk_to_the_ceo: 'Habla con el CEO',
      list_of_candidates: 'Lista de candidatos',
      last_connection: 'Última conexión',
      download_pdf: 'Descargar pdf',
      loading_pdf: 'Cargando pdf',
      support: 'Soporte',
      support_tickets: 'Tickets de soporte',
      talent: 'Talento',
      prospects: 'Prospectos',
      mark_as_resolved: 'Marcar como resuelto',
      write_an_answer: 'Escribe una respuesta',
      recruiter_visits_to_your_profile: 'Visitas de reclutadores a tu perfil',
      number_of_recruiters_who_favorited_your_profile:
        'Cantidad de reclutadores que marcaron tu perfil como favorito',
      number_of_companies_that_have_made_you_an_offer:
        'Cantidad de empresas que te han hecho una oferta',
      talents_with_recruiter: 'Talentos con reclutador',
      menAll: 'Todos',
      apply_second_validation: 'Aplicar segunda validación',
      this_chat_was_marked_as_resolved: 'Este chat fue marcado como resuelto',
      very_badly: 'Muy mal',
      bad: 'Mal',
      regular: 'Regular',
      good: 'Bien',
      excellent: 'Excelente',
      rate_the_conversation: 'Califica la conversación',
      create_for: 'Creado por:',
      user_rating: 'Calificación del usuario',
      talk_to_your_talent_manager: 'Habla con tu Talent Manager',
      without_resolving: 'Sin resolver',
      resolved: 'Resuelto',
      complete_information_manually: 'Completar información manualmente',
      upload_your_linkedin_pdf: 'Sube tu PDF de LinkedIn',
      get_candidates: 'Obtener candidatos',
      name: 'Nombre',
      why_hutrit: 'Por qué Hutrit',
      request_for_offer: 'Solicitud de oferta',
      prospect: 'Prospecto',
      select_the_category: 'Selecciona la categoría',
      select_years_of_experience: 'Selecciona los años de experiencia',
      enter_your_name: 'Ingresa tu nombre',
      enter_your_email: 'Ingresa tu correo electrónico',
      metrics: 'Métricas',
      new_talents: 'nuevos talentos',
      search_by_name: 'Buscar por nombre',
      search: 'Buscar',
      clean_filter: 'Limpiar filtro',
      talent_manager_description: 'Descripción del talent manager',
      validate: 'Validar',
      change_idiom: 'Cambiar idioma',
      spanish: 'Español',
      work_wherever_you_want_and_achieve_your_goals:
        'Trabaja donde quieras y logra tus metas',
      you_have_nothing_to_lose: 'No tienes nada que perder',
      and: 'y',
      a_lot_to_gain: 'mucho que ganar',
      test_the_technology: 'Prueba la tecnología',
      that_connects_you_with_talent: 'que te conecta con el talento.',
      register_for_free: 'Regístrate de forma gratuita',
      nickname: 'Apodo',
      last_name: 'Apellido',
      numbers_and_special_characters_are_not_allowed:
        'No se admiten números ni caracteres especiales',
      enter_valid_email: 'Ingresar correo electrónico válido',
      you_must_enter_a_corporate_email: 'Debe ingresar un correo corporativo',
      confirm_password: 'Confirmar contraseña',
      enter_minimum_characters: 'Ingresar mínimo 8 caracteres',
      passwords_must_match: 'Las contraseñas deben coincidir',
      minimum_characters: 'mínimo 8 caracteres',
      your_email: 'tucorreo@gmail.com',
      register_now: 'Regístrate ahora',
      the_registration_link_has_been_sent_successfully:
        'El enlace de registro ha sido enviado con éxito',
      check_your_email_to_access_your_new_account:
        'Revisa tu correo electrónico para acceder a tu nueva cuenta',
      go_to_your_email: 'Ir a tu correo',
      record: 'Registro',
      creat_your_account: 'Crea tu cuenta',
      sign_up_free: 'Regístrate gratis',
      register_with_email: 'Regístrate con Email',
      do_you_already_have_an_account: '¿Ya tienes cuenta?',
      or_register_with: 'O regístrate con',
      or: 'O',
      sign_up_with_google: 'Regístrate con Google',
      sign_up_with_linkedIn: 'Regístrate con LinkedIn',
      by_registering_you_are_agreeing_to_our:
        'Al registrarte, estás aceptando nuestros',
      start: 'Inicio',
      we_have_already_processed_your_profile: '¡Ya procesamos tu perfil!',
      processing_your_profile: 'Procesando tu perfil',
      view_more: 'ver más',
      save_to_pdf: 'Guardar en PDF',
      to_download_it_go_to_your_linkedIn_profile_click_view_more_and_to_finish_click_on_save_to_pdf:
        'Para descargarlo, ve a tu perfil de LinkedIn, haz clic en {{dynamicValue}} y para finalizar haz click en {{dynamicValueTwo}}',
      the_file_type_is_not_supported: 'El tipo de archivo no es soportado',
      linkedIn_pdf: 'PDF de LinkedIn',
      or_drag_the_pdf_from_linkedin: 'O arrastra el PDF de linkedin',
      file_uploaded_successfully: '¡Archivo cargado correctamente!',
      you_are_just_a_few_steps_away_from_completing_your_profile_and_attracting_the_best:
        'Estás a solo unos pasos de completar tu perfil y atraer las mejores',
      opportunities: 'oportunidades',
      upload_information: 'Subir información de',
      complete_information: 'Completar información',
      manually: 'manualmente',
      in_spain: 'Ej. España',
      in_catalonia: 'Ej. Cataluña',
      in_barcelona: 'Ej. Barcelona',
      maximum_characters_allowed: 'Máximo 20 caracteres permitidos',
      you_must_enter_a_valid_url_for_linkedIn_ex:
        'Debe ingresar una url valida para linkedin. Ej',
      enter_the_link: 'Ingresa el enlace',
      you_have_repeated_skills: 'Tienes habilidades repetidas',
      minimum_skills: 'Minimo {{value}} habilidades',
      add_more: 'Agregar más',
      step_one_location: 'Paso 1: Ubicación',
      step_two_additional_data: 'Paso 2: Datos adicionales',
      step_three_profession: 'Paso 3: Profesión',
      step_four_skills: 'Paso 4: Habilidades',
      step_five_education: 'Paso 5: Educación',
      step_six_work_experience: 'Paso 6: Experiencia laboral',
      here_you_will_see_your_profession: 'Aquí verás tu profesión',
      profile_visits: 'Visitas a tu perfil',
      in_favorites: 'En favorito',
      job_offers_received: 'Ofertas recibidas',
      configuration: 'Configuración',
      log_out: 'Cerrar sesión',
      now_complete_and_correct_the_corresponding_information:
        'Ahora, completa y corrige la información correspondiente.',
      we_are_under_maintenance_but_we_want_to_help_you:
        'Estamos en mantenimiento, pero queremos ayudarte',
      by_completing_the_following_form_our_team_will_take_care_of_find_the_talent_you_need_at_no_cost:
        'Al completar el siguiente formulario, nuestro equipo se encargará de buscar el talento que necesitas sin costo alguno.',
      complete_form: 'Completar formulario',
      our_ai_tool_has_matched: 'Nuestra herramienta de IA ha hecho match con',
      profiles_you_can_find_them_in_the_favorites_section:
        'perfiles, puedes encontrarlos en la sección de Favoritos',
      create_another_offer: 'Crear otra oferta',
      we_are_working_on_it: 'Esta funcionalidad esta en desarrollo.',
      your_notifications: 'Tus notificaciones',
      mark_as_read: 'Marcar como leídas',
      newTwo: 'Nuevas',
      years: 'años',
      months: 'meses',
      days: 'días',
      today: 'Hoy',
      previous: 'Anteriores',
      there_are_no_more_notifications: 'No hay más notificaciones',
      no_notifications: 'Sin notificaciones',
      here_we_will_notify_you_when_companies_contact_you_and_information_relevant_to_you:
        'Aquí te notificaremos cuando las empresas te contacten e información relevante para ti',
      review_all: 'Revisar todas',
      filters: 'Filtros',
      search_by_category: 'Buscar por categoría',
      search_by_skills: 'Buscar por habilidades',
      clean_filters: 'Limpiar filtros',
      former: 'Anterior',
      notifications: 'Notificaciones',
      from_experience: 'de experiencia',
      when_you_submit_the_offer_you_will_not_be_able_to_edit_it_to_modify_the_offer_go_to:
        'Al enviar la oferta, no podrás editarla. Para modificar la oferta ve a',
      offer_sent: 'Oferta enviada',
      inn_this_space_you_can_communicate: 'En este espacio podrás comunicarte',
      with: 'con',
      he: 'El',
      the_recruiter: 'the_recruiter',
      recruiter: 'reclutador',
      the_company: 'la empresa',
      this_space_was_created_so_that_a_manager_or_a_company_can_establish_a_conversation_with_you:
        'Este espacio fue creado para que un manager o una empresa  pueda establecer una conversación contigo',
      charging: 'Cargando...',
      you: 'Tú',
      hutrit_manager: 'Manager de hutrit',
      greeting: '¡Hola, {{value}}! 👋',
      here_you_will_receive_messages_from_your: 'Aquí recibirás mensajes de tu',
      manager_and_you_will_establish_contact_with:
        'manager y establecerás contacto con',
      companies_you_have_accepted: 'companies you have accepted.',
      ex_software_consulting: 'Ej. Consultora de Software',
      ex: 'Ej',
      spain: 'España',
      step_company_details:
        'Paso 1: Datos de la empresaCuéntanos cómo podemos ayudarteCuéntanos cómo podemos ayudarte',
      step_location: 'Paso 2: Ubicación',
      step_company_values: 'Paso 3: Valores de la empresa',
      your_message_was_sent_successfully: '¡Tu mensaje fue enviado con éxito!',
      our_team_will_contact_you_as_soon_as_possible_if_youre_in_a_big_hurry_make_an_appointment_right_now:
        'Nuestro equipo se pondrá en contacto contigo cuanto antes. Si tienes mucha prisa, agenda una cita ahora mismo.',
      return_to_hutrit: 'Volver a Hutrit',
      message: 'Mensaje',
      tell_us_how_we_can_help_you: 'Cuéntanos cómo podemos ayudarte',
      send_message: 'Enviar mensaje',
      schedule_an_appointment: 'Agenda una cita',
      on_our_platform_job_offers_come_to_you_in_this_section_you_can_review_the_offers_you_receive_to_connect_with_the_best_opportunities:
        'En nuestra plataforma, las ofertas de empleo llegan a ti. En esta sección podrás revisar las ofertas que recibas para conectarte con las mejores oportunidades.',
      request_evaluation_of_my_profile: 'Pedir valoración de mi perfil',
      create_your_first_offer: 'Crea tu primera oferta',
      post_your_first_offer_and_let_our_ai_powered_technology_find_the_perfect_professional_for_you:
        'Publica tu primera oferta y deja que nuestra tecnología potenciada por IA encuentre el profesional perfecto para ti.',
      category_created_successfully: 'Categoría creada correctamente',
      successfully_created_city: 'Ciudad creada correctamente',
      you_must_select_a_country_and_a_state_to_see_the_list_of_cities:
        'Debes seleccionar un país y un estado para ver la lista de las ciudades.',
      list_of_cities: 'Lista de ciudades',
      enter_the_name_of_the_city_to_create:
        'Ingresa el nombre de la ciudad a crear',
      language_created_successfully: 'Idioma creado correctamente',
      welcome_manager: 'Bienvenido Manager',
      here_you_will_see_the_name_of_the_company:
        'Aquí verás el nombre de la empresa',
      copy_link: 'Copiar link',
      attention: 'Atención',
      list_of_companies: 'Lista de compañias',
      what_profiles_are_you_looking_for: '¿Qué perfiles están buscando?',
      assign_a_name_so_you_can_identify_your_offers_faster_this_information_will_not_be_visible_to_the_talent:
        'Asigna un nombre para que puedas identificar tus ofertas más rápido. Esta información no será visible para el talento.',
      choose_the_skills: 'Escoge las habilidades',
      annual_offered_salary: 'Salario ofrecido anual',
      minimum: 'Mínimo',
      average_amount: 'Monto promedio',
      maximum: 'Máximo',
      the_initial_range_must_be_less_than_the_final_range:
        'El rango inicial debe ser menor al rango final.',
      do_you_prefer_to_include_a_salary_range:
        '¿Prefieres incluir un rango salarial?',
      salary_type: 'Tipo de salario',
      choose_the_type_of_salary: 'Escoge el tipo de salario',
      annual: 'Anual',
      in_person: 'Presencial',
      you_will_be_able_to_search_for_candidates_in_person_very_soon:
        '¡Podrás buscar candidatos de forma presencial muy pronto!',
      say_goodbye_to_manual_talent_search_complete_your_job_offer_and_get_ready_to_meet_your_ideal_candidate:
        'Dile adiós a la búsqueda manual de talento. Completa tu oferta laboral y prepárate para conocer a tu candidato ideal.',
      tell_us_what_happened: 'Cuéntanos qué pasó',
      please_tell_us_more_about_what_you_didnt_like_about_the_offer_so_we_can_send_you_those_that_meet_your_expectations:
        'Por favor, cuéntanos más sobre lo que no te gustó de la oferta para enviarte aquellas que se adapten a tus expectativas.',
      offer_disabled: '¡Oferta Desactivada!',
      we_will_notify_the_talent_that_the_offer_is_not_available_you_can_activate_it_again_and_notify_the_talent_to_continue_with_the_selection_process:
        'Notificaremos al talento que la oferta no se encuentra disponible. Puedes activarla nuevamente y notificar al talento para continuar con el proceso de selección',
      annuals: 'Anuales',
      more_than_years: 'Más de {{value}} años',
      without_years: 'Sin años',
      created_in: 'Creado en',
      you_cannot_have_more_than_one_offer_active:
        'No puedes enviar más de una vez la oferta al mismo talento',
      the_some_selected_talents_have_already_been_sent_the_offer:
        'A algunos de los talentos seleccionados ya se les ha enviado la oferta.',
      do_you_have_a_company_or: '¿Tienes una empresa o',
      work_team: 'equipo de trabajo?',
      receive_more_information_and_resolve_doubts:
        'Recibe más información y resuelve dudas',
      directly_with_our_ceo_at: 'directamente con nuestro CEO a',
      via_whatsApp: 'través de WhatsApp',
      if_you_hire_you_will_pay: 'Solo pagarás si contratas.',
      you_hire: 'contratas.',
      use_our_tools_publish_all_the_offers_you_want_and_find:
        'Utiliza nuestras herramientas, publica todas las ofertas que quieras y encuentra',
      the_technological_talent_you_need:
        'el talento tecnológico que necesitas.',
      totally_free: 'Totalmente gratis.',
      know_benefits: 'Conocer beneficios',
      because: 'Por qué',
      in_seconds: 'En {{value}} segundos…',
      you_will_find_the_perfect_technological_talent_for_your_company_a_once_the_job_offer_is_created_our_ai_will_show_you_the_candidates_that_meet_your_needs:
        'Encontrarás al talento tecnológico perfecto para tu empresa. Una vez creada la oferta laboral nuestra IA te mostrará los candidatos que cumplan con tus necesidades.',
      verified_talents: 'Talentos verificados',
      each_talent_has_been_evaluated_and_certified_to_ensure_their_competence_and_experience_in_their_area_of_expertise_trust_our_platform_to_find_the_right_professionals_for_your_technological_projects:
        'Cada talento ha sido evaluado y certificado para garantizar su competencia y experiencia en su área de especialización. Confía en nuestra plataforma para encontrar a los profesionales adecuados para tus proyectos tecnológicos.',
      you_will_match: 'Harás match…',
      this_way_you_will_be_more_effective_in_the_search_for_talent:
        'Solo con las personas que estén interesadas con tu oferta laboral y cumplan con los skills solicitados, de esta forma conseguirás ser más efectivo en la búsqueda del talento.',
      find_the: 'Encuentra al',
      ideal_candidate: 'candidato ideal',
      sign_up_to_use_the_filter_tool_to_find_your_ideal_candidate_faster_or_browse_all_available_talent_without_signing_up:
        'Regístrate para usar la herramienta de filtro y encontrar tu candidato ideal más rápido, o explora todos los talentos disponibles sin registrarte',
      register_to_access_filter_and_save_your_favorite_candidates:
        'Regístrate para acceder, filtrar y guardar tus candidatos favoritos.',
      seen: 'Visto',
      benefits_of_using_hutrit: 'Beneficios de usar Hutrit',
      for_businesses: 'Para empresas',
      you_can_use_the_platform_for_free_once_registered_they_will_be_able_to_search_for_the_ideal_candidate_and_you_will_only_pay_if_you_hire:
        'Podrás usar la plataforma de manera gratuita. Una vez registrados podrán buscar al candidato ideal. Y solo pagarás si contratas.',
      find_the_ideal_candidate: 'Encontrar al candidato ideal',
      for_talent: 'Para el talento',
      thanks_to_hutrit_you_receive_clear_job_offers_without_applying_and_you_will_only_speak_with_those_companies_in_which_you_are_interested:
        'Gracias a Hutrit recibes ofertas laborales claras sin postularte y solo hablarás con aquellas empresas en las que estés interesado.',
      connect_with_company: 'Conectar con empresa',
      and_complete_your_profile_in_just_a_few_steps:
        'Y completa tu perfil en pocos pasos.',
      create_an_offer: 'Crea una oferta',
      the_better_the_description_the_better_results_you_will_get:
        'Mientras mejor sea la descripción, mejores resultados obtendrás.',
      send_the_job_offer: 'Envía la oferta laboral',
      browse_to_see_all_profiles_and_find_the_ideal_candidate:
        'Navega para ver todos los perfiles y encontrar al candidato ideal.',
      start_the_selection_process: 'Inicia el proceso de selección',
      only_interview_those_you_want_to_work_with_you:
        'Solo entrevista a los que desees que trabajen contigo.',
      only_people_who_are_interested_in_your_job_offer_and_meet_the_requested_skills_this_way_you_will_be_more_effective_in_the_search_for_talent:
        'Solo con las personas que estén interesadas con tu oferta laboral y cumplan con los skills solicitados, de esta forma conseguirás ser más efectivo en la búsqueda del talento.',
      why: 'Por qué',
      for_companies: 'Para empresas',
      complete_your_professional_profile: 'Completa tu perfil profesional',
      highlight_your_unique_skills_all_the_work_experiences_you_have_and_the_accomplishments_that_make_you_proud:
        'Resalta tus habilidades únicas, las experiencias que has acumulado y los logros que te llenan de orgullo.',
      receive_feedback_of_your_profile: 'Recibe feedback de tu perfil',
      our_team_of_experts_will_provide_you_with_advice_and_suggestions_to_optimize_your_profile_and_increase_your_visibility:
        'Nuestro equipo de expertos te proporcionará asesoría y sugerencias para optimizar tu perfil y aumentar tu visibilidad.',
      start_receiving_job_offers: 'Comienza a recibir ofertas',
      receive_personalized_job_offers_according_to_your_profile_check_select_and_accept_the_best_job_offers_for_you:
        'Recibe ofertas laborales personalizadas según tu perfil. Revisa, selecciona y acepta las mejores ofertas para ti.',
      a_disruptive_method: 'Un método disruptivo',
      use_our_tools_for_free: 'Utiliza nuestras herramientas de forma gratuita',
      welcome_back: '¡Bienvenido de vuelta!',
      enter_hutrit: 'Ingresa a Hutrit',
      your_nickname_or_your_email: '@tuapodo o tucorreo@gmail.com',
      did_you_forget_your_password: '¿Olvidaste tu contraseña?',
      enter_now: 'Ingresar ahora',
      or_enter_with: 'O ingresa con',
      sign_in_with_google: 'Ingresa con Google',
      sign_in_with_linkedin: 'Ingresa con LinkedIn',
      you_do_not_have_an_account: '¿No tienes cuenta?',
      you_found_the_talent_you_were_looking_for_follow_these_steps_to_contact_him_and_have_the_best_candidate:
        'Encontraste el talento que buscabas. Sigue estos pasos para contactarlo y tener al mejor candidato',
      send_the_offer: 'Envía la oferta',
      selection_begins: 'Comienza la selección',
      steps_to_contact_talent: 'Pasos para contactar talento',
      until: 'hasta',
      work_here_currently: 'Trabaja aqui actualmente',
      activities: 'Actividades',
      you_have_attempt_to_change_your_username:
        'Tienes {{value}} intento de cambiar tu username',
      updated_password: 'Contraseña actualizada',
      by_deactivating_your_account_your_profile_will_be_hidden_you_will_not_receive_any_more_offers_and_you_will_lose_contact_with_the_companies_and_your_manager_do_you_want_to_continue:
        'Al desactivar tu cuenta, tu perfil quedará oculto, no recibirás más ofertas y perderás el contacto con las empresas y tu manager ¿Deseas continuar?',
      monthly: 'Mensual',
      weekly: 'Semanal',
      general: 'Generales',
      registered_talents: 'Talentos registrados',
      validated_talents: 'Talentos validados',
      current_month: 'Mes actual',
      previous_month: 'Mes previo',
      active_users: 'Usuarios activos',
      your_description_is_the_voice_of_your_professional_story_take_advantage_of_this_space_to_share_your_vision_passions_and_what_moves_you_in_your_career:
        'Tu descripción es la voz de tu historia profesional. Aprovecha este espacio para compartir tu visión, pasiones y lo que te mueve en tu carrera.',
      over_you: 'Sobre ti',
      write_a_description_of_your_profile:
        'Escribe una descripción de tu perfil',
      technical_skills: 'Habilidades técnicas',
      soft_skills: 'Habilidades blandas',
      select_your_level: 'Selecciona tu nivel',
      select_a_skill: 'Selecciona una habilidad',
      youre_about_to_leave: 'Estás a punto de salir',
      are_you_sure_you_want_to_log_out:
        '¿Estás seguro de que deseas cerrar tu sesión?',
      completed_profiles: 'Perfiles completados',
      talent_funnel: 'Embudo de talento',
      referral_link: 'Link de referido',
      offers_with_talents: 'Ofertas con talentos',
      review_talent: 'Revisar talento',
      no_external_links_allowed_in_this_field:
        'No se permiten enlaces externos en este campo',
      registered_talent_managers: 'Talent Managers registrados',
      active_talent_managers: 'Talent Managers activos',
      abandoned_talent_managers: 'Talent Managers abandonados',
      the_nickname_cannot_contain_an_email_or_url:
        'El apodo no puede contener un correo electrónico o una URL.',
      required_level_of_english: 'Nivel de inglés requerido',
      years_of_experience_required: 'Años de experiencia requeridos',
      edit_offer: 'Editar oferta',
      web_page: 'Página web',
      now_you_can_complete_your_profile_with_linkedIn:
        '¡Ahora puedes completar tu perfil con LinkedIn!',
      boost_your_profile_in_one_click_by_uploading_the_pdf_of_your_profile_on_linkedI:
        'Potencia tu perfil en un click subiendo el pdf de tu perfil en LinkedIn.',
      skip: 'Omitir',
      complete_profile_with_linkedIn: 'Completar perfil con LinkedIn',
      incomplete: 'Incompletos',
      validated: 'Validados',
      activated: 'Activados',
      validation: 'Validación',
      talent_activation: 'Activación del talento',
      activate_talent: 'Activar talento',
      report_problem: 'Reportar problema',
      change_the_tm: 'Cambiar el TM',
      about_the_profile: 'Sobre el perfil',
      see_details: 'Ver detalles',
      deactivate: 'Desactivar',
      remove: 'Eliminar',
      activate: 'Activar',
      profiles: 'Perfiles',
      registered_profile: 'Perfil registrado',
      validated_profile: 'Perfil validado',
      successfully_eliminated_talent: 'Talento eliminado existosamente',
      talent_successfully_deactivated: 'Talento desactivado exitosamente',
      successfully_activated_talent: 'Talento activado exitosamente',
      problem_reported_successfully: 'Problema reportado exitosamente',
      profile_created: 'Perfil creado',
      copy_invitation_link: 'Copiar link de invitación',
      your_invitation_link_was_successfully_copied:
        'Tu link de invitación fue copiado con éxito',
      your_talents: 'Tus talentos',
      registered: 'Registrados',
      number_of_talents_who_completed_the_registration_process_with_your_invitation_link:
        'Cantidad de talentos que completaron el proceso de registro con tu link de invitación',
      number_of_talents_who_have_not_completed_their_profile_in_hutrit:
        'Cantidad de talentos que no han completado su perfil en Hutrit',
      number_of_talents_that_passed_the_verification_process_and_have_their_profile_open_to_offers:
        'Cantidad de talentos que pasaron el proceso de verificación y tienen su perfil abierto a las ofertas',
      keep_your_data_updated_to_maintain_better_communication_with_your_talents:
        'Mantén tus datos actualizados para mantener una mejor comunicación con tus talentos.',
      my_talents: 'Mis talentos',
      offers_received: 'Ofertas recibidas',
      latest_talents: 'Últimos talentos',
      not_validated: 'Sin validar',
      frequent_questions: 'Preguntas frecuentes',
      see_all_your_talents: 'Ver todos tus talentos',
      without_validating: 'Sin validar',
      email_successfully_copied: 'Correo electrónico copiado con éxito',
      phone_number_successfully_copied: 'Número telefónico copiado con éxito',
      feedback_sent_successfully: 'Feedback enviado con éxito',
      all_offers: 'Todas las ofertas',
      check_registered_talents_by_first_and_last_name:
        'Consulta talentos registrados por nombre y apellido',
      consult: 'Consultar',
      tm_active_offers: 'Ofertas activas',
      successfully_validated_talent: 'Talento validado con éxito',
      sort_by: 'Ordenar por',
      date: 'Fecha',
      search_category: 'Buscar categorías',
      search_skills: 'Buscar habilidades',
      search_experience: 'Buscar experiencia',
      talent_consultation: 'Consulta de talento',
      not_registered_in_hutrit: 'No registrado en hutrit',
      talent_registered_in_hutrit: 'Talento registrado en Hutrit',
      question_one:
        '¿Desde qué países las empresas estarán enviando ofertas laborales?',
      answer_one: 'Desde España, Estados Unidos y LATAM.',
      question_two: '¿Se pueden registrar Talentos de todos los países?',
      answer_two:
        'Si, el registro en Hutrit es para todos. La página se puede cambiar al Inglés para que el Talento haga su registro sin problemas. Y también pueden cargar toda su información en Inglés.',
      question_three:
        '¿El Talent Manager puede cargar el CV del Talento en Hutrit?',
      answer_three:
        'No. Solo el Talento es quien tiene que cargar su información/CV en la plataforma.',
      question_four:
        '¿Los Talentos deben cargar en Hutrit la información sobre su educación?',
      answer_four:
        'No, en nuestra plataforma nos enfocamos en que su CV solo muestre las habilidades técnicas que conoce y su experiencia laboral.',
      question_five:
        '¿El Talento puede cargar en Hutrit cualquier PDF para agilizar su registro?',
      answer_five:
        'No, en Hutrit solo se podrá cargar el PDF que se descarga de LinkedIn. Deben considerar que si el Talento carga el PDF de LinkedIn vacío porque en su cuenta no tiene alguna descripción de su experiencia laboral, en nuestra plataforma se verá igualmente vacío.',
      question_six:
        '¿Si un Talento indica que no ve una habilidad técnica, se puede añadir a la plataforma?',
      answer_six:
        'Si, deben solicitarle el nombre de dicha habilidad y la podemos agregar a la plataforma para que el Talento culmine su registro exitosamente.',
      question_seven:
        '¿Les llegará un correo notificando si alguna Empresa ve su perfil de Hutrit?',
      answer_seven:
        'Si, diariamente se le enviará al Talento un correo con todas las notificaciones recibidas en su cuenta. Igualmente en la plataforma podrán ver todas las notificaciones que surjan en cuanto a ofertas recibidas, guardados en favoritos, etc. Y ustedes como sus Talent Managers recibirán las mismas notificaciones que sus Talentos reciban.',
      question_eight: '¿Cómo funciona esta mentoría de ser Talent Manager?',
      answer_eight:
        'Los Talent Managers evaluarán el perfil de los Talentos y le indicarán si hay algo que pueda mejorar, quizá en la redacción de su experiencia laboral, agregar más habilidades técnicas que conozca, etc. Además, estarán acompañándolos durante toda su carrera profesional.',
      question_nine:
        '¿Cómo funciona el proceso con su plataforma? O si indican que es similar a Upwork.',
      answer_nine:
        'Esta plataforma funciona a la inversa de lo que venimos acostumbrados que es postular, postular y postular y muchas veces sin tener respuestas de esas postulaciones. Hutrit sirve para unir Talentos Tecnológicos con Empresas de manera disruptiva, es decir, el Talento recibirá las ofertas laborales que se adecuen a su perfil directo a su cuenta en Hutrit y tendrá la decisión de aceptar o rechazar. Una vez que el talento acepte la oferta pasará a la entrevista con la Empresa directamente, quienes le indicarán todo el detalle de la vacante a ocupar y lo necesario para empezar a trabajar. Nosotros, Hutrit, servimos de puente entre los Talentos y las Empresas. Es decir, con nosotros en Hutrit no tendrán que realizar tareas ni nada similar a Upwork. Solo recibirán ofertas de trabajo reales y claras.',
      question_ten: '¿Tengo que pagar una cuota por utilizar sus servicios?',
      answer_ten:
        'No, Hutrit es completamente gratis para los Talentos, los Talent Managers y las Empresas. Únicamente pagan las Empresas cuando se realice una contratación de un Talento.',
      question_eleven: '¿Una vez que consiga empleo tengo que pagar una cuota?',
      answer_eleven:
        'No, como Talento no se debe pagar nada. Tampoco como Talent Manager.',
      question_twelve: 'Si las personas preguntan qué tan real es esta página:',
      answer_twelve:
        'Explicar que es una plataforma que está iniciando y que ya tenemos Empresas que están revisando los perfiles, y que de existir una vacante que se adecue a su CV el Talento estará recibiendo las ofertas directamente en su cuenta de Hutrit.',
      question_thirteen: '¿Cómo son los métodos de pagos a los Talentos?',
      answer_thirteen:
        'Los mismos dependen de cada empresa. Hutrit no tiene que ver con esta parte, nuestra plataforma solo se encarga de hacer el enlace entre el Talento y la Empresa. Salvo que la empresa contrate a Hutrit para manejar todo el proceso de selección y contratación del Talento, Hutrit se encargará de pagarle bien sea por PayPal, transferencias en dólares o si se encuentra en Venezuela transferencias en Bolívares.',
      question_fourteen:
        '¿El contacto con las Empresas es únicamente a través de Hutrit?',
      answer_fourteen:
        'Si, solo será dentro de la plataforma. Los datos personales del Talento estarán siempre en el anonimato, y una vez que acepten las ofertas laborales y se haga el primer contacto con la Empresa, los mismos se podrán visualizar. Y desde allí la Empresa podrá contactarlo para coordinar la entrevista.',
      question_fifteen:
        '¿Las pasantías realizadas cuentan como experiencia laboral?',
      answer_fifteen: 'No.',
      question_sixteen: '¿Cómo será el proceso de entrevistas?',
      answer_sixteen:
        'Este proceso dependerá de cada Empresa. Y la misma deberá informar en todo momento al Talento. De igual forma, en Hutrit estaremos siempre informando como va el proceso de cada Talento, así que ustedes como Talent Managers estarán muy informados de cada entrevista realizada hasta la contratación.',
      question_seventeen: '¿Puede un Talento desactivar su cuenta en Hutrit?',
      answer_seventeen:
        'Si, todos los talentos tienen en la configuración de su cuenta para desactivarla cuando deseen. Le llegará una notificación a su Talent Manager.',
      question_eighteen:
        '¿Los Talentos que se deben registrar solo deben ser del área de IT?',
      answer_eighteen:
        'Si, Hutrit significa por sus siglas en Inglés, Human Talent Recruitment IT. Por lo tanto, nuestra plataforma se basa única y exclusivamente en Talentos del área de Tecnología.',
      active_processes: 'Procesos activos',
      search_by_nickname_or_email: 'Buscar por apodo o correo',
      affer_received: 'Oferta recibida',
      see_the_process: 'Ver el proceso',
      selection_process: 'Proceso de selección',
      validate_talent: 'Validar Talento',
      send_feedback: 'Enviar feedback',
      open_chat: 'Abrir chat',
      vacancies: 'Vacantes',
      creation: 'Creación',
      email_copied_successfully: 'Correo electrónico copiado exitosamente',
      successfully_reassigned_talent: 'Talento re-asignado correctamente',
      username: 'Nombre de usuario',
      reject_talent: 'Rechazar talento',
      birthdate: 'Fecha de nacimiento',
      your_date_of_birth_is_missing: 'Falta incluir tu fecha de nacimiento',
      enrich_your_profile_on_hutrit: 'Enriquece tu perfil en Hutrit',
      add_your_date_of_birth_to_receive_exclusive_offers_and_benefits_for_you:
        'Agrega tu fecha de nacimiento para recibir ofertas y beneficios exclusivos para ti.',
      add_date_of_birth: 'Agregar fecha de nacimiento',
      you_are_about_to_send_feedback_to_the_talent:
        'Estás a punto de enviarle feedback al talento',
      by_sending_feedback_the_talent_will_receive_an_email_indicating_that_they_must_improve_their_professional_profile_within_hutrit_are_you_sure_you_want_to_continue:
        'Al enviar feedback, el talento recibirá un correo indicándole que debe mejorar su perfil profesional dentro de Hutrit. ¿Seguro que deseas continuar?',
      yes_send_feedback: 'Sí, enviar feedback',
      no_cancel: 'No, cancelar',
      number_of_connections: 'Numero de conexiones',
      hybrid: 'Hibrido',
      now_you_can_create_offers_with_hybrid_mode:
        '¡Ahora puedes crear ofertas con modalidad híbrida!',
      modality: 'Modalidad',
      your_profile_has_opportunities_for_improvement:
        'Tu perfil tiene oportunidades de mejora',
      your_talent_manager_has_some_suggestions_to_boost_your_profile_remember_that_it_is_the_mirror_of_your_professionalism_and_your_letter_of_introduction_to_the_world:
        'Tu Talent Manager tiene algunas sugerencias para potenciar tu perfil. Recuerda que es el espejo de tu profesionalismo y tu carta de presentación ante el mundo',
      improve_my_profile_now: 'Mejorar mi perfil ahora',
      your_talent_manager_has_sent_you_to_improve_your_profile:
        'Tu Talent Manager te ha enviado a mejorar tu perfil. ',
      dont_know_how_to_improve_it: '¿No sabes cómo mejorarlo?',
      look: 'Mira',
      a: 'un',
      junior_profile_example: 'ejemplo de perfil Junior',
      example_of_a_senior_profile: 'ejemplo de un perfil Senior',
      highlight_your_profile_as_a_senior: 'Destaca tu perfil como un Senior',
      make_the_most_of_your_experience_with_a_profile_that_authentically_represents_you_see_how_we_do_it_in_our_featured_example:
        'Aprovecha al máximo tu experiencia con un perfil que te representa auténticamente. Observa cómo lo hacemos en nuestro ejemplo destacado.',
      highlight_your_profile_as_a_junior: 'Destaca tu perfil como un Junior',
      find_talent_in_seconds: 'Encuentra el talento en segundos',
      you_have_already_sent_feedback_to_this_talent:
        'Ya le enviaste feedback a este talento',
      are_you_talented: '¿Eres talento?',
      my_enterprise: 'Mis empresas',
      you_will_only_pay_if: 'Solo pagarás si',
      superfinder: 'Superbuscador',
      check_our_updates: 'Conoce nuestra nueva actualización',
      quickly_find_the_ideal_talent_with_our_superfinder_engine_and_its_advanced_filters:
        'Encuentra rápidamente el talento ideal con nuestro superbuscador y sus filtros avanzados.',
      skip_and_continue: 'Omitir y Continuar',
      go_to_superfinder: 'Ir al superbuscador',
      try_our: 'Prueba nuestro',
      totally_free_and_without_signing_up: 'Totalmente gratis y sin registro.',

      see_talents: 'Ver Talentos',
      professionals_in: 'Profesionales en',
      remote_from: 'remoto de',
      verified: 'verificados',
      hutrit_is_for_who: '¿Para quién es Hutrit?',
      for_it_consultants: 'Para consultorías de TI',
      for_startups_and_pymes: 'Para Startups y PYMES',
      try: 'Probar',
      our_blog: 'Nuestro blog',
      find_tips_and_strategies_for_talents_recruiters_and_companies_in_the:
        'Encuentra consejos y estrategias para talentos, reclutadores y empresas en el',
      technology_sector: 'sector tecnológico',
      use_our_superfinder_engine_free_of_charge:
        'Utiliza nuestro superbuscador de forma gratuita',
      company_activation: 'Activación de la empresa',
      activate_company: 'Activar empresa',
      you_are_about_to_activate_this_company:
        'Estás a punto de activar a esta empresa',
      by_activating_this_company_you_will_have_access_to_the_platform_are_you_sure_you_want_to_continue:
        'Al activar esta empresa, tendrá acceso a la plataforma ¿Seguro que deseas continuar?',
      yes_activate_company: 'Sí, activar empresa',
      add_notes: 'Agregar notas',
      disable: 'Desactivar',
      delete: 'Eliminar',
      change_the_talent_manager: 'Cambiar el Talent Manager',
      current_talent_manager: 'Talent Manager actual',
      new_talent_manager: 'Talent Manager nuevo',
      find_the_talent_manager: 'Busca el Talent Manager',
      note: 'Nota',
      the_company_will_be_visible_to_the_talent_manager_you_select:
        'La empresa será visible para el Talent Manager que selecciones',
      save_changes: 'Guardar cambios',
      no_talent_manager_found: 'No se encontró ningún Talent Manager',
      copy_invitation: 'Copiar invitación',
      invitation_in_spanish: 'Invitación en español',
      invitation_in_english: 'Invitación en inglés',
      company_notes: 'Notas de la empresa',
      write_relevant_information_about_the_company:
        'Escribe información relevante sobre la empresa',
      write_your_comments: 'Escribe tus comentarios',
      verified_email: '¡Correo verificado!',
      welcome_to_hutrit: 'Bienvenido a Hutrit',
      checking_email: 'Verificando correo...',
      there_was_an_error_while_validating_your_email:
        'Hubo un error mientras se validaba su correo',
      low_skill_level: 'Nivel de habilidades bajo',
      could_you_do_it_better: 'Puedes hacerlo mejor',
      brilliant_high_level_of_skills: '¡Genial! 🌟 Nivel alto de habilidades',
      includes_at_least_skills_we_recommend_you_include_between_to_skills_to_strengthen_your_profile:
        'Incluye mínimo {{minimumSkills}} habilidades. Te recomendamos incluir entre {{numberOfSkillsOne}} a {{numberOfSkillsTwo}} habilidades para fortalecer tu perfil',
      low_experience_level: 'Nivel de experiencia baja',
      brilliant_high_level_of_experience:
        '¡Genial! 🌟 Nivel alto de experiencia',
      our_talents_that_include_between_and_skills_receive_more_offers:
        'Nuestros talentos que incluyen entre 10 y 15 habilidades reciben 80% más ofertas',
      excellent_your_profile_now_has_the_skills_necessary_to_stand_out:
        '¡Excelente! Tu perfil ahora tiene las habilidades necesarias para destacar.',
      best_describe_the_role_you_played_in_this_company_you_can_see_a:
        'Describe mejor el papel que desempeñaste en esta empresa. Puedes ver un',
      successful_example_here: 'ejemplo exitoso aquí.',
      use_styles_in_your_text_to_better_highlight_your_experience_highlight_important_points_and_personalize_your_writing:
        'Utiliza estilos en tu texto para resaltar mejor tu experiencia. Resalta puntos importantes y personaliza tu redacción.',
      brilliant_a_complete_description_of_your_experience_will_bring_you_closer_to_the_position_you_deserve:
        '¡Genial! Una descripción completa de tu experiencia te acercará más al puesto que te mereces',
      low_description_level: 'Nivel de descripción bajo',
      brilliant_great_description: '¡Genial! 🌟 Gran descripción',
      your_description_is_very_brief_add_more_details_to_attract_better_offers:
        'Tu descripción es muy breve. Añade más detalles para atraer mejores ofertas.',
      your_description_is_good_but_it_could_be_better_add_more_details_to_stand_out_more:
        'Tu descripción es buena, pero podría ser mejor. Añade más detalles para destacar más.',
      excellent_your_description_is_very_complete_this_will_increase_your_chances_of_receiving_high_quality_job_offers:
        '¡Excelente! Tu descripción es muy completa. Esto aumentará tus posibilidades de recibir ofertas laborales de alta calidad.',
      number_of_offers_that_are_in_process:
        'Cantidad de ofertas que se encuentran en proceso',
      number_of_offers_shared_with_candidates:
        'Cantidad de ofertas que se compartieron con candidatos',
      number_of_offers_that_were_accepted_by_candidates:
        'Cantidad de ofertas que fueron aceptadas por los candidatos',
      company_successfully_deactivated: 'Empresa desactivada correctamente',
      successfully_deleted_company: 'Empresa eliminada correctamente',
      you_are_about_to_deactivate_the_company:
        'Estás a punto de desactivar la empresa',
      by_deactivating_the_company_you_will_lose_access_to_the_platform_you_can_reactivate_the_company_at_any_time_are_you_sure_you_want_to_continue:
        'Al desactivar la empresa, perderá el acceso a la plataforma. Puedes volver a activar la empresa en cualquier momento ¿Seguro que deseas continuar?',
      yes_deactivate_company: 'Sí, desactivar empresa',
      you_are_about_to_eliminate_the_company:
        'Estás a punto de eliminar la empresa',
      deleting_the_company_will_delete_your_entire_hutrit_record_this_action_cannot_be_undone_are_you_sure_you_want_to_continue:
        'Al eliminar la empresa, se borrará todo el registro de Hutrit. Esta acción no se puede deshacer ¿Seguro que deseas continuar?',
      yes_delete_company: 'Sí, eliminar empresa',
      recruiter_change_successfully_completed:
        'Cambio de reclutador realizado exitosamente',
      successfully_created_note: 'Nota creada correctamente',
      signed_contract: 'Contrato firmado',
      the_it_talent_you_need: 'El talento IT que necesitas',
      one_click: 'a un click',
      we_have_the_people_you_are_looking_for_we_send_you_the_talent_that:
        'Tenemos las personas que estás buscando. Te enviamos el talento que',
      you_need_free_in_less_than_24_hours:
        'necesitas, gratis, en menos de 24 horas.',
      we_send_you_the_talent_that: 'Te enviamos el talento que',
      you_need_in_hours: 'necesitas en 24 horas.',
      more_than_it_experts_are: 'Más de 20,000 expertos IT están',
      waiting_for_you_to_contact_them: 'esperando a que les contactes.',
      our_model_is_based_on_your: 'Nuestro modelo se basa en tu',
      success_you_only_pay_if_we_solve: 'éxito, solo pagas si resolvemos',
      your_hiring_problem: 'tu problema de contratación.',
      about_us: '¿Quiénes somos?',
      in_a_competitive_world_where_time_and_quality_are_crucial_we_are_ready_to_be_your_strategic_partner_in_the_acquisition_of_technological_talent_forget_about_financial_risks_and_trust_us_to_take_your_team_to_the_next_levelL:
        'En un mundo competitivo donde el tiempo y la calidad son cruciales, estamos listos para ser tu socio estratégico en la adquisición de talento tecnológico. Olvídate de los riesgos financieros y confia en nosotros para llevar tu equipo al siguiente nivel.',
      schedule_an_appointment_with: 'Agenda una cita con',
      us: 'Nosotros',
      your_request_was_received_successfully:
        'Tu solicitud fue recibida con éxito',
      our_team_will_contact_you_via_email_to_send_you_the_candidates_if_you_are_in_a_hurry_make_an_appointment_right_now:
        'Nuestro equipo se pondrá en contacto contigo a través de tu correo para enviarte los candidatos. Si tienes mucha prisa, agenda una cita ahora mismo.',
      close: 'Cerrar',
      in_a_competitive_world_where_time_and_quality_are_crucial_we_are_ready_to_be_your_strategic_partner_in_the_acquisition_of_technological_talent_forget_about_financial_risks_and_trust_us_to_take_your_team_to_the_next_level:
        'En un mundo competitivo donde el tiempo y la calidad son cruciales, estamos listos para ser tu socio estratégico en la adquisición de talento tecnológico. Olvídate de los riesgos financieros y confia en nosotros para llevar tu equipo al siguiente nivel.',
      see_all_skills: 'Ver todas las habilidades',
      new_habilities: 'Nuevas habilidades',
      you_will_soon_be_able_to_view_the_soft_skills_of_this_profile:
        'Pronto podrá visualizar las habilidades blanda de este perfil',
      your_profile_is_hidden_from_companies_improve_your_profile_to_start_receiving_offers:
        'Tu perfil está oculto a las empresas. Mejora tu perfil para empezar a recibir ofertas.',
      look_a: 'Mira un',
      and_a: 'y un',
      improve_your_skills_to_attract_better_offers:
        'Mejora tus habilidades para atraer mejores ofertas.',
      improve_your_experience_description_to_attract_better_offers:
        'Mejora la descripción de tu experiencia para atraer mejores ofertas.',
      improve_description: 'Mejorar descripción.',
      improve_technical_skills: 'Mejorar habilidades técnicas',
      you_are_viewing_your_profile_as_companies_see_it_highlight_all_your_potential_and_make_it_shine:
        'Estás visualizando tu perfil como lo ven las empresas. Resalta todo tu potencial y hazlo brillar',
      this_profile_has_areas_for_improvement:
        'Este perfil tiene áreas de mejora',
      low_profile_level: 'Nivel de perfil bajo',
      medium_profile_level: 'Nivel de perfil medio',
      congratulations_your_profile_has_a_high_level:
        'Felicitaciones, tu perfil tiene un nivel alto',
      about_me: 'Sobre mí',
      include_between_and_skills_to_improve_your_profile:
        'Incluye entre {{firstQuantity}} y {{secondQuantity}} habilidades para mejorar tu perfil.',
      your_description_is_good_but_it_could_be_better:
        'Tu descripción es buena, pero podría ser mejor.',
      improve_about_me: 'Mejorar sobre mí',
      high_level: 'nivel alto',
      medium_level: 'Nivel medio',
      low_level: 'Nivel bajo',
      improve_soft_skills: 'Mejorar habilidades blandas',
      it_is_prohibited_to_enter_links: 'Prohibido ingresar enlaces.',
      see_all_offers: 'Ver todas las ofertas',
      select_a_languages: 'Selecciona un idioma',
      you_have_repeated_languages: 'Tienes idiomas repetidos',
      minimum_languages: 'Minimo {{value}} idiomas',
      remove_language: 'Remover idioma',
      native: 'Nativo',
      complementary: 'Complementarios',
      name_and_surname: 'Nombre y Apellido',
      brilliant: 'Genial',
      check_the_email_entered_to_have_your_new_password:
        'Revisa el correo ingresado para tener tu nueva contraseña',
      recover_password: 'Recuperar contraseña',
      send_mail: 'Enviar correo',
      found_talents: 'Talentos Encontrados',
      add_to_favorite: 'Agregar a favorito',
      request_demo: 'Solicitar demo',
      the_talent_details_will_appear_here:
        'Aquí aparecerán los detalles del talento.',
      if_you_are_looking_for_a_more_specific_talent_we_invite_you_to_use_the_search_filters_or_request_a_demo:
        'Si buscas un talento más específico, te invitamos a utilizar los filtros de búsqueda o solicitar una demo',
      soon_you_will_be_able_to_see_more: 'Pronto podrás visualizar más',
      professional_information_about_this: 'información profesional de este',
      profile: 'perfil',
      do_you_want_to_make_this_talent_an_offer:
        '¿Quieres hacerle una oferta a este talento?',
      schedule_a_call_to_manage_the_process_with_you:
        'Agenda una llamada para gestionar el proceso junto a ti',
      schedule_call: 'Agendar llamada',
      select: 'Seleccionar',
      language_not_found: 'Idioma no encontrado',
      update_profile: 'Actualizar perfil',
      i_am_a_talent_manager: 'Soy Talent Manager',
      it_talent: 'Talento IT',
      in_24_hours: 'en 24 horas',
      receive_qualified_profiles_in_less_than_hours_find_the_talent:
        'Recibe 3 perfiles cualificados en menos de 24 horas. Encuentra el talento',
      what_you_are_looking_for_easy_and_fast: 'que buscas, fácil y rápido.',
      enter_the_name_of_the_company: 'Ingresa el nombre de la empresa',
      offer_description: 'Descripción de la oferta',
      your_request_has_already_been_received: 'Tu solicitud ya fue recibida',
      our_team_will_be_in_touch_with_you_shortly_to_send_you_the_profiles_of_candidates_who_fit_your_offer_if_you_need_an_urgent_response_schedule_an_appointment_right_now:
        'Nuestro equipo se pondrá en contacto contigo pronto para enviarte el perfil de los candidatos que encajen con tu oferta. Si necesitas una respuesta urgente, agenda una cita ahora mismo.',
      company_visits_to_your_profile: 'Visitas de empresas a tu perfil',
      number_of_companies_that_marked_your_profile_as_a_favorite:
        'Cantidad de empresas que marcaron tu perfil como favorito',
      present: 'Actualidad',
      maximum_characters: 'Máximo {{value}} caracteres.',
      no_external_links_allowed_in_this_field_or_emails:
        'No se permiten enlaces externos o correos electrónicos en este campo',
      the_start_date_must_be_less: 'La fecha inicial debe ser menor.',
      education: 'Educación',
      courses_and_certificates: 'Cursos y certificados',
      certificates: 'Certificados',
      courses_and_certificates_updated_correctly:
        'Cursos y certificados actualizados correctamente',
      studies_updated_correctly: 'Estudios actualizados correctamente',
      academic_training: 'Formación académica',
      describe_your_academic_background: 'Describe tu formación académica',
      obtained_title: 'Título obtenido',
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre',
      educational_institution: 'Institución educativa',
      month: 'Mes',
      year: 'Año',
      include_your_most_notable_degrees_and_certifications_this_will_help_companies_recognize_your_academic_and_professional_preparation:
        'Incluye tus títulos y certificaciones más destacados. Esto ayudará a las empresas a reconocer tu preparación académica y profesional.',
      or_expected_date: 'o fecha prevista',
      certification_date: 'Fecha de certificación',
      mandatory: 'Obligatorio',
      certifications_and_courses_completed:
        'Certificaciones y cursos realizados',
      this_offer_was_abandoned: 'Esta oferta fue abandonada',
      decided_not_to_continue_with_this_offer:
        '{{name}} decidió no continuar con esta oferta',
      the_candidate_did_not_advance_in_the_process:
        'El candidato no avanzó en el proceso',
      unfortunately_did_not_advance_in_the_selection_process_for_this_offer:
        'Lamentablemente, {{name}} no avanzó en el proceso de selección de esta oferta',
      sorry_we_couldn_t_find_the_page_you_were_looking_for:
        'Lo sentimos, no encontramos la página que buscas',
      please_go_back_to_the_beginning_or_contact_support_if_you_think_this_is_an_error:
        'Vuelve al inicio o contacta a soporte si consideras que se trata de un error',
      contact_support: 'Contactar a soporte',
      oops_there_seems_to_be_a_connection_problem:
        'Ups, parece que hay un problema de conexión',
      please_check_your_internet_connection_or_try_again_later_ff_the_problem_persists_please_contact_support:
        'Revisa tu conexión a internet o intenta de nuevo más tarde. Si el problema persiste, contacta a soporte.',
      retry: 'Reintentar',
      the_email_is_already_registered: 'El correo ya se encuentra registrado.',
      list_of_soft_skills: 'Lista de habilidades blandas',
      create_soft_skills: 'Crear habilidad blanda',
      enter_a_soft_skill: 'Ingresa una habilidad blanda',
      enter_a_soft_skill_in_english: 'Ingresa una habilidad blanda en ingles',
      show_soft_skill: 'Mostrar habilidad blanda',
      hide_soft_skill: 'Ocultar habilidad blanda',
      edit_soft_skill: 'Editar habilidad blanda',
      you_have_not_invited_any_companies_yet:
        'Todavía no has invitado empresas',
      you_can_check_the_status_and_information_of_the_companies_you_invite_to_hutrit_from_here:
        'Podrás revisar el estado y la información de las empresas que invites a Hutrit desde aquí.',
      invite_companies: 'Invitar empresas',
      observe_this_example_of_a: 'Observa este ejemplo de un',
      successfull_profile: 'perfil exitoso',
      in_hutrit_for_more_experienced_talents:
        'en Hutrit para talentos más experimentados',
      example_of_a_junior_profile: 'Ejemplo de un perfil junior',
      begin: 'Comenzar',
      go_out: 'Salir',
      company_details: 'Detalle de la empresa',
      companies: 'Empresas',
      favorites: 'Favoritos',
      profile_views: 'Visitas al perfil',
      complete_your_profile: 'Completa tu perfil',
      to_access_this_function: 'para acceder a esta función',
      your_nickname_must_not_exceed_characters:
        'Tu apodo no debe superar los {{value}} caracteres',
      avoid_using_your_real_name_choose_a_unique_and_professional_nickname_that_best_represents_you:
        'Evita usar tu nombre real. Elige un apodo único y profesional que te represente mejor.',
      update_your_nickname_to_improve_your_profile:
        'Actualiza tu apodo para mejorar tu perfil',
      your_nickname_is_the_first_thing_companies_see_to_ensure_better_presentation_it_should_now_be_a_maximum_of_characters_adjust_yours_to_stand_out_more_effectively:
        'Tu apodo es lo primero que ven las empresas. Para asegurar una mejor presentación, ahora debe tener un máximo de 15 caracteres. Ajusta el tuyo para destacar de manera más efectiva.',
      update_nickname: 'Actualizar apodo',
      our_team_will_be_in_touch_with_you_to_give_you_access_and_introduce_you_to_hutrit_if_you_dont_see_our_email_please_check_your_spam_folder_if_you_are_in_a_hurry_please_schedule_an_appointment_right_now:
        'Nuestro equipo se pondrá en contacto contigo para darte acceso y presentarte Hutrit. Si no ves nuestro correo, revisa tu carpeta de Spam. Si tienes mucha prisa, agenda una cita ahora mismo.',
      your_data_was_received_successfully:
        '¡Tus datos fueron recibidos con éxito!',
      this_company_has_not_completed_its_profile:
        'Esta empresa no ha completado su perfil',
      you_will_be_able_to_review_and_activate_this_company_once_you_have_completed_your_profile:
        'Podrás revisar y activar esta empresa una vez que haya completado su perfil',
      approximate_date_of_hiring: 'Fecha aproximada de contratación',
      estimated_hiring: 'Estimada de contratación',
      this_is_the_approximate_date_when_the_selection_process_is_expected_to_be_completed_and_a_formal_offer_is_made_it_is_not_a_guarantee_or_the_exact_date_of_your_hiring:
        ' Es la fecha aproximada en la que se espera que el proceso de selección termine y se haga una oferta formal. No es una garantía ni la fecha exacta de tu contratación.',
      cant_find_tech_talent: '¿No encuentras talento tecnológico?',
      try_hutrit: 'Prueba con Hutrit',
      we_present_you_qualified_candidates_within_hours:
        'Te presentamos candidatos cualificados en 48 horas.',
      know_how_much_you_can: 'Conoce cuánto puedes',
      spare: 'ahorrar',
      with_hutrit: 'con Hutrit',
      position_or_role: 'Posición o rol',
      select_the_role_you_need_to_hire:
        'Selecciona el rol que necesitas contratar',
      gross_annual_salary: 'Salario bruto anual',
      enter_the_gross_annual_salary_for_this_role:
        'Escribe el salario bruto anual para este rol',
      up_to_a: 'Hasta un',
      percentage_less: '{{value}}% menos',
      in_costs_compared_to_other_platforms:
        'en costos en comparación con otras plataformas.',
      get_candidates_in_hours: 'Obtén candidatos en 48 horas',
      get_qualified_candidates_in_less_than_hours:
        'Obtén candidatos cualificados en menos de 48 horas',
      our_model_is_based_on_your_success_you_only_pay_if_we_solve_your_hiring_problem:
        'Nuestro modelo se basa en tu éxito, solo pagas si resolvemos tu problema de contratación.',
      cookieConsent:
        'Utilizamos cookies para mejorar tu experiencia en nuestro sitio web y para analizar el tráfico de nuestro sitio. Al hacer clic en <127>Aceptar</127>, das tu consentimiento para el uso de cookies de Google Analytics. Para más información, consulta nuestra <222>Política de Cookies</222>.',
      accept: 'Aceptar',
      we_use_cookies_to_improve_your_experience_on_our_website_and_to_analyse_our_site_traffic:
        'Utilizamos cookies para mejorar tu experiencia en nuestro sitio web y para analizar el tráfico de nuestro sitio.',
      by_clicking: 'Al hacer clic en',
      you_consent_to_the_use_of_cookies_by_google_analytics_for_more_information_please_see_our:
        'das tu consentimiento para el uso de cookies de Google Analytics. Para más información, consulta nuestra',
      cookie_policy: 'Política de Cookies',
      complete_your_registration: 'Completa tu registro',
      create_a_username_and_password_to_finish_and_start_using_your_account:
        'Crea un usuario y contraseña para finalizar y empezar a usar tu cuenta.',
      email_notifications: 'Notificaciones por correo',
      choose_the_notifications_you_want_to_receive_by_email:
        'Elige las notificaciones que deseas recibir por correo',
      weekly_summary: 'Resumen semanal',
      receive_an_email_with_the_events_of_the_week:
        'Recibe un correo con los acontecimientos de la semana',
      weekly_summary_notifications_have_been_successfully_disabled:
        'Las notificaciones de Resumen semanal fueron desactivadas con éxito',
      weekly_summary_notifications_have_been_successfully_activated:
        'Las notificaciones de Resumen semanal fueron activadas con éxito',
      if_you_disable_notifications_for_offers_your_profile_will_be_automatically_deactivated_so_you_will_not_be_able_to_receive_more_offers_and_you_will_lose_contact_with_the_companies_and_your_manager_do_you_want_to_continue:
        'Si desactivas las notificaciones de las ofertas, automáticamente se desactivará tu perfil, por lo que no podrás recibir más ofertas y perderás el contacto con las empresas y tu manager ¿Deseas continuar?',

      you_are_about_to_activate_your_notifications:
        'Estás a punto de activar tus notificaciones',
      you_are_about_to_disable_your_notifications:
        'Estás a punto de desactivar tus notificaciones',
      creation_date: 'Fecha de creación',
      this_talent_has_not: 'El talento {{value}} no ha',
      completed_their_profile: 'completado su perfil',
      in_order_for_talent_to_start_receiving_offers_they_must_complete_their_profile:
        'Para que el talento comience a recibir ofertas, debe completar su perfil.',
      once_complete_review_and_validate_the_information_to_ensure_it_meets_the_necessary_criteria:
        'Una vez completo, revisa y valida la información para asegurar que cumple con los criterios necesarios.',
      send_reminder: 'Enviar recordatorio',
      profile_not_completed: 'Perfil sin completar',
      validate_profile: 'Validar perfil',
      the_user_is_already_validated: 'El usuario ya se encuentra validado',
      you_cant_find_the_talent_youre_looking_for:
        'No encuentras el talento que buscas',
      at_hutrit_you_will_have_hundreds_of_talent_managers_in_charge_of_finding_talents_just_for_you:
        'En Hutrit, contarás con cientos de Talents Managers encargados de buscar talentos sólo para ti.',
      keep_searching: 'Seguir buscando',
      oops_we_couldnt_find_what_you_were_looking_for:
        'Ups no encontramos lo que buscabas.',
      dont_worry_try_again: 'Tranquilo, intentalo de nuevo.',
      great_you_found_over_talents_in_your_first_search:
        '¡Excelente! Encontraste más de {{value}} talentos en tu primera búsqueda',
      want_to_maximize_your_results_request_a_free_demo_and_discover_how_hutrit_can_help_you_find_the_perfect_talent_faster:
        '¿Quieres maximizar tus resultados? Solicita una demo gratuita y descubre cómo Hutrit puede ayudarte a encontrar el talento perfecto más rápidamente.',
      see_results: 'Ver resultados',
      you_found_less_than_talents_in_your_first_search:
        'Encontraste menos de {{value}} talentos en tu primera búsqueda',
      schedule_a_call_to_help_you_find_the_specific_candidate_you_are_looking_for_with_the_support_of_more_than_hundreds_of_talent_managers_at_your_disposal:
        'Agenda una llamada para ayudarte a buscar al candidato específico que buscas con el apoyo de más de cientos de Talent Managers a tu disposición.',
      by_deleting_your_account_you_are_completely_disconnected_from_hutrit:
        'Al eliminar tu cuenta, te desvinculas completamente de Hutrit.',
      you_are_about_to_delete_your_account:
        'Estas a punto de eliminar tu cuenta',
      yes_remove_account: 'Si, eliminar cuenta',
      remove_account: 'Eliminar cuenta.',
      account_deleted_successfully: 'Cuenta eliminada correctamente',
      request_a_demo_to_get_started: 'Solicita una Demo para Empezar',
      discover_how_our_platform_can_help_you_create_effective_offers_request_your_demo_now_and_start_using_all_our_features:
        'Descubre cómo nuestra plataforma puede ayudarte a crear ofertas eficaces. Solicita tu demo ahora y empieza a utilizar todas nuestras funcionalidades.',
      active: 'Activa',
      remove_validation: 'Remover validación',
      validation_successfully_removed: 'Validación removida correctamente',
      your_virtual_recruiter: 'hutrit, tu <1/> Agente IA <2/> reclutador',
      revolutionizing_talent_search_and_selection:
        'Encuentra el mejor talento tecnológico y <1/> de marketing sin mover un dedo',
      learn_more: 'Conocer más',
      this_offer_is_activated: 'Esta oferta está activada',
      this_offer_has_been_deactivated: 'Esta oferta fue desactivada',
      position: 'Puesto',
      published: 'Publicado',
      estimated_hiring_v2: 'Contratación estimada',
      see_offer: 'Ver oferta',
      see_processes: 'Ver procesos',
      talents_ready_for_your_needs: 'Talentos listos a <1/> tus necesidades',
      start_today_and_secure_the_future_of_your_company_with_the_best_talents_in_the_market: `Comienza hoy y asegura el <1 /><2 style='color: #01A76A'>{{dinamicText}}</2> con los <3/> mejores talentos del mercado`,
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: idiom, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
