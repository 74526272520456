const FireIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list/local_fire_department">
        <mask
          id="mask0_12720_1422"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="48"
          height="48"
        >
          <rect id="Bounding box" width="48" height="48" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_12720_1422)">
          <path
            id="icon"
            d="M12 28C12 29.7333 12.35 31.375 13.05 32.925C13.75 34.475 14.75 35.8333 16.05 37C16.0167 36.8333 16 36.6833 16 36.55V36.1C16 35.0333 16.2 34.0333 16.6 33.1C17 32.1667 17.5833 31.3167 18.35 30.55L24 25L29.65 30.55C30.4167 31.3167 31 32.1667 31.4 33.1C31.8 34.0333 32 35.0333 32 36.1V36.55C32 36.6833 31.9833 36.8333 31.95 37C33.25 35.8333 34.25 34.475 34.95 32.925C35.65 31.375 36 29.7333 36 28C36 26.3333 35.6917 24.7583 35.075 23.275C34.4583 21.7917 33.5667 20.4667 32.4 19.3C31.7333 19.7333 31.0333 20.0583 30.3 20.275C29.5667 20.4917 28.8167 20.6 28.05 20.6C25.9833 20.6 24.1917 19.9167 22.675 18.55C21.1583 17.1833 20.2833 15.5 20.05 13.5C18.75 14.6 17.6 15.7417 16.6 16.925C15.6 18.1083 14.7583 19.3083 14.075 20.525C13.3917 21.7417 12.875 22.9833 12.525 24.25C12.175 25.5167 12 26.7667 12 28ZM24 30.6L21.15 33.4C20.7833 33.7667 20.5 34.1833 20.3 34.65C20.1 35.1167 20 35.6 20 36.1C20 37.1667 20.3917 38.0833 21.175 38.85C21.9583 39.6167 22.9 40 24 40C25.1 40 26.0417 39.6167 26.825 38.85C27.6083 38.0833 28 37.1667 28 36.1C28 35.5667 27.9 35.075 27.7 34.625C27.5 34.175 27.2167 33.7667 26.85 33.4L24 30.6ZM24 6V12.6C24 13.7333 24.3917 14.6833 25.175 15.45C25.9583 16.2167 26.9167 16.6 28.05 16.6C28.65 16.6 29.2083 16.475 29.725 16.225C30.2417 15.975 30.7 15.6 31.1 15.1L32 14C34.4667 15.4 36.4167 17.35 37.85 19.85C39.2833 22.35 40 25.0667 40 28C40 32.4667 38.45 36.25 35.35 39.35C32.25 42.45 28.4667 44 24 44C19.5333 44 15.75 42.45 12.65 39.35C9.55 36.25 8 32.4667 8 28C8 23.7 9.44167 19.6167 12.325 15.75C15.2083 11.8833 19.1 8.63333 24 6Z"
            fill="#01A76A"
          />
        </g>
      </g>
    </svg>
  )
}

export default FireIcon
